import { base } from '../base';

import { getActiveRequest } from './getActiveRequest';
import { getRequestFields } from './getRequestFields';
import { getSummaryFields } from './getSummaryFields';

export const happycoInspection = {
  ...base,
  getActiveRequest,
  getRequestFields,
  getSummaryFields,
};
