import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { Button, ConfirmationModal } from '@moved/ui';
import { format, useUser, useModal, useNotify } from '@moved/services';

import { getMove } from '../../../../moves/actions';
import { SummaryScreen } from '../../shared/components/SummaryScreen';

export const UtilitySetupSummary = ({ screen, taskDefinition, taskSummary, taskDetails, origin, taskBaseRoute }) => {
  // HOOKS
  const { taskId, moveId } = useParams();
  const { user } = useUser();
  const dispatch = useDispatch();
  const history = useHistory();
  const Modal = useModal();
  const Notify = useNotify();

  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const resetPending = taskDefinition.selectors.useCreateUtilityRequestPending()
  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);
  const artifacts = taskDefinition.helpers.getArtifacts(taskSummary) || [];
  const activeAccount = artifacts[0];

  const isAccountMine = (
    (activeRequest && activeRequest?.submitted_by?.user_id === user.id) ||
    (activeAccount && activeAccount?.provided_by?.id === activeMoveStep?.tenant?.id)
  );

  const requiresApproval = taskDetails?.settings?.requires_approval;

  // Reset handler functions
  const confirmReset = () => {
    if(activeRequest?.status === 'rejected') return handleReset();
    else Modal.open(
      <ConfirmationModal
        title='Resubmit account information'
        copy={'Are you sure? This will replace your existing account information.'}
        confirmText='Continue'
        cancelText='Never mind'
        onConfirm={handleReset}
      />,
      { sondheim: true },
    );
  };
  const handleReset = () => {
    const resetTaskPromise = taskDetails?.id ? Promise.resolve(taskDetails) :
      dispatch(taskDefinition.actions.create(taskId))
    return resetTaskPromise
      .then(newTaskDetails => dispatch(taskDefinition.actions.createRequest(newTaskDetails.id)))
      // refresh the move because reset affects MT on the move
      .then(r => { dispatch(getMove(moveId)); return r; })
      .then((resp) => history.push(`${taskBaseRoute}${taskDefinition.getStartOverRoute(resp)}`))
      .catch((error) => Notify.error(format.error(error)))
  };

  return (
    <SummaryScreen
      origin={origin}
      screen={screen}
      extraAttributes={[
        isAccountMine && !(activeRequest?.status === 'approved' && requiresApproval) && {
          value: (
            <div className={CSS.reschedule}>
              <Button
                size='small'
                color='secondary'
                text='Resubmit updated account info'
                onClick={confirmReset}
                disabled={resetPending}
              />
            </div>
          )
        },
      ].filter(Boolean)}
    />
  );

};
