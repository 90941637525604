import { isNil } from 'lodash';


import * as screenComponents from './components';

export const screens = {


  'vehicle-details': {
    slug: 'details',
    label: "Details",
    subStep: true,
    title: 'Register your vehicles',
    subtitle: 'Residents are required to register all vehicles to ensure only approved vehicles are parked on the property.',
    component: screenComponents.VehicleType,
    isCompleted: (data, { getActiveVehicle, helpers }, context) => {
      const request = helpers.getActiveRequest(data);
      // Not done if no value for 'has_vehicles'
      if(isNil(data.has_vehicles)) return false;
      // Done if has_vehicles is explicitly false and no request
      if(data.has_vehicles === false && (!request || request.status === 'cancelled')) return true
      // Done if the request is not draft
      if(request && request.status !== 'draft') return true;

      const vehicle = getActiveVehicle(data, context);
      if(!vehicle) return false;
      return !!(vehicle.make && vehicle.model && vehicle.year && vehicle.color && vehicle.owner);
    },
  },

  'summary': {
    slug: 'summary',
    label: "Summary",
    subStep: true,
    title: 'Do you need to register another vehicle',
    subtitle: 'Provide details for another vehicle, or complete your registration',
    component: screenComponents.VehiclesSummary,
    isCompleted: (data, { helpers }) => {
      const request = helpers.getActiveRequest(data);
      return request && request.status !== 'draft';
    },
    maxWidth: '1025px',
  },

  'confirmation': {
    slug: 'confirmation',
    component: screenComponents.Confirmation,
    label: 'Confirmation',
    backRestricted: true,
  },
};
