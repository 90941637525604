import React from 'react';

import { Accordion } from '@moved/ui';

import { InsuranceDisclaimer } from './InsuranceDisclaimer';
import { CancellationDisclaimer } from './CancellationDisclaimer';

import CSS from './styles/QuoteFAQs.module.scss';

const sections = [
  {
    label: 'Who is my moving company?',
    content: (
      <div className={CSS.answer}>
        <p>Your move will be matched with a top-tier company in Moved’s vetted network of moving companies. You’ll receive an email introducing you to the moving company shortly after you’ve booked the move.</p>
      </div>
    ),
  },
  {
    label: 'When will the movers show up?',
    content: (
      <div className={CSS.answer}>
        <p>
          Your time preference is shared with the moving company, who will provide you with a specific arrival window.
          For example, if the provided arrival window is 8AM - 11AM, the movers can arrive <em>as early</em> as 8AM (please be ready for them) or <em>as late</em> as 11AM (make sure to account for enough time to complete the move according to any reservations, traffic, etc.)
        </p>
      </div>
    ),
  },
  {
    label: 'What if I need to cancel or reschedule?',
    content: (
      <div className={CSS.answer}>
        <p>
          <CancellationDisclaimer />
        </p>
      </div>
    ),
  },
  {
    label: 'Is my move insured?',
    content: (
      <div className={CSS.answer}>
        <p>
          <InsuranceDisclaimer />
        </p>
      </div>
    ),
  },
  {
    label: 'Will the movers disassemble and reassemble my items?',
    content: (
      <div className={CSS.answer}>
        <p>
          Yes! Standard disassembly of basic furniture (i.e. basic metal bed frames) is included. Disassembly/reassembly of more complicated furniture items such as cribs, bunk beds, or murphy beds may be subject
          to an additional fee. After booking you'll be introduced to the moving company and you should let them know if you have any such items - e.g. murphy beds, bunk beds, IKEA closets, cribs, wardrobes, custom
          shelving, chandeliers, pianos, and any other complex, time-consuming items.
        </p>
      </div>
    ),
  },
];

export const QuoteFAQs = (props) => (
  <div className={CSS.questions}>
    <h3 className={'mb-20'}>Questions?</h3>
    <Accordion panels={sections}/>
  </div>
);
