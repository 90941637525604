import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { get } from 'lodash';

import { ContentRenderer } from '@moved/ui';

import { ScreenTitle } from '../../shared';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/DynamicContentScreen.module.scss';

export const DynamicContentScreen = ({ screen, changeScreen, task, origin }) => {
  const screens = get(task,'flow',[]).map(screen => screen.slug);
  return (
    <div className={taskCSS.holder}>
      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} />
        <div className={CSS.content_holder}>
          <div className={CSS.sizer}>
            <div className={taskCSS.content}>
              <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
                <div className={CSS.container}>
                  <ContentRenderer content={screen.content} />
                </div>
              </div>
            </div>
            {screen.side_column_image_url && (
              <div className={CSS.side_column} style={{ backgroundImage: `url(${screen.side_column_image_url})`}} />
            )}
          </div>
        </div>
        <div className={taskCSS.navigate}>
          { screens[screens.length-1] === screen.slug ? (
            <Link
              id='orientation_screen_button'
              to={origin}
              className='btn-green-dark'
            >
              Back to Dashboard
            </Link>
          ) : (
            <div className={taskCSS.navigate_next}>
              <button
                id='orientation_screen_button'
                type='button'
                className={classNames('btn-primary', 'btn--full')}
                onClick={() => changeScreen('next')}
              >{screen.cta_text}</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );

};
