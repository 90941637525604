import { merge } from 'lodash';

import * as getMove from './getMove';
import * as getMoveSummaries from './getMoveSummaries';
import * as createMove from './createMove';

export const initialState = merge(
  getMove.initialState,
  getMoveSummaries.initialState,
  createMove.initialState,
);

export const reducers = [
  getMove.reducer,
  getMoveSummaries.reducer,
  createMove.reducer,
];
