import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { get } from 'lodash';

import { useNotify, format } from '@moved/services';
import { DynamicForm, AtomSpinner, Notebox } from '@moved/ui';

import { ScreenTitle } from '../../shared';
import { QuoteFAQs } from './QuoteFAQs';
import { QuoteTicket } from './QuoteTicket';
import { RateDetails } from './RateDetails';
import { LastMinuteNotice } from './LastMinuteNotice';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import quoteCSS from './styles/ViewQuotes.module.scss';

// Calculate new minimum after removing all discounts
const _calcReduction = (min, discounts) => {
  const reducer = (accumulator, discount) => {
    switch(discount.type) {
      case 'cents':
        return accumulator - discount.cents;
      case 'percent':
        return accumulator - (min * discount.percent);
      default:
        return accumulator;
    };
  };
  return discounts.reduce(reducer,min);
};

// Render quotes screen
export const ViewQuotes = ({ screen, changeScreen, task, origin }) => {
  // hooks
  const { id, moveId } = useParams();
  const Notify = useNotify();
  const dispatch = useDispatch();

  // redux
  const taskDetails = task.selectors.useTaskable(id);
  const { mover_booking } = taskDetails;
  const rates = task.selectors.usePartnerRates(mover_booking.id) || [];
  const getRatesPending = task.selectors.usePartnerRatesPending();
  const updateTaskPending = task.selectors.useUpdateMoverBookingTaskPending();
  const { discounts = [] } = task.selectors.useMove(moveId);
  const percentDiscounts = discounts.filter(discount => get(discount, 'type') === 'percent');
  const isCancelable = task.isCancellationFree(taskDetails);

  // Defaults with ability to override in each APP
  const FAQs = screen.faq || QuoteFAQs;
  const Ticket = screen.ticket || QuoteTicket;

  // fetch latest rates
  useEffect(() => {
    dispatch(task.actions.getPartnerRates(mover_booking.id))
      .catch(err => Notify.error('Unable to retrieve quotes'));
  // eslint-disable-next-line
  },[]); // only run on mount

  // Submit form function
  const _handleSubmit = ({ rate }) => {
    const data = {
      mover_booking: {
        id: mover_booking.id,
        selected_partner_rate_id: parseInt(rate)
      }
    };
    return dispatch(task.actions.update(id, data))
      .then(resp => changeScreen('next',resp));
  };

  // Opt out function
  const optOut = () => {
    dispatch(task.actions.update(id, { interested_in_moved: false }))
      .then(resp => changeScreen('next', resp))
      .catch(err => Notify.error(format.error(err)));
  }

  // convenience function
  const getInitialRateSelection = () => {
    const selectedRate = get(taskDetails,'mover_booking.selected_partner_rate');
    const selected = (
      (selectedRate && rates.find(rate => rate.id === selectedRate.id)) || // previously selected
      (selectedRate && rates.find(rate => rate.crew_size === selectedRate.crew_size)) || // updated rate but same crew_size
      rates.find(rate => rate.is_recommended) // fallback to recommended rate
    );
    return selected ? selected.id : '';
  };

  const fields = [
    {
      name: 'rate',
      type: 'itemList',
      value: getInitialRateSelection(),
      required: 'You must make a selection',
      className: quoteCSS.item_list,
      options: rates.map(rate => ({
        value: rate.id,
        icon: { symbol: `Crew-${rate.crew_size}`, library: 'people' },
        large: true,
        label: `${rate.crew_size} Person Crew`,
        sublabel: rate.is_recommended ? (<span className={quoteCSS.badge}>Recommended</span>) : null,
        rightLabel: (
          <div className="flex flex-column">
          {/* Show percentage off on hourly rate only if _all_ coupons are percent coupons */}
          {discounts.length > 0 && percentDiscounts.length === discounts.length ?
            (<>
              <div><span>{format.currency(_calcReduction(rate.hourly_rate, percentDiscounts))}</span> <span className={quoteCSS.per_hour}>/ hour</span></div>
              <span className={quoteCSS.pre_coupon_rate}>{format.currency(rate.hourly_rate)}</span>
            </>) : (
              <div><span>{format.currency(rate.hourly_rate)}</span> <span className={quoteCSS.per_hour}>/ hour</span></div>
            )}
          </div>
        ),
        hiddenContent: (<RateDetails rate={rate} discounts={discounts} task={task} isCancelable={isCancelable} />),
      })),
    },
  ];

  return (
    <div className={taskCSS.holder}>

      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} />
        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>

            <div className={quoteCSS.content}>

              <div className={quoteCSS.form}>
                { getRatesPending ? (
                  <>
                    <h5 className={quoteCSS.title} style={{textAlign: 'center', marginTop: '50px'}}>Generating quotes</h5>
                    <AtomSpinner />
                  </>
                ) : rates.length ? (
                  <>
                    <LastMinuteNotice moverBooking={mover_booking}/>
                    <h5 className={quoteCSS.title}>Choose an option</h5>
                    <DynamicForm id='quote-form'
                      fields={fields}
                      onSubmit={_handleSubmit}
                      disabled={updateTaskPending}
                    />
                  </>
                ) : (
                  <Notebox
                    heading={`Unable to generate a rate`}
                    body={`We are unable to provide you with instant rates. To
                      continue please send a message to your personal concierge
                      using the contact form in the bottom right corner.`}
                    color='orange'
                    icon={{ library:'code', symbol:'Warning-2' }}
                  />
                )}

                <FAQs />

                <div className={taskCSS.navigate}>
                  <div className={screen.showOptOut ? taskCSS.navigate_choice : taskCSS.navigate_next}>
                    <label
                      htmlFor="quote-form-submit"
                      tabIndex="0"
                      role="button"
                      className={'btn-primary btn--full'}
                      disabled={updateTaskPending}>
                      Next
                    </label>
                    { screen.showOptOut && (
                      <label
                        tabIndex="0"
                        role="button"
                        className={'btn-ghost' + (updateTaskPending  ? ' loading' : '')}
                        onClick={optOut}
                        disabled={updateTaskPending}>
                        No thanks
                      </label>
                    )}
                  </div>
                </div>

              </div>

              <Ticket task={task} />

            </div> {/* quoteCSS.content */}


          </div> {/* taskCSS.folder */}
        </div>

      </div>

    </div>
  );
}
