import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import * as Yup from 'yup';
import classNames from 'classnames';
import { get } from 'lodash';

import { DynamicForm, AtomSpinner } from '@moved/ui';
import { useNotify, useUser, format } from '@moved/services';

import { ScreenTitle } from '../../shared';
import { getS3UploadUrl } from "../../shared/actions";
import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/PetDetails.module.scss'

// Multi-file document upload screen component
export const PetDetails = ({ screen, changeScreen, task }) => {
  // HOOKS
  const { id, screenContext } = useParams();
  const { user } = useUser();
  const dispatch = useDispatch();
  const Notify = useNotify();

  // REDUX
  const taskable = task.selectors.useTaskable(id);
  const activeMoveStep = task.selectors.useActiveMoveStep();

  const activeRequest = task.helpers.getActiveRequest(taskable);
  const activePet = task.getActivePet(taskable, screenContext);
  const updatePending = task.selectors.usePetRegistrationPending();
  const requestPending = task.selectors.useUpdatePetRequestPending();
  const loading = task.selectors.usePetRegistrationPending();
  const building = task.selectors.useBuilding(get(activeMoveStep,'building.slug'));

  // STATE
  const pending = updatePending || requestPending;

  const fields = [
    {
      type: 'text',
      name: 'breed',
      label: 'Breed',
      flavor: 'Samoyed, Labrador, Border collie, etc.',
      required: true,
      half: true,
      value: get(activePet,'breed'),
    },
    {
      type: 'integer',
      name: 'age_years',
      label: 'Age ( Years )',
      required: 'Age is required',
      half: true,
      className: CSS.tiny,
      value: get(activePet,'age_years'),
    },
    {
      type: 'difference',
      name: 'age_months',
      label: 'Months',
      required: 'Months is required',
      difference: 0,
      half: true,
      className: CSS.tiny,
      value: get(activePet,'age_months') || 0,
    },
    {
      type: 'number',
      name: 'weight_in_lbs',
      label: 'Weight (lbs)',
      required: true,
      half: true,
      value: get(activePet,'weight_in_lbs'),
    },
    {
      type: 'text',
      name: 'color',
      label: 'Color',
      flavor: 'Beige, black, white, etc.',
      required: true,
      half: true,
      value: get(activePet,'color'),
    },
    {
      type: 'select',
      name: 'legal_owner_tenant_id',
      label: 'Owner',
      required: true,
      options:  taskable.tenants_on_lease.map(tenant => ({
        label: format.fullname(tenant),
        value: tenant.id,
      })),
      value: get(activePet,'owner.id')
      || get(taskable,'tenants_on_lease').find(tenant => tenant.user_id === user.id).id,
    },
    {
      type: 'slideToggle',
      name: 'gender',
      label: 'Gender',
      required: true,
      half: true,
      options: [
        { value: 'female', label: 'Female' },
        { value: 'male', label: 'Male' },
      ],
      value: get(activePet,'gender'),
    },
    {
      type: 'slideToggle',
      name: 'is_service_animal',
      label: 'Is this a service animal?',
      required: true,
      half: true,
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
      value: get(activePet,'is_service_animal'),
    },
    taskable.settings.service_animal_documentation_enabled && {
      type: 'uploader',
      name: 'service_animal_documentation_url',
      label: (<span className={CSS.service_animal_documentation_label}>Upload service animal documentation</span>),
      className: CSS.uploader_label,
      required: 'Proof of service animal documentation is required',
      s3UploadRequest: (fileData) => dispatch(getS3UploadUrl(building.id,{
        ...fileData,
        subdirectory: `pet-registration`,
      })),
      options: {
        accept: {
          'image/jpg': ['.jpg', '.jpeg'],
          'image/png': ['.png'],
          'image/heic': ['.heic'],
          'application/pdf': ['.pdf'],
          'application/msword': ['.doc','.docx'],
        },
        useUniqueFilenames: true,
      },
      value: get(activePet,'service_animal_documentation_url'),
      custom: {
        hidden: {
          compare: {field: 'is_service_animal'},
          boolean: 'not',
          value: true,
        }
      }
    },
  ].filter(Boolean);

  const validation = Yup.object().shape({
    age_months: Yup.number().max(11,'Cannot be more than 11 months'),
  });

  const _handleSubmit = formData => {
    if(pending) return;

    dispatch(task.actions.updatePet(activeRequest.id, activePet.id, formData))
      .then((resp) => {
        changeScreen('next',resp);
      })
      .catch(err =>  Notify.error(format.error(err)));

  };

  return (
    <div className={taskCSS.holder}>
      <div className={classNames(taskCSS.task)}>
        <ScreenTitle screen={screen} />

        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
            <div className={taskCSS.screen_edit_form}>
              { !loading ? (
                <DynamicForm
                  id={`${screen.slug}-form`}
                  fields={fields}
                  formStyle={'underline'}
                  onSubmit={_handleSubmit}
                  disabled={pending}
                  validation={validation}
                />
              ) : (
                <AtomSpinner />
              )}
            </div>
          </div>
        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            <label
              htmlFor={`${screen.slug}-form-submit`}
              tabIndex="0"
              role="button"
              className={'btn-primary btn--full' + (pending  ? ' loading' : '')}
              disabled={pending}
            >
              Submit
            </label>
          </div>
        </div>

      </div>
    </div>
  );
};
