import React from 'react';
import classNames from 'classnames';

import { format } from '@moved/services';

import CSS from '../styles/CouponTicket.module.scss';

export const CouponTicket = ({ coupon, title, cta, onClick }) => (
  <div className={CSS.coupon}>
    { title && (
      <div className={CSS.section}>
        <h3 className={CSS.title}>{ title }</h3>
      </div>
    )}
    { title && (
      <div className={CSS.divider}></div>
    )}
    <div className={CSS.section}>
      <div className={CSS.amount_label}>Coupon</div>
      <div className={CSS.amount}>
        {
          coupon.type === 'cents' ?
            format.currency(coupon.cents,{ minimumFractionDigits: 0, maximumFractionDigits: 0}) :
            format.percent(coupon.percent)
        }
      </div>
      <div className={CSS.amount_label}>Off your move</div>
      <button className={classNames('btn-primary',CSS.action_button)} onClick={onClick}>{ cta }</button>
    </div>
  </div>
);
