import React from 'react';
import { useHistory } from 'react-router-dom';
import { userService, useUser } from '@moved/services';

import cats from './images/cats.png';
import CSS from './Four04.module.scss';

export const Four04 = ({ message = (<p>We're sorry, the link you followed may be broken or the page may have been removed.</p>) }) => {
  const { user, isAuthenticated } = useUser();
  const history = useHistory();
  const redirect = () => userService.redirect(user,history);

  return (
    <div className={CSS.page_not_found}>
      <h1 className="mega">404</h1>
      <h3>We're not kitten around.  Your page was not found.</h3>
      { message && (
        <>{message}</>
      )}
      <p>Please <span className={'faux-link'} onClick={redirect}>{ isAuthenticated ? 'click here' : 'sign in'}</span> to return to the application.</p>
      <img className={CSS.cats} src={cats} alt="Cats" />

    </div>
  );
};
