import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'USER_GET_PARTNER_BUILDINGS',
  request: (slug) => request.get(`/residents/partners/${slug}/building-summaries`).then(r => get(r,'data.data.buildings')),
  selector: (state, slug) => get(state,`partnerBuildings.${slug}`),
  reducers: {
    success: (state, { response: buildings, params: [slug] }) => ({
      ...state,
      partnerBuildings: {
        ...state.partnerBuildings,
        [slug]: buildings,
      },
    }),
  },
}

export const {
  action,
  reducer,
  useResource: usePartnerBuildings,
  usePending: usePartnerBuildingsPending,
} = generate.redux(creator);

export const initialState = {
  partnerBuildings: {},
};
