import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'CORE_GET_SNIPPET_MAP',
  request: (id) => request.get(`/buildings/${id}/snippet-map`).then(r => r.data.data.snippet_map),
  selector: (state, id, tag) => {
    const { snippetMap } = state;
    if(!snippetMap[id]) return false;
    return !tag ? snippetMap[id] : get(snippetMap,`${id}['${tag}']`);
  },
  cache: { days: 1 },
  reducers: {
    success: (state, { response: snippets, params: [id] }) => ({
      ...state,
      snippetMap: {
        ...state.snippetMap,
        [id]: snippets
      },
    }),
  },
};

export const {
  action,
  reducer,
  useResource: useSnippetMap,
  usePending: useSnippetMapPending,
} = generate.redux(creator);

export const initialState = {
  snippetMap: {},
};
