import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_UPDATE_MOVER_BOOKING_DIRECT',
  request: (id, { mover_booking }) => {
    const { id: moverBookingId, ...moverBooking } = mover_booking; // pull the moverBookingId out of the mover_booking
    return request.patch(`/mover-bookings/${moverBookingId}`, moverBooking)
      // rewrap with the fake "taskable"
      .then(r => ({
        id,
        mover_booking: get(r, 'data.data.mover_booking'),
      }));
  },
  reducers: {
    success: (state, { response }) => ({
      ...state,
      moverBookingTasks: {
        ...state.moverBookingTasks,
        [response.id]: response,
      },
    }),
  },
};

export const {
  action,
  reducer,
  usePending: useUpdateMoverBookingDirectPending,
} = generate.redux(creator);
