import { get } from 'lodash';

import * as screenComponents from './components';

export const screens = {

  'coi-submit': {
    slug: 'submit',
    component: screenComponents.Submit,
    label: 'Submit COI',
    title: 'Submit processed COI for moving company',
    isCompleted: (data, { helpers }) => {
      const activeRequest = helpers.getActiveRequest(data);
      return activeRequest && (get(activeRequest,'status') !== 'draft');
    },
    maxWidth: 420,
  },
  'summary': {
    slug: 'summary',
    component: screenComponents.Complete,
    label: 'COI Submitted',
    backRestricted: true,
  },

};
