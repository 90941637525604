import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { get } from 'lodash';

import { DynamicForm } from '@moved/ui';

import { useNotify, useUser, format } from '@moved/services';

import { QuoteTicket } from '../../book-movers/components';
import { ScreenTitle } from '../../shared';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/LongDistance.module.scss';

export const LongDistance = ({ screen, changeScreen, task }) => {
  //hooks
  const { id } = useParams();
  const Notify = useNotify();
  const taskDetails = task.selectors.useTaskable(id);
  const { user } = useUser();
  const dispatch = useDispatch();

  //actions
  const updateTaskPending = task.selectors.useTaskablePending();

  //Submit form function
  const _handleSubmit = ({ mover_booking }) => {
    dispatch(task.actions.update(id, { mover_booking }))
      .then(r => dispatch(task.actions.longDistanceEmail(mover_booking.id)).then(() => r))
      .then(r => changeScreen('next', r))
      .catch(err => Notify.error(format.error(err)));
  };

  const fields = [
    {
      type: 'hidden',
      name: 'mover_booking.id',
      value: get(taskDetails,'mover_booking.id'),
    },
    {
      name: 'mover_booking.contact_email',
      label: 'Email address',
      value: get(taskDetails,'mover_booking.contact_email') || get(user,'email'),
      type: 'email',
      required: true,
      className: CSS.preference_field,
    },
    {
      name: 'mover_booking.contact_phone',
      label: 'Phone number',
      value: get(taskDetails,'mover_booking.contact_phone'),
      type: 'tel',
      required: true,
      className: CSS.preference_field,
    },
  ];

  return (
    <div className={taskCSS.holder}>

      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} />
        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>

            <div className={CSS.content}>

              <div className={CSS.form}>

                <p className={'mb-50'}>
                  We've detected that the moving distance is longer than what we
                  consider a 'local' move. Confirm your contact information below
                  and a Moved specialist will source and send you hand selected
                  quotes from our partners who best fit your moving locations.
                  Don't worry - this won't be shared with anyone but your Moved
                  concierge.
                </p>

                <DynamicForm id='long-distance-form'
                  fields={fields}
                  formStyle={'underline'}
                  onSubmit={_handleSubmit}
                  disabled={updateTaskPending}
                />
              </div>

              <QuoteTicket task={task} />

            </div> {/* CSS.content */}


          </div> {/* taskCSS.folder */}
        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            <label
              htmlFor="long-distance-form-submit"
              tabIndex="0"
              role="button"
              className={'btn-primary btn--full' + (updateTaskPending  ? ' loading' : '')}
              disabled={updateTaskPending}
            >
              Next
            </label>
          </div>
        </div>

      </div>

    </div>
  );
}
