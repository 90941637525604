import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import classNames from 'classnames';

import { useNotify, format } from '@moved/services';
import { DynamicForm } from '@moved/ui';

import { getTaskSummary } from '../../../actions';
import { ScreenTitle } from '../../shared';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/KeyPickup.module.scss';

export const KeySelectAppointment = ({ screen, changeScreen, taskDefinition, taskDetails }) => {
  // hooks
  const { taskId, id } = useParams();
  const Notify = useNotify();
  const dispatch = useDispatch();
  // redux
  const updatePending = taskDefinition.selectors.useUpdatePending();
  const createPending = taskDefinition.selectors.useCreateRequestPending();
  const pending = updatePending || createPending;
  const { roommate_appointments = [] } = taskDetails ?? {};

  // Handle case where user wants to schedule their own appointment
  const handleSelfResponsibility = () => {
    // if no draft request post to request a new draft otherwise, update task to self
    const draftRequest = get(taskDetails,'requests[0].status') === 'draft' ? taskDetails.requests[0] : null;

    if(draftRequest) {
      // Update task
      dispatch(taskDefinition.actions.update(id, { responsibility: 'self' }))
        .then((resp) => changeScreen('next',resp))
        .catch(error => Notify.error( get(error, 'response.data.message') ));
    } else {
      // Create request
      dispatch(taskDefinition.actions.createRequest(id))
        .then((resp) => dispatch(taskDefinition.actions.updateRequest(resp.requests[0].id, {
          'tenant_key_ids': [ taskDetails.current_tenant_key.id],
          ...(taskDetails.building_calendar_options.length === 1
              ? {building_calendar_id: taskDetails.building_calendar_options[0].id }
              : null
          )
        })))
        .then((resp) => changeScreen('next',resp))
        .catch(error => Notify.error( get(error, 'response.data.message') ));
    }

  }

  // Submit form, update key pickup task
  const handleSubmit = data => {
    if (pending) return;

    if(data.current_tenant_key.key_pickup_appointment_id === 'self_schedule') {
      return handleSelfResponsibility();
    }

    return dispatch(taskDefinition.actions.update(id, data))
      // update to the artifacts list based on this request, must refresh the summary
      .then((resp) => dispatch(getTaskSummary(taskId)).then(() => resp)) // sneaky forward the previous response
      .then((resp) => changeScreen('next',resp))
      .catch(error => Notify.error( get(error, 'response.data.message') ));
  }

  return (
    <div className={taskCSS.holder}>
      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} />
        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>

            <DynamicForm
              id='appointment-form'
              fields={[
                {
                  name: 'current_tenant_key.key_pickup_appointment_id',
                  type: 'itemList',
                  value: get(taskDetails,'responsibility') === 'self' ? 'self_schedule' : '',
                  required: 'You must select an appointment or schedule your own',
                  isMulti: false,
                  isTwoCol: false,
                  className: CSS.roommates_form,
                  options: roommate_appointments.map(appt => {
                    return {
                      label: `${get(appt,'tenant.firstname')} ${get(appt,'tenant.lastname')}`,
                      sublabel: `Picking up keys for ${get(appt,'covered_tenants').map(tenant => tenant.firstname).join(', ')}`,
                      rightLabel: (
                        <div className={CSS.appt_info}>
                          <span className={CSS.appt_date}>{format.date(appt.pickup_date)}</span>
                          <span className={CSS.appt_time}>{format.date(appt.pickup_time,'time',null,['h:ma'])}</span>
                        </div>
                      ),
                      customIcon: (
                        <div className={CSS.oval}>
                          <span>{format.initials(get(appt,'tenant'))}</span>
                        </div>
                      ),
                      value: appt.id,
                    }
                  }).concat([
                    {
                      label: `I will pick up my own keys`,
                      icon: { symbol: 'Time', library: 'code' },
                      value: 'self_schedule',
                    }
                  ])
                },
              ]}
              onSubmit={handleSubmit}
              disabled={pending}
            />

          </div>
        </div>
        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            <label
              id='appointment_form_button'
              htmlFor="appointment-form-submit"
              tabIndex="0"
              role="button"
              className={classNames('btn-primary', 'btn--full', {'loading': pending})}
              disabled={pending}
            >
              Next
            </label>
          </div>
        </div>
      </div>
    </div>
  )
};
