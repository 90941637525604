import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { /*Link,*/ useParams, useHistory } from 'react-router-dom';
import { get } from 'lodash';

import { useUser, userService, useNotify, format } from '@moved/services';
import { Icon, LoaderOverlay } from '@moved/ui';

import { Snippet } from '../../../snippets';
import { MarketplaceLeader } from '../../../dashboard';
import { getMove } from '../../../moves/actions';
import { useMove } from '../../../moves/actions/selectors';
import { useSatisfactionResponse, useUpdateSatisfactionResponsePending} from '../actions/selectors';
import CSS from './styles/Confirmation.module.scss';

export const Confirmation = () => {
  const { buildingSlug, token, level } = useParams();
  const { isAuthenticated } = useUser();
  const Notify = useNotify();
  const history = useHistory();
  const [moveId, setMoveId] = useState();
  const dispatch = useDispatch();
  const move = useMove(moveId);
  const surveyResponse = useSatisfactionResponse(token);

  const pending = useUpdateSatisfactionResponsePending();

  useEffect(() => {
    if(isAuthenticated) {
      userService.getActiveMove()
        .then(({move_id: moveId}) => {
          dispatch(getMove(moveId));
          return setMoveId(moveId);
        })
      .catch(error => Notify.error(format.error(error)));
    }
  },[isAuthenticated]); // eslint-disable-line

  useEffect(() => {
    if(!surveyResponse.submitted_at) {
      // Return to base path if survey has not been submitted
      return history.replace({
        pathname: `/${buildingSlug}/surveys/satisfaction/${token}/${level}`,
      });
    }
    const responseLevel = get(surveyResponse,'satisfaction_level.label');
    if(level !== responseLevel) {
      return history.replace({
        pathname: `/${buildingSlug}/surveys/satisfaction/${token}/${responseLevel}/confirmation`,
      });
    }
  },[surveyResponse]); // eslint-disable-line

  return (
    <>
      {pending && (<LoaderOverlay />)}
      <h1 className={'headingL marginBottom-4'}>
        <Snippet tag={'surveys.satisfaction.feedback.confirmation.title'}>
          Survey complete!
        </Snippet>
      </h1>
      <h3 className={'labelM contentSecondary marginBottom-20'}>
        <Snippet tag={'surveys.satisfaction.feedback.confirmation.subTitle'}>
          Thank you for your time and your feedback.
        </Snippet>
      </h3>
      <div className={CSS.box}>
        <div className={CSS.box_content}>
          <Icon symbol={'Done-circle'} library={'code'} size={'48px'} className={CSS.confirm_icon} />
          <div className={CSS.title}>
            <h1 className={'displayS'}>
              <Snippet tag={'surveys.satisfaction.feedback.confirmation.thanks'}>
                Thank you!
              </Snippet>
            </h1>
          </div>
        </div>
      </div>
      {/* Awaiting the refer functionality
        <div className={CSS.content}>
          <div className={CSS.refer}>
            <div className={CSS.refer_icon}>
              <Icon symbol={'Smile'} library={'general'} size={'24px'} className={CSS.confirm_icon} />
            </div>
            <div className={CSS.text}>
              <h4>Earn up to $500 by referring a future resident!</h4>
              <p>Simply visit your <Link to='/profile' className={CSS.back_icon}>profile</Link> and follow the instructions to "refer a friend".</p>
            </div>
          </div>
      </div>
      */}
      {isAuthenticated && (
        <div className={CSS.marketplace}>
          <MarketplaceLeader move={move} />
        </div>
      )}
    </>
  );
};
