import { get } from 'lodash';

import billingBG from '../assets/billing-faq-bg.jpg';
import tipsBG from '../assets/moving-faq-bg.jpg';

import { BookMoversDirect } from '../../tasks/types/book-movers-for-direct/definition';
import { FaqsDirect } from '../../tasks/types/faqs-for-direct/definition';

export const getPseudoTasks = (move) => {
  const moverBooking = get(move,'mover_bookings[0]');
  return [
    {
      task_type: BookMoversDirect.slug,
      title: 'Hire professional movers',
      subtitle: 'Fill out your move details, get quotes, and book your movers.',
      cta_copy: 'Get started',
      icon_name: 'Box#2',
      id: moverBooking?.id || move?.id, // needs to be move_id for taskable create
      task_details_id: moverBooking?.id,
      status: BookMoversDirect.getMoverBookingStatus(moverBooking),
    },
    {
      task_type: FaqsDirect.slug,
      label: 'Payment',
      title: 'Billing FAQ',
      id: 1,
      task_details_id: 1,
      backgroundImage: billingBG,
    },
    {
      task_type: FaqsDirect.slug,
      label: 'Moving',
      title: 'Tips, Tricks, and FAQs',
      id: 2,
      task_details_id: 2,
      backgroundImage: tipsBG,
    }
  ];
};
