import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { get } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';

import { useNotify, format } from '@moved/services';
import { DynamicForm } from '@moved/ui';

import { ScreenTitle } from '../../shared';
import { HireAHelper } from './HireAHelper';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';

export const LaborQuotes = ({ screen, changeScreen, task }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const hireAHelperAPI = useRef(); // used to access child component functions
  const Notify = useNotify();

  const taskDetails = task.selectors.useTaskable(id);
  const activeMoveStep = task.selectors.useActiveMoveStep();
  const [selectPending, setSelectPending] = useState()
  const [declinePending, setDeclinePending] = useState();
  const pending = selectPending || declinePending;

  const providerAd = taskDetails.labor_provider_ads.find(ad => ad.label === 'hire-a-helper');

  const initialDate = moment(get(activeMoveStep,'lease.start_date')).isAfter() ? moment(get(activeMoveStep,'lease.start_date')) : moment();
  const [date, setDate] = useState(initialDate.format('YYYY-MM-DD'));
  const [zip, setZip] = useState(get(activeMoveStep,'lease.address.zipcode'));
  const [loading, setLoading] = useState(true);
  const fields = [
    {
      type: 'googlePlace',
      name: 'address',
      label: 'Where?',
      placeholder: 'type your address...',
      required: 'You must select an address',
      value: get(activeMoveStep,'lease.address'),
      half: true,
    },
    {
      type: 'datePicker',
      name: 'date',
      label: 'When?',
      placeholder: 'select a date...',
      required: 'You must select a service date',
      minDate: moment().format('YYYY-MM-DD'),
      value: date,
      half: true,
    },
  ];

  const widgetOptions = {
    afterFullyLoaded: () => setLoading(false),
  };

  const _handleChange = ({ address, date}, form) => {
    setZip(get(address,'zipcode'));
    setDate(date);
  };

  const selectProvider = () => {
    if(pending) return;
    setSelectPending(true);
    return dispatch(task.actions.update(id, {[`selected_labor_provider_ad_id`]: providerAd.id}))
      .then(r => dispatch(task.actions.complete(id)))
      .then(r => changeScreen('next',r))
      .catch(err => {
        setSelectPending(false);
        Notify.error(format.error(err));
      });
  };

  const declineProvider = () => {
    if(pending) return;
    setDeclinePending(true);
    return dispatch(task.actions.complete(id))
      .then(r => changeScreen('next',r))
      .catch(err => {
        setDeclinePending(false);
        Notify.error(format.error(err));
      });
  };

  return (
    <div className={taskCSS.holder}>

      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} />
        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>

            <DynamicForm
              id={`${screen.slug}-form`}
              fields={fields}
              formStyle={'underline'}
              onChange={_handleChange}
            />

            <HireAHelper date={date} zip={zip} options={widgetOptions} ref={hireAHelperAPI} />

          </div>
        </div>
        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_choice}>
            <label
              onClick={selectProvider}
              tabIndex="0"
              role="button"
              className={classNames('btn-primary btn--full',{ loading: selectPending })}
              disabled={loading || pending}
            >
              I'm using Hire A Helper
            </label>
            <label
              onClick={declineProvider}
              tabIndex="0"
              role="button"
              className={classNames('btn-gray btn--full',{ loading: declinePending })}
              disabled={pending}
            >
              No thanks
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
