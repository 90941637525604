import { get, merge } from 'lodash';

import { vehicleRegistration as helpers } from '@moved/product';

import { BaseTask } from '../../components/BaseTask';
import * as actions from './actions';
import * as selectors from './actions/selectors';
import { screens } from './screens';
import { routes } from './routes';

export class VehicleRegistration extends BaseTask {

  constructor() {
    super({
      routes: routes,
      screens: screens,
      actions: actions,
      selectors: {
        useTaskable: selectors.useVehicleRegistration,
        useTaskablePending: selectors.useVehicleRegistrationPending,
        ...selectors,
      }
    });
  }

  static label = 'Vehicle Registration';
  static slug = 'vehicle-registration';
  static icon = 'Car';
  static helpers = helpers;

  _flowBuilder(taskable) {
    const request = get(taskable,'requests[0]');
    const vehicles = get(request,'vehicles', []);

    const createVehicleSteps = vehicles => {
      return [
        ...vehicles.map(vehicle => {
          return {
            ...this.screens['vehicle-details'],
            context: vehicle.id,
            key: `${this.screens['vehicle-details'].slug}_${vehicle.id}`,
            label: vehicle.make ? `${vehicle.make} ${vehicle.model}` : "New vehicle",
          };
        }),
        this.screens['summary'],
        this.screens['confirmation'],
      ].filter(v=>v);
    }


    switch(taskable.has_vehicles) {
      case true:
        if(vehicles.length > 0) {
          return createVehicleSteps(vehicles);
        }
        else {
          return [
            this.screens['vehicle-details'],
            this.screens['summary'],
            this.screens['confirmation'],
          ];
        }

      case false:
        return [
          this.screens['vehicle-details'],
          this.screens['confirmation'],
        ];
      default:
        if(vehicles.length > 0) return createVehicleSteps(vehicles);
        return [
          this.screens['vehicle-details'],
          this.screens['mystery'],
          this.screens['confirmation'],
        ];
    };
  }

  getStatusCopy(request, taskDetails) {
    const defaultCopy = super.getStatusCopy(request);
    const requiresApproval = taskDetails?.settings?.requires_approval;
    let requestCopy = {};
    switch(request.status) {
      case 'rejected':
        requestCopy = {
          summary: {
            flavor: 'Review the requested changes below and submit updated vehicle details.',
          },
        };
        break;
      case 'pending-approval':
        requestCopy = {
          summary: {
            flavor: 'Property Management has been notified of your submitted vehicle details. You will be notified when your submission is reviewed.',
          },
        };
        break;
      case 'approved':
        requestCopy = {
          summary: {
            flavor: requiresApproval ?
              'The vehicle details you submitted have been reviewed and approved.' :
              'Your vehicle registration details have been submitted.'
          },
        };
        break;
      default:
        requestCopy = {};
    }
    return merge({},defaultCopy,requestCopy);
  }

  /* START OVERRIDES */
  /* END OVERRIDES */

  /* TASK SPECIFIC */
  getActiveVehicle(taskable, id) {
    const request = get(taskable,'requests[0]');
    if(id) return request.vehicles.find(vehicle => vehicle.id === parseInt(id));
    return false;
  }
  /* END TASK SPECIFIC */

}
