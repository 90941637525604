import React from 'react';
import { isNil, get } from 'lodash';

import { format } from '@moved/services';

export const getRequestFields = request => {
  if(!request) return [];
  return [
    {
      label: 'Provider',
      value: get(request,'utility_provider.name'),
      icon: { symbol: 'Building', library: 'home' },
    },
    {
      label: 'Account number',
      value: get(request,'account_number'),
      icon: { symbol: 'File', library: 'files' },
    },
    {
      label: 'Confirmation number',
      value: get(request,'confirmation_number'),
      icon: { symbol: 'File-done', library: 'files' },
    },
    get(request,'file_url') && {
      label: 'Submitted documents',
      value: (
        <span>
          <a href={get(request,'file_url')} target="_blank" rel="noopener noreferrer">View file</a>
        </span>
      ),
      icon: { symbol: 'Uploaded-file', library: 'files' },
    },
    !isNil(get(request,'consent_to_share_data_with_program_provider')) && {
      label: 'Get GridRewards notifications',
      value: get(request,'consent_to_share_data_with_program_provider') ? 'Yes' : 'No',
      icon: { symbol: 'Done-circle', library: 'code' }
    },
    !isNil(get(request,'consent_to_share_data_with_property')) && {
      label: 'Share utility data for carbon emissions tracking',
      value: get(request,'consent_to_share_data_with_property') ? 'Yes' : 'No',
      icon: { symbol: 'Done-circle', library: 'code' }
    },
    {
      label: 'Provided by',
      value: get(request,'submitted_by.firstname') ? format.fullname(request.submitted_by) : null,
      icon: { symbol: 'User', library: 'general' },
    },
    {
      label: 'Reason for decline',
      value: request?.notes,
      icon: { symbol: 'File', library: 'files' },
    }
  ].filter(v=>v);
};
