import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { get } from 'lodash';

import { DynamicForm, AtomSpinner, Notebox } from '@moved/ui';
import { useNotify } from '@moved/services';

import { ScreenTitle } from '../../shared';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';

const dateFormat = 'YYYY-MM-DD';

export const MoveDate = ({ screen, changeScreen, task }) => {
  // hooks
  const { id, moveId } = useParams();
  const Notify = useNotify();
  const dispatch = useDispatch();
  // state
  const [pending, setPending] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const isTomorrowSelected = moment(selectedDate).isSame(moment().add(1,'d'),'day');
  // redux
  const taskDetails = task.selectors.useTaskable(id);
  const moverBooking = get(taskDetails,'mover_booking',{});
  const soldOutDates = task.selectors.useSoldOutDates(moveId) || [];
  const soldOutDatesPending = task.selectors.useSoldOutDatesPending();
  const isPreviousDateUnavailable = get(moverBooking,'requested_pickup_date') && !task.canBookDate(get(moverBooking,'requested_pickup_date'));

  useEffect(() => {
    dispatch(task.actions.getSoldOutDates(moveId))
      .then(() => setSelectedDate(getInitialValue()));
  },[]); // eslint-disable-line

  const isSoldOut = (day) => {
    return soldOutDates
      .filter(date => date.type === 'sold-out')
      .map(date => date.date)
      .includes(moment(day).format(dateFormat));
  };

  const isUnavailable = (day) => {
    return isSoldOut(day) || !task.canBookDate(day);
  };

  const getInitialValue = () => {
    const suggestedDates = [
      get(moverBooking,'requested_pickup_date'),
      ...get(moverBooking,'suggested_pickup_dates',[]).map(date => date.date),
      ...Array.from({length:14},(_,i) => moment().startOf('day').add(i,'d').format(dateFormat)),
    ].filter(v => v);
    return suggestedDates.find(date => !isUnavailable(date));
  };

  const fields = [
    {
      name: 'mover_booking.id',
      type: 'hidden',
      value: get(moverBooking,'id'),
    },
    {
      type: 'date',
      name: 'mover_booking.requested_pickup_date',
      value: getInitialValue(),
      required: 'Date is required.',
      onChange: (date) => setSelectedDate(date.format(dateFormat)),
      validate: (date) => {
        if(isUnavailable(date)) return `${moment(date).format('MMMM Do')} is no longer available for booking`;
      },
      calProps: {
        boxStyle: true,
        disablePast: true,
        isDayDisabled: isUnavailable,
      }
    },
  ];

  const _handleSubmit = (formData) => {
    setPending(true);
    dispatch(task.actions.update(taskDetails.id, formData))
      .then(resp => changeScreen('next'))
      .catch(err => {
        setPending(false);
        Notify.error();
      });
  };

  return (
    <div className={taskCSS.holder}>

      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} />

        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
            <div className={taskCSS.screen_edit_form}>
              { soldOutDatesPending ? (
                <AtomSpinner />
              ) : (
                <>
                  { isPreviousDateUnavailable && (
                    <Notebox
                      heading='Availability Notice'
                      body={(
                        <p>{ moment(get(moverBooking,'requested_pickup_date')).format('MMMM Do') } is no longer available. Select a new date to continue.</p>
                      )}
                      color='orange'
                      icon={{ library:'code', symbol:'Warning-2' }}
                      style={{maxWidth:'322px'}}
                    />
                  )}
                  { soldOutDates.length > 0 && (
                    <Notebox
                      heading='Availability Notice'
                      body={`Due to high demand, some dates are sold out and no longer available.`}
                      color='orange'
                      icon={{ library:'code', symbol:'Warning-2' }}
                      style={{maxWidth:'322px'}}
                    />
                  )}
                  { isTomorrowSelected && (
                    <Notebox
                      heading='Availability Notice'
                      body={(
                        <p>To book a move for tomorrow, we require booking <strong>before 2pm</strong> in order to provide adequate time to secure a moving company.</p>
                      )}
                      color='orange'
                      icon={{ library:'code', symbol:'Warning-2' }}
                      style={{maxWidth:'322px'}}
                    />
                  )}
                  <DynamicForm
                    id={`${screen.slug}-form`}
                    fields={fields}
                    formStyle={'underline'}
                    onSubmit={_handleSubmit}
                    autocomplete={screen.autocomplete}
                    disabled={pending}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            <label
              htmlFor={`${screen.slug}-form-submit`}
              tabIndex="0"
              role="button"
              className={'btn-primary btn--full' + (pending  ? ' loading' : '')}
              disabled={pending}
            >
              Next
            </label>
          </div>
        </div>
      </div>

    </div>
  );
}
