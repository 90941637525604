import { get, isNil } from 'lodash';

import { fieldService } from '@moved/services';

import * as screenComponents from './components';

export const screens = {

  'has-pets': {
    slug: 'pets',
    label: "Pets",
    title: 'Do you own any pets or service animals?',
    subtitle: 'Your property requires details for all animals living in your apartment',
    component: screenComponents.HavePets,
    submitRequest: true,
    isCompleted: (data, { helpers }) => {
      const request = helpers.getActiveRequest(data);
      return (request && request.status !== 'draft')
        || !isNil(get(data,'has_pets'));
    },
    fields: (taskable = {}) => {
      const fields = [
        {
          type: 'itemList',
          name: 'has_pets',
          label: 'Do you have any pets?',
          hiddenLabel: true,
          required: 'You must tell us if you have any pets',
          options: [
            {
              label: 'I have one or more animals to register',
              value: true,
              icon: { symbol: 'Check', library: 'navigation' },
            },
            {
              label: (<>No, I don&rsquo;t own any pets or service animals</>),
              value: false,
              icon: { symbol: 'Close', library: 'navigation' },
            },
          ]
        }
      ];
      return fieldService.hydrate(fields,taskable);
    }
  },

  'pet-type': {
    slug: 'type',
    label: "Type",
    subStep: true,
    title: 'Which animal would you like to register?',
    subtitle: 'Your property keeps this information on file to help identify your animal',
    component: screenComponents.PetType,
    isCompleted: (data, { getActivePet, helpers }, context) => {
      const request = helpers.getActiveRequest(data);
      if(request && request.status !== 'draft') return true;

      const pet = getActivePet(data, context);
      if(!pet) return false;
      return !!(pet.name && pet.pet_type);
    },
  },

  'pet-details': {
    slug: 'details',
    label: "Details",
    subStep: true,
    title: 'Please provide details about your animal',
    subtitle: 'Your property keeps this information on file to help identify your animal',
    component: screenComponents.PetDetails,
    isCompleted: (data, { getActivePet, helpers }, context) => {
      const request = helpers.getActiveRequest(data);
      if(request && request.status !== 'draft') return true;

      const pet = getActivePet(data, context);
      if(!pet) return false;
      return !!(pet.owner && pet.color);
    },
  },

  'pet-medical': {
    slug: 'medical',
    label: "Medical info",
    subStep: true,
    title: 'Please provide a few medical details',
    subtitle: 'We require these records on file to reduce risks for our residents',
    component: screenComponents.PetMedical,
    isCompleted: (data, { getActivePet, helpers }, context) => {
      const { settings: { neutered_status_enabled, neutered_date_enabled, vaccine_records_enabled } } = data;

      const request = helpers.getActiveRequest(data);
      if(request && request.status !== 'draft') return true;

      const pet = getActivePet(data, context);
      if(!pet) return false;

      return (
        (!neutered_status_enabled || !isNil(pet.is_spayed_or_neutered))
        && (!neutered_date_enabled || !isNil(pet.spayed_or_neutered_date) || pet.is_spayed_or_neutered === false)
        && (!vaccine_records_enabled || get(pet,'vaccine_file_urls.length') > 0)
      );
    },
  },

  'pet-photo': {
    slug: 'photo',
    label: "Photo",
    subStep: true,
    title: 'Upload a photo of your animal',
    subtitle: 'Help us identify your animal in case he/she is lost within the property',
    component: screenComponents.PetPhoto,
    isCompleted: (data, { getActivePet, helpers }, context) => {
      if(!get(data,'settings.photo_enabled')) return true;
      const request = helpers.getActiveRequest(data);
      if(request && request.status !== 'draft') return true;

      const pet = getActivePet(data, context);
      if(!pet) return false;
      return !!(pet.photo_url);
    },
    maxWidth: 780,
  },

  'summary': {
    slug: 'summary',
    label: "Summary",
    title: 'Do you need to register another pet or service animal?',
    subtitle: 'Provide details for another animal, or complete your registration',
    component: screenComponents.PetsSummary,
    isCompleted: (data, { helpers }) => {
      const request = helpers.getActiveRequest(data);
      return request && request.status !== 'draft';
    },
    maxWidth: '1025px',
  },

  'confirmation': {
    slug: 'confirmation',
    component: screenComponents.Confirmation,
    label: 'Confirmation',
    backRestricted: true,
  },
};
