import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_CREATE_MOVER_BOOKING_DIRECT',
  request: (moveId, data) => request.post(`/residents/moves/${moveId}/mover-bookings`, data).then(r => get(r,'data.data.mover_booking')),
  reducers: {
    success: (state, { response }) => ({
      ...state,
      moverBookingTasks: {
        ...state.moverBookingTasks,
        [response.id]: { // create a fake taskable wrapper and just use the same id
          id: response.id,
          mover_booking: response
        },
      },
    }),
  }
};

export const {
  action,
  reducer,
  usePending: useCreateMoverBookingDirectPending,
} = generate.redux(creator);

export const initialState = {
  moverBookingTasks: {},
};
