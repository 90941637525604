import { TaskFlow } from '../../components/TaskFlow';
import { screens } from './screens';

export const routes = [
  {
    path: `/:screenSlug(${Object.keys(screens).map(screen => screens[screen].slug).filter(v=>v).join('|')})/:screenContext?`,
    name: 'taskFlowScreen',
    component: TaskFlow,
    viewKey: ({taskType,id}) => `task-${taskType}-${id}`,
    exact: true,
  },
];
