import React, { useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";

import { useScroller } from '@moved/services';
import { BuildingLogoBlock, Footer } from '@moved/product';

import { useBuilding } from '../../../common/actions/selectors';

import CSS from './styles/SurveyLanding.module.scss';

export const SurveyLanding = ({ children }) => {
  // hooks
  const { buildingSlug } = useParams();
  const Scroller = useScroller();
  const scrollerRef = useRef();
  const building = useBuilding(buildingSlug);

  useEffect(() => {
    const ref = scrollerRef.current;
    return(() => Scroller.set(ref));
  // eslint-disable-next-line
  },[scrollerRef.current]);

  return (
    <div className={CSS.background} ref={scrollerRef}>
      <div className={CSS.container}>
        <BuildingLogoBlock building={building} horizontal={true} />
        <div className={CSS.content}>
          { children }
        </div>
      </div>
      <Footer />
    </div>
  );
};
