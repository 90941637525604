import {
  Instructions,
  Summary,
} from './components';

export const screens = {

  'instructions': {
    slug: 'instructions',
    component: Instructions,
    label: 'PetScreening',
    title: 'Complete your PetScreening profile',
    subtitle: 'Please ensure that you complete your PetScreening profile immediately upon move-in',
    isCompleted: (taskable) => taskable.submitted_at,
    maxWidth: 'none',
  },

  'summary': {
    slug: 'summary',
    component: Summary,
    label: 'Summary',
  },

};
