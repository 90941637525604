import { generate, request } from '@moved/services';
import { get, isEmpty } from 'lodash';

const updateTask = (id,data) => request.patch(`/mover-booking-tasks/${id}`, data).then(r => get(r, 'data.data.mover_booking_task'));
const refreshTask = (id) => request.get(`/mover-booking-tasks/${id}`).then(r => get(r, 'data.data.mover_booking_task'));
const updateMoverBooking = (id, data) => request.patch(`/mover-bookings/${id}`, data).then(r => get(r, 'data.data.mover_booking'));

const creator = {
  namespace: 'TASKS_UPDATE_MOVER_BOOKING_TASK',
  request: (id, { mover_booking, ...data }) => {
    if(mover_booking) {
      const { id: moverBookingId, ...moverBooking } = mover_booking; // pull the moverBookingId out of the mover_booking
      return updateMoverBooking(moverBookingId, moverBooking)
        .then(() => isEmpty(data) ? refreshTask(id) : updateTask(id,data));
    }
    else return updateTask(id, data);
  },
  reducers: {
    success: (state, { response: bookingTask }) => {
      return {
        ...state,
        moverBookingTasks: {
          ...state.moverBookingTasks,
          [bookingTask.id]: bookingTask,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useUpdateMoverBookingTaskPending,
} = generate.redux(creator);
