import { get } from 'lodash';
import { ordinal } from '../utils/formatters/ordinal';

const floorOptions = [
  { value: 'basement', label: 'Basement' },
  { value: 'ground', label: 'Ground Floor' }
].concat(Array.from({length: 60}, (v, i) => ({
  value:(i+1).toString(),
  label:`${ordinal(i+1)} Floor`,
  requireElevator: (i+1) > 7,
})));

const stairOptions = [
  { value: 0, label: 'No Stairs' },
  { value: 1, label: '1 Flight of Stairs' },
  { value: 2, label: '2 Flights of Stairs' },
  { value: 3, label: '3 Flights of Stairs' },
  { value: 4, label: '4 Flights of Stairs' },
  { value: 5, label: '5 Flights of Stairs' },
  { value: 6, label: '6 Flights of Stairs' },
];

const stairElevatorOptions = [
  { value: 'has_elevator', label: 'Elevator' },
  ...stairOptions,
];

const simpleTypeOptions = [
  { label: 'House', value: 'house', icon: { symbol: 'Door-open', }, },
  { label: 'Apartment', value: 'apartment', icon: { symbol: 'Building' }, },
];

const typeOptions = [
  ...simpleTypeOptions,
  { label: 'Storage', value: 'storage', icon: { symbol: 'Home' }, },
  { label: 'Other', value: 'other', icon: { symbol: 'Other#1' }, },
];

const ownershipOptions =  [
  { label: 'Own', value: 'own', },
  { label: 'Rent', value: 'rent', },
];

export const addressService = {
  floorOptions,
  stairOptions,
  stairElevatorOptions,
  simpleTypeOptions,
  typeOptions,
  ownershipOptions,
  getFloorLabel: (stop) => get(floorOptions.find(option => option.value === stop.floor),'label',''),
  getStairsLabel: (stop) => get(stairElevatorOptions.find(option => option.value === (stop.has_elevator ? 'has_elevator' : stop.flights_of_stairs)),'label',''),
  getTypeLabel: (stop) => get(typeOptions.find(option => option.value === stop.type),'label',''),
  getOwnershipLabel: (stop) => get(ownershipOptions.find(option => option.value === stop.deed),'label',''),
};
