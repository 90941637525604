import React from 'react';
import { useParams, Link } from 'react-router-dom';
import classNames from 'classnames';

import { getDashboardRoute } from '../../dashboard/helpers';
import { FaqsDirect } from '../../tasks/types/faqs-for-direct/definition';
import { getPseudoTasks } from '../helpers/getPseudoTasks';

import CSS from './styles/Faqs.module.scss';

const faqs = getPseudoTasks().filter(({task_type}) => task_type === FaqsDirect.slug);

export const Faqs = () => {
  const { moveId } = useParams();
  return (
    <div className={CSS.faq_section}>
      <div className={CSS.title}>
        <h2>Additional resources</h2>
        <p>Learn from the moving experts how to have a stress-free moving day.</p>
      </div>
      <div className={CSS.faq_blocks}>

        { faqs.map(faqTask => (
          <div className={CSS.block_wrapper} key={`${faqTask.task_type}-${faqTask.task_details_id}`}>
            <Link to={`${getDashboardRoute(moveId)}/tasks/${faqTask.id}/${faqTask.task_type}`}>
              <div
                className={CSS.block}
                style={{backgroundImage:`url(${faqTask.backgroundImage})`}}
              >
                <p className={CSS.faq_label}>{faqTask.label}</p>
                <h2 className={CSS.faq_title}>{faqTask.title}</h2>
                <div className={classNames('btn-white',CSS.faq_cta)}>View</div>
              </div>
            </Link>
          </div>
        ))}

      </div>
    </div>
  );
};
