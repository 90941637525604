import { PersonalTaskList } from './components/PersonalTaskList';
import { Dashboard } from './components/Dashboard';
import { withContactForm } from '../contact';

const routes = [
  {
    path: '/personal-tasks',
    name: 'DashboardLanding',
    component: withContactForm(PersonalTaskList),
    transition: 'crossFade',
    viewKey: ({moveId}) => `move-${moveId}-tasks`,
    children: [
      {
        path: '/',
        name: 'PersonalTasksDashboard',
        component: Dashboard,
        exact: true,
      },
    ],
  },
].filter(Boolean);

export default routes;
