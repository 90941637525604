import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { get } from 'lodash';
import classNames from 'classnames';

import { useNotify, format } from '@moved/services';
import { AtomSpinner, Icon, Rating } from '@moved/ui';

import { ScreenTitle } from '../../shared';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import companiesCSS from './styles/ViewMovingCompanyAds.module.scss';

const CompanyCard = ({ company, selected, onSelect }) => (
  <div className={classNames(companiesCSS.company_card, {[companiesCSS.selected_item]: selected})} onClick={() => onSelect(company)}>
    {/* company logo */}
    { company.image && (
      <img className={companiesCSS.company_icon} src={`${process.env.REACT_APP_S3_ASSET_URL}/vendors/logos/${company.image}`} alt={company.name}/>
    )}
      <div className={companiesCSS.company_content}>
        <div className={companiesCSS.content_row}>

          {/* company name */}
          <label className={companiesCSS.company_name}>{company.name}</label>

          {/* featured tag */}
          { company.is_featured && (
            <span className={companiesCSS.tag}>Featured</span>
          )}

        </div>
        <div className={companiesCSS.content_row}>
          {/* reviews stars */}
          <span className={companiesCSS.reviews_row}>
            <Rating size='small' value={company.review_rating} precision={0.5} readOnly={true} className={companiesCSS.company_stars} classes={{sizeSmall:companiesCSS.stars_small}} /> {company.review_rating}
            <span className={companiesCSS.company_reviews_count}>({company.num_reviews})</span>
            <a href={company.review_url} target='_blank' className={companiesCSS.review_link} rel="noopener noreferrer">
              <Icon library='navigation' symbol='Up-right' size='16px' color='inherit' className={companiesCSS.review_link_icon} />
              <span className={companiesCSS.review_link_text}>View reviews</span>
            </a>
          </span>
          {/* review link */}
        </div>
      </div>
      {/* company rate */}
      <div className={companiesCSS.company_rate}>
        <label>starting at</label>
        <span>{format.currency(company.min_hourly_rate_in_cents, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}/hr</span>
      </div>
      {/* checkbox */}
      <div className={companiesCSS.item_check}>
        <Icon size='16px' symbol='Check' color='white' />
      </div>
  </div>
);

export const ViewMovingCompanyAds = ({ screen, changeScreen, task }) => {
  // hooks
  const { id } = useParams();
  const Notify = useNotify();
  const dispatch = useDispatch();

  // redux
  const taskDetails = task.selectors.useTaskable(id);
  const { featured_companies: featuredCompanies=[], non_featured_companies: companies=[]} = task.selectors.useMovingCompanyAds(id) || {};
  const updatePending = task.selectors.useUpdateMoverBookingTaskPending();
  const completePending = task.selectors.useCompleteMoverBookingTaskPending();
  const pending = updatePending || completePending;

  // state
  const [selected, setSelected] = useState((get(taskDetails,'selected_moving_company_ads') || []).map(company => company.id));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(task.actions.getMovingCompanyAds(id))
      .finally(() => setLoading(false))
      .catch(err => Notify.error('Unable to retrieve moving company list.'))
  // eslint-disable-next-line
  },[]); // only run on mount

  useEffect(() => {
    if(
      (
        !loading &&
        !companies.length &&
        !featuredCompanies.length
      ) || get(taskDetails,'selected_moving_company_ads')
    ) handleSkip();
  }, [companies, featuredCompanies, loading]);  // eslint-disable-line

  const onSelect = (company) => {
    if(selected.includes(company.id)) setSelected(selected.filter(id => id !== company.id));
    else setSelected([...selected, company.id]);
  };

  // Submit form function
  const handleSubmit = (skip) => {
    if(pending || (!selected.length && !skip) || (selected.length && skip)) return;
    const data = get(taskDetails,'selected_moving_company_ads') ?
      {} : // cannot resubmit if already saved selection, but must still mark complete
      { selected_moving_company_ad_ids: skip ? [] : selected };
    dispatch(task.actions.update(id, data))
      .then(r => dispatch(task.actions.complete(id)))
      .then(r => changeScreen('next', r))
      .catch(err => Notify.error(format.error(err, {'moving-company-ad-responses-exist':'Contact preferences already saved.'})));
  };

  const handleSkip = () => handleSubmit(true);

  return (
    <div className={taskCSS.holder}>

      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} />
        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
            { loading ? (
              <>
                <h5 className={companiesCSS.title} style={{textAlign: 'center', marginTop: '100px'}}>Gathering recommendations</h5>
                <AtomSpinner />
              </>
            ) : (
              <>
                { featuredCompanies.length > 0 && (
                  <div className={companiesCSS.section}>
                    <h5 className={companiesCSS.title}>Featured</h5>
                    { featuredCompanies.map(company => (
                      <CompanyCard company={company} key={company.id} onSelect={onSelect} selected={selected.includes(company.id)} />
                    ))}
                  </div>
                )}
                { companies.length > 0 && (
                  <div className={companiesCSS.section}>
                    <h5 className={companiesCSS.title}>Moving Companies</h5>
                    { companies.map(company => (
                      <CompanyCard company={company} key={company.id} onSelect={onSelect} selected={selected.includes(company.id)} />
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_choice}>
            <label
              tabIndex="0"
              role="button"
              onClick={() => handleSubmit()}
              className={classNames('btn-primary btn--full',{ loading: updatePending })}
              disabled={ pending || !selected.length }>
              Next
            </label>
            <label
              tabIndex="10"
              role="button"
              onClick={() => handleSkip()}
              className={'btn-ghost'}
              disabled={ pending || selected.length }>
              No thanks
            </label>
          </div>
        </div>

      </div>

    </div>
  );
}
