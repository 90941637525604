import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { get } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';

import { DynamicForm } from '@moved/ui';
import { useNotify, format, fieldService } from '@moved/services';

import { Snippet } from '../../../../snippets';
import { ScreenTitle } from '../../shared';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';

export const PolicyDetails = ({ screen, changeScreen, task }) => {
  // hooks
  const { id } = useParams();
  const Notify = useNotify();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  // redux
  const activeMoveStep = task.selectors.useActiveMoveStep();
  const building = task.selectors.useBuilding(get(activeMoveStep,'building.slug'));
  const taskDetails = task.selectors.useRenterInsurance(id);
  const pending = task.selectors.useUpdateRenterInsuranceRequestPending();

  const activeRequest = task.helpers.getActiveRequest(taskDetails);

  const _handleSubmit = ({ policy_term, confirmation, ...policyData}) => {
    if(pending) return;
    dispatch(task.actions.updateRequest(activeRequest.id, policyData))
      .then(resp => {
        // If this screen is in the flow it is *sometimes* last so it submits the policy
        // only when the flag 'submitRequest' has been set on the screen by the flow builder
        if(screen.submitRequest) return dispatch(task.actions.submitRequest(activeRequest.id));
        else return resp;
      })
      .then(resp => changeScreen('next', resp))
      .catch(err => Notify.error());
  };

  const fields = [
    {
      label: 'Policy details',
      type: 'title',
    },
    {
      label: 'Insurance company',
      type: 'text',
      name: 'insurance_provider',
      required: true,
    },
    {
      label: 'Policy number',
      type: 'text',
      name: 'policy_number',
      required: true,
    },
    {
      label: 'Liability coverage amount',
      type: 'currency',
      name: 'liability_coverage_amount',
      required: true,
    },

    {
      label: 'Coverage timeline',
      type: 'title',
    },
    {
      label: 'Policy Term',
      type: 'slideToggle',
      name: 'policy_term',
      default: 12,
      required: true,
      toggleProps: { full: true, rectangular: true },
      onChange: (data, setFieldValue, name) => {
        if(data !== 'other' && get(formData,'coverage_start')) {
          const coverageEndDate = moment(get(formData,'coverage_start')).add(data,'M');
          setFieldValue('coverage_end',coverageEndDate.format('YYYY-MM-DD'));
        }
      },
      options: [
        { label: '12 months', value: 12, },
        { label: '6 months', value: 6, },
        { label: 'Other', value: 'other', },
      ]
    },
    {
      label: 'Start date',
      type: 'datePicker',
      name: 'coverage_start',
      half: true,
      required: true,
      maxDate: get(taskDetails,'latest_coverage_start_date'),
      flavor: 'Coverage must begin on or before your lease start date',
      onChange: (data, setFieldValue, name) => {
        if(get(formData,'policy_term') !== 'other') {
          const coverageEndDate = moment(data).add(get(formData,'policy_term',12),'M');
          setFieldValue('coverage_end',coverageEndDate.format('YYYY-MM-DD'));
        }
      },
    },
    {
      label: 'End date',
      type: 'datePicker',
      name: 'coverage_end',
      half: true,
      required: true,
      minDate: get(taskDetails,'latest_coverage_start_date'),
      onChange: (data, setFieldValue, name) => {
        if(get(formData,'coverage_start')) {
          const term = moment(data).diff(get(formData,'coverage_start'),'months',true);
          if(term === 12) { if(get(formData,'policy_term') !== 12) setFieldValue('policy_term',12); }
          else if(term === 6) { if(get(formData,'policy_term') !== 6) setFieldValue('policy_term',6); }
          else { if(get(formData,'policy_term') !== 'other') setFieldValue('policy_term','other'); }
        }
      },
    },
    {
      name: 'confirmation',
      label: (
        <>
          I confirm that <Snippet tag={'tasks.insurance.interested_party_name'} description={'Interested party name'}>{get(building,'partner.name')}</Snippet> is listed as an "Interested Party"
        </>
      ),
      type: 'checkbox',
      value: false,
      required: 'Confirmation is required in order to continue',
    },
  ];
  const hydratedFields = fieldService.hydrate(fields,activeRequest);
  const validation = Yup.object().shape({
    liability_coverage_amount: Yup.number()
      .required('Liability coverage amount is required')
      .min(
        taskDetails.minimum_coverage_requirement ? taskDetails.minimum_coverage_requirement : 10000000,
        `Liability coverage amount must be at least ${taskDetails.minimum_coverage_requirement ? format.currency(taskDetails.minimum_coverage_requirement) : '$100,000'}`
      )
      .max(2000000000,'Maximum coverage amount is $20,000,000.00'),
    coverage_end: Yup.date('End date must be a valid date').when('coverage_start',
      (coverage_start, schema) => {
        if(coverage_start && moment(coverage_start).isValid()) {
          return schema
            .min(
              moment(coverage_start).add(1, 'day').toDate(),
              `Coverage end date must be after start date`
            )
            .max(
              moment(coverage_start).add(2,'year').toDate(),
              `Coverage end date must be within two years of start date.`
            );
        } else {
          return schema.min(
            moment().add(1, 'day').toDate(),
            `Coverage end date must be after start date`
          );
        }
      },
    ),
  },['coverage_start','coverage_end']);

  return (
    <div className={taskCSS.holder}>

      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} />

        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
            <div className={taskCSS.screen_edit_form}>
              <DynamicForm
                id={`${screen.slug}-form`}
                fields={hydratedFields}
                validation={validation}
                formStyle={'underline'}
                onSubmit={_handleSubmit}
                onChange={setFormData}
                autocomplete={screen.autocomplete}
                disabled={pending}
              />
            </div>
          </div>
        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            <label
              htmlFor={`${screen.slug}-form-submit`}
              tabIndex="0"
              role="button"
              className={classNames('btn-primary btn--full',{'loading':pending})}
              disabled={pending}
            >
              Next
            </label>
          </div>
        </div>
      </div>

    </div>
  );
}
