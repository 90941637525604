import React from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import classNames from 'classnames';

import { useUser, useNotify, useModal, format } from '@moved/services';
import { Notebox, Button } from '@moved/ui';

import { claimAccount } from '../actions';
import { useClaimAccountPending } from '../actions/selectors';

import { ClaimAccountVerificationModal } from './ClaimAccountVerificationModal';

import CSS from '../styles/ClaimAccountSummary.module.scss';

export const ClaimAccountSummary = ({ code, claim, title, subtitle, onComplete }) => {

  const Notify = useNotify();
  const Modal = useModal();
  const dispatch = useDispatch();
  const pending = useClaimAccountPending();
  const { user, logout } = useUser();

  const isUserTenantMatch = get(user,'email') === get(claim,'email');

  const fields = [
    { label: 'First name', value: get(claim,'firstname') },
    { label: 'Last name', value: get(claim,'lastname') },
    { label: 'Email', value: get(claim,'email') },
    get(claim,'unit') && { label: 'Unit number', value: get(claim,'unit') },
    get(claim,'lease_date') && { label: `Lease ${get(claim,'move_step_type') === 'move-in' ? 'start' : 'end'} date`, value: format.date(get(claim,'lease_date')) },
  ].filter(v=>v);

  const verify = () => {
    Modal.open(<ClaimAccountVerificationModal claim={claim} />, {
      onClose: (result) => {
        if(result === true) submit();
      },
    });
  };

  const submit = () => {
    if(pending) return;
    dispatch(claimAccount(get(claim,'building_id'), { claim_token: code }))
      .then((response) => {
        if(onComplete) onComplete(response);
      })
      .catch(err => {
        Notify.error(format.error(err));
      });
  };

  return (
    <>
      <div className={CSS.content}>
        <div className={CSS.flex_wrapper}>
          <h1 className={CSS.title}>{title}</h1>
          <h3 className={CSS.subtitle}>{subtitle}</h3>
          { !isUserTenantMatch && (
            <Notebox
              icon={{ library:'code', symbol:'Warning-2' }}
              color='orange'
              heading={(<>You are currently logged in as <strong>{ user.fullname } ({user.email})</strong></>)}
              className='mt-30'
            />
          )}

          <div className={CSS.card_wrapper}>
            { fields.map(field => (
              <div className={CSS.field} key={field.label}>
                <label>{ field.label }</label>
                <div>{ field.value }</div>
              </div>
            ))}
          </div>
          <p className='mt-25'>
            Please confirm if you are attempting to link this { get(claim,'move_step_type') }{' '}
            to your existing account, or if you want to create a new account for{' '}
            {get(claim,'firstname')} {get(claim,'lastname')}.
          </p>
        </div>
      </div>
      <div className={CSS.footer}>
        <Button
          text={`Link account under ${user.fullname}`}
          size='default'
          color={isUserTenantMatch ? 'primary' : 'secondary'}
          tabIndex="0"
          disabled={pending}
          onClick={verify}
          className={classNames({[CSS.reorder_last]: !isUserTenantMatch})}
          icon={{
            symbol:'Attachment#2',
            library:'general'
          }}
        />
        <div className={classNames('labelS contentSecondary',CSS.divider)}>or</div>
        <Button
          text={isUserTenantMatch ? `Register a new email address` : `Create new account for ${get(claim,'firstname')} ${get(claim,'lastname')}`}
          size='default'
          color={isUserTenantMatch ? 'secondary' : 'primary'}
          tabIndex="10"
          disabled={pending}
          onClick={() => logout()}
          className={classNames({[CSS.reorder_first]: !isUserTenantMatch})}
          icon={{
            symbol:'Crew-2',
            library:'people',
          }}
        />
      </div>
    </>
  );

};
