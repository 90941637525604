import React from 'react';
import { useParams, useHistory } from 'react-router-dom'

import { CompleteCard, FieldList, Button } from '@moved/ui';

import { Snippet } from '../../../../snippets';
import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  CompleteScreenActions
} from '../../shared';

export const Complete = ({ screen, task, origin, taskBaseRoute }) => {

  // HOOKS
  const { id } = useParams();
  const addon = task.selectors.useTaskable(id);
  const history = useHistory();

  const attributes = [
    ...task.helpers.getSummaryFields(addon),
    {
      value: (
        <Button
          text='Change selection'
          color='secondary'
          size='small'
          onClick={() => history.push(`${taskBaseRoute}${task.getStartOverRoute(addon)}`)}
        />
      ),
    },
  ];

  const flavor = (<Snippet tag={'tasks.addon-interests.summary'}></Snippet>);

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} />
      <ScreenContent screen={screen}>
        <CompleteCard status={addon.status || 'approved'} flavor={flavor}>
          <FieldList list={attributes}/>
        </CompleteCard>
      </ScreenContent>
      <CompleteScreenActions origin={origin} />
    </ScreenLayout>
  );

};
