import { clone } from 'lodash';

import { format } from '@moved/services';
import { base as baseHelpers } from '@moved/product';

import { screens as sharedScreens } from '../types/shared/screens';
import { useBuilding } from '../../common/actions/selectors';
import { useMove } from '../../moves/actions/selectors';
import { useActiveMoveStep } from '../../dashboard/contexts';
import * as defaults from './defaults';

export class BaseTask {
  constructor({ routes=[], screens={}, actions={}, selectors={} }) {
    // expose statics to the instance
    this.slug = this.constructor.slug;
    this.label = this.constructor.label;
    this.icon = this.constructor.icon;
    this.helpers = this.constructor.helpers || {};
    this.config = this.constructor.config || {};

    // Extensible attributes
    this.routes = [ ...routes ];
    this.screens = { ...sharedScreens, ...screens };
    this.actions = { ...actions };
    this.selectors = {
      useActiveMoveStep,
      useBuilding,
      useMove,
      ...selectors
    };

    // Local Data
    this._flow = [];
    this._flowNested = [];

  }

  // Statics
  static slug = '';
  static label = 'BaseTask';
  static icon = 'Info';
  static lockedMessage = 'Complete the tasks above before reviewing this information.';
  static helpers = baseHelpers;
  static config = {
    supportsArtifacts: false,
  };

  _flowBuilder(taskDetails) {
    throw new Error(`${this.name} task flow is missing a flowBuilder method`);
  }

  flattenFlow(flow) {
    let flat = clone(flow);
    let screensAdded = 0;
    flow.forEach((item, idx) => {
      if(item.isCategory) {
        const totalIndex = idx+screensAdded;
        flat.splice(totalIndex+1,0,...item.screens)
        screensAdded += item.screens.length;
      }
    })
    flat = flat.filter(item => !item.isCategory)
    return flat;
  }

  get flow() {
    return this._flow;
  }

  get flowNested() {
    return this._flowNested;
  }

  updateFlow(taskDetails) {
    this._flowNested = this._flowBuilder(taskDetails);
    return this._flow = this.flattenFlow(this._flowNested) || [];
  }

  // Route to the task flow base
  getBaseRoute(taskId) {
    return defaults.getBaseRoute(taskId, this);
  }

  // Route to a specific screen
  getScreenRoute(screen, taskDetails) {
    return defaults.getScreenRoute(screen, taskDetails.id);
  }

  // Route back to starting screen
  getStartOverRoute(taskDetails) {
    this.updateFlow(taskDetails); // ensure flow is up-to-date
    return this.getScreenRoute(this._flow[0], taskDetails);  // return the route to the first screen in the flow
  }

  // Find the most appropriate next screen based on current taskable data
  getRecommendedScreen(taskDetails) {
    return defaults.getRecommendedScreen(taskDetails, this._flow, this);
  }

  // Route to next screen based on task data
  getRecommendedRoute(taskDetails) {
    return this.getScreenRoute(this.getRecommendedScreen(taskDetails), taskDetails);
  }

  // Access control logic
  canAccessScreen(taskDetails, slug, context) {
    return defaults.canAccessScreen(taskDetails, slug, context, this);
  }

  // Status variable content
  getStatusCopy(request) {
    switch(request.status) {
      case 'cancelled':
        return {
          summary: {
            title: 'Canceled',
            flavor: `This submission was canceled on ${format.date(request.cancelled_at,'dateTime')}`,
            color: 'red',
          },
          history: {
            title: `Canceled on ${format.date(request.cancelled_at,'dateTime')}`,
            label: 'Canceled',
            color: 'red',
          },
        };
      case 'rejected':
        return {
          summary: {
            title: 'Declined',
            color:'red',
          },
          history: {
            title: `Declined on ${format.date(request.responded_at,'dateTime')}`,
            label: 'Declined',
            color: 'red',
          },
        };
      case 'pending-approval':
        return {
          summary: {
            title: 'Waiting for approval',
            color: 'brown',
          },
          history: {
            title: 'Submission pending approval',
            label: `Pending`,
            color: 'orange',
          },
        };
      case 'approved':
        return {
          summary: {
            title: "Congrats, you're all set!",
            color: 'green',
          },
          history: {
            title: request.responded_at
              ? `Approved on ${format.date(request.responded_at,'dateTime')}`
              : `Submitted on ${format.date(request.submitted_at,'dateTime')}`,
            label: `On hold`,
            color: 'orange',
          },
        };
      case 'override':
        return {
          summary: {
            title: `Congrats, you're all set!`,
            color: 'green',
          }
        };
      default:
        return { summary: {}, history: {} };
    }
  }

}
