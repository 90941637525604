import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useDrawer, useNotify, format } from '@moved/services';
import {
  Button,
  DrawerHeader,
  DrawerContent,
  DrawerActions,
  AtomSpinner,
} from '@moved/ui';

import { TaskNotes } from '../common/TaskNotes';
import { TaskStatus } from '../common/TaskStatus';
import { TaskSubtasks } from '../common/TaskSubtasks';

import { useMove } from '../../../moves/actions/selectors';

import { getTaskList } from '../../actions/getTaskList';
import { getTask, useTask } from '../../actions/getTask';
import { updateTask, useUpdateTaskPending } from '../../actions/updateTask';

import { BookMoversLink } from './BookMoversLink';

export const BookMoversDrawer = ({ task, taskListId, moveId }) => {
  const dispatch = useDispatch();
  const Notify = useNotify();
  const Drawer = useDrawer();

  const taskDetails = useTask(task.id);
  const move = useMove(moveId);

  // loading is for initial load only
  const [loading, setLoading] = useState(true);
  // pending is for any active requests triggered by user interactions
  const pending = useUpdateTaskPending();

  useEffect(() => {
    dispatch(getTask(task.id))
      .then(() => setLoading(false))
      .catch(err => Notify.error(format.error(err)));
  },[task, dispatch, Notify]);

  const toggleComplete = () => {
    if(pending) return;
    const status = task.status === 'todo' ? 'complete' : 'todo';
    dispatch(updateTask(task.id,{ status }))
      .then(() => dispatch(getTaskList(taskListId)))
      .then(() => Drawer.close())
      .catch(err => Notify.error(format.error(err)));
  };

  return (
    <>
      <DrawerHeader title={task?.title} />
      <DrawerContent>
        { loading ? (
          <AtomSpinner />
        ) : (
          <div className='stackVertical gap-40'>
            {/* Status, due date, reminder section */}
            <TaskStatus taskDetails={taskDetails} />
            {/* Subtasks section */}
            <TaskSubtasks taskDetails={taskDetails} />
            {/* Notes section */}
            <TaskNotes taskDetails={taskDetails} />
            {/* Task specific CTA to link into RA task */}
            <BookMoversLink taskDetails={taskDetails} move={move} />
          </div>
        )}
      </DrawerContent>
      <DrawerActions>
        <Button
          text='Close'
          color='secondary'
          onClick={() => Drawer.close()}
          disabled={pending}
        />
        { taskDetails?.status === 'todo' ? (
          <Button
            text='Mark complete'
            color='primary'
            icon={{ library: 'code', symbol: 'Done' }}
            onClick={toggleComplete}
            disabled={pending}
          />
        ) : (
          <Button
            text='Mark incomplete'
            color='tertiary'
            showWarning={true}
            icon={{ library: 'code', symbol: 'Done' }}
            onClick={toggleComplete}
            disabled={pending}
          />
        )}
      </DrawerActions>
    </>
  );
}
