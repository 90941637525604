import { useParams } from 'react-router-dom';

import { useActiveTask } from '../contexts/TaskContext';
import { getDashboardRoute } from '../../dashboard/helpers';
import { TaskLayout } from './TaskLayout';

export const TaskSummary = () => {
  const { moveId, stepId } = useParams();
  const {
    activeTaskDefinition,
    activeTaskSummary,
    activeTaskBaseRoute
  } = useActiveTask();

  const screen = activeTaskDefinition.screens['summary'];
  const dashboardRoute = getDashboardRoute(moveId, stepId);

  return (
    <TaskLayout origin={dashboardRoute}>

      <screen.component
        screen={screen}
        task={activeTaskDefinition}
        taskDefinition={activeTaskDefinition}
        taskBaseRoute={activeTaskBaseRoute}
        taskSummary={activeTaskSummary}
        origin={dashboardRoute}
      />

    </TaskLayout>

  );
};
