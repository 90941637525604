import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CSS from './Options.module.scss';

export const Options = ({
  options=[],
  isOpen,
  onSelect,
  emptyContent='None available',
  className,
}) => {
  if(!isOpen) return null;
  return (
    <div className={classNames(CSS.optionList,className)}>
      { options.map(option => (
        <div className={CSS.option} key={option.label} onClick={() => onSelect(option)}>{ option?.label }</div>
      ))}
      { options.length === 0 && <div className={CSS.empty}>{ emptyContent }</div> }
    </div>
  )
};

Options.propTypes = {
  /** Available options in the list */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Value to use for this option */
      value: PropTypes.any.isRequired,
      /** Label text for this option */
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  /** Boolean control flag to display or hide the list of options */
  isOpen: PropTypes.bool,
  /** Handler function when an option is selected */
  onSelect: PropTypes.func,
  /** Class name to add to the list container */
  className: PropTypes.string,
};
