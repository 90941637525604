import {
  Payment,
  Summary,
} from './components';

export const screens = {

  'payment': {
    slug: 'make-payment',
    component: Payment,
    label: 'Payment',
    title: 'Pay your move-in costs',
    subtitle: 'Please ensure that you complete the payment instructions before moving in.',
    isCompleted: (taskable, task) => task.helpers.getActiveRequest(taskable),
  },

  'summary': {
    slug: 'confirmation',
    component: Summary,
    label: 'Confirmation',
  },

};
