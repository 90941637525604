import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useOptionalControl } from '../useOptionalControl';
import CSS from './Toggle.module.scss';

export const Toggle = ({
  name,
  label,
  value,
  isControlled,
  error,
  disabled,
  onChange,
  className,
}) => {
  const [isActive, setIsActive] = useOptionalControl(Boolean(value), isControlled);
  const handleChange = () => {
    if(disabled) return;
    setIsActive(prev => !prev);
    onChange?.({[name]:!isActive});
  };

  return (
    <div className={classNames(CSS.wrapper,className)}>
      <input
        className={CSS.input}
        name={name}
        type='checkbox'
        checked={isActive}
        disabled={disabled}
        id={name}
        readOnly
      />
      <label
        htmlFor={name}
        className={classNames(CSS.toggle,{ [CSS.error]: error })}
        onClick={handleChange}
      />
      { label && (
        <label
          htmlFor={name}
          className={classNames(CSS.label,'labelM','contentPrimary')}
          onClick={handleChange}
        >
          { label }
        </label>
      )}
    </div>
  );
};

Toggle.propTypes = {
  /** Name to use for the form input */
  name: PropTypes.string,
  /** Label text for the toggle */
  label: PropTypes.string,
  /** Toggle active state (only initial value if not controlled) */
  value: PropTypes.bool,
  /** Flag to make the input a controlled input */
  isControlled: PropTypes.bool,
  /** Display error state flag */
  error: PropTypes.bool,
  /** Flag to disable the input */
  disabled: PropTypes.bool,
  /** onChange handler function */
  onChange: PropTypes.func,
  /** (optional) class name to add to the Toggle component */
  className: PropTypes.string
};
