/*
* Custom service wrapper for context that keeps track of breadcrumbs based
*  on user's current location and comparing with route tree configuration.
*
* The hook for this context `useBreadcrumbs` provides the following utilities:
*   setRoutes: Set the route config context for generating active breadcrumbs
*
* Use full context for class component
* example:
*    import { BreadcrumbsContext } from '@moved/services';
*    static contextType = BreadcrumbsContext;
*    const breadcrumbs = this.context.breadcrumbs;
*
* Use context hook for functional component
* example:
*    import { useBreadcrumbs } from '@moved/services';
*    const { crumbs } = useBreadcrumbs();
*
* Use context provider component for wrapping the global application
* (not needed more than once per app, usually in the Root component)
* example:
*    import { BreadcrumbsProvider } from '@moved/services';
*    render (
*      <BreadcrumbsProvider> ... </BreadcrumbsProvider>
*    )
*/

import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getBreadcrumbs } from './getBreadcrumbs';

const BreadcrumbsContext = createContext();
const useBreadcrumbs = () => useContext(BreadcrumbsContext);


const BreadcrumbsProvider = ({children, ...props}) => {
  // hooks
  const location = useLocation();
  const [routeTree, setRouteTree] = useState([]);
  const [crumbs, setCrumbs] = useState([]);

  useEffect(() => {
    if(routeTree.length > 0) {
      setCrumbs(getBreadcrumbs({
        routes: routeTree,
        location,
      }));
    }

  },[location, routeTree]);

  // function for setting the routes configuration
  const setRoutes = routes => setRouteTree(routes);

  // map the context API
  const context = {
    crumbs,
    setRoutes,
  };

  return (
    <BreadcrumbsContext.Provider value={context}>{ children }</BreadcrumbsContext.Provider>
  );
};

export { BreadcrumbsContext, useBreadcrumbs, BreadcrumbsProvider };
