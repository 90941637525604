import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_MOVER_BOOKING_DIRECT',
  request: (id) => request.get(`/mover-bookings/${id}`).then(r => get(r, 'data.data.mover_booking')),
  selector: (state, id) => get(state, `moverBookingTasks.${id}`),
  reducers: {
    success: (state, { response }) => ({
      ...state,
      moverBookingTasks: {
        ...state.moverBookingTasks,
        [response.id]: {
          id: response.id,
          mover_booking: response,
        },
      },
    }),
  },
};

export const {
  action,
  reducer,
  useResource: useMoverBookingDirect,
  usePending: useMoverBookingDirectPending,
} = generate.redux(creator);

export const initialState = {
  moverBookingTasks: {},
};
