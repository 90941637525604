import React from 'react';
import { useHistory, useParams } from 'react-router-dom'

import { useUser } from '@moved/services';
import { Icon, Button } from '@moved/ui';

import { SummaryScreen } from '../../shared/components/SummaryScreen';
import { useBadgeUpload } from '../actions/selectors';

export const Summary = ({ screen, task, origin, taskBaseRoute }) => {
  // HOOKS
  const { id } = useParams();
  const history = useHistory();
  const { user } = useUser();
  const badge = useBadgeUpload(id);

  const handleReset = () => history.push(`${taskBaseRoute}${task.getStartOverRoute(badge)}`);

  return (
    <SummaryScreen
      origin={origin}
      screen={screen}
      extraAttributes={[
        {
          label: 'Title',
          value: <><Icon symbol={'Done'} library={'code'} color={'green'} /> {user.fullname}</>,
          icon: { symbol: 'Proof-of-renters', library: 'task' },
        },
        {
          value: (
            <Button
              size='small'
              color='secondary'
              onClick={handleReset}
              text='Upload a new photo'
            />
          )
        }
      ]}
    />
  )

};
