import React from 'react';
import { useDispatch } from 'react-redux';

import { Icon } from '@moved/ui';

import { BookMoversDirect } from '../../tasks/types/book-movers-for-direct/definition';
import { TaskListItem } from '../../dashboard';
import { getMove } from '../../moves/actions';
import { getPseudoTasks } from '../helpers/getPseudoTasks';

import CSS from './styles/BookMovers.module.scss';

export const BookMovers = ({ move }) => {
  const dispatch = useDispatch();

  const taskDefinition = new BookMoversDirect();
  const moverBookingTask = getPseudoTasks(move).find(({ task_type }) => task_type === BookMoversDirect.slug);

  const handleCreateMoverBooking = () => {
    return moverBookingTask.task_details_id ?
      Promise.resolve(moverBookingTask) :
      dispatch(taskDefinition.actions.create(moverBookingTask.id))
        // refresh the move since we've just created a moverBooking
        .then(resp => dispatch(getMove(move.id)).then(() => resp)) // sneaky .then() to forward the initial response
        .then(resp => ({
          ...moverBookingTask,
          id: resp.id,
          task_details_id: resp.id,
        }));
  }

  return (<>
    <div className={CSS.intro_title}>
      <div className={CSS.book_movers_upsell}>
        <h1>Your Move, Your Way</h1>
        <p>Moved is your advocate throughout the moving process.</p>
        <div className={CSS.benefit_grid}>
          <div className={CSS.benefit}>
            <div className={CSS.benefit_icon}>
              <Icon symbol='Timer' color='blue' size='40px'/>
            </div>
            <h3>Save Time</h3>
            <p>One platform to complete your entire move.</p>
          </div>
          <div className={CSS.benefit}>
            <div className={CSS.benefit_icon}>
              <Icon symbol='Dollar' color='blue' size='40px'/>
            </div>
            <h3>Save Money</h3>
            <p>Receive competitive rates from our trusted network of movers.</p>
          </div>
          <div className={CSS.benefit}>
            <div className={CSS.benefit_icon}>
              <Icon symbol='Smile' color='blue' size='40px'/>
            </div>
            <h3>Save Headaches</h3>
            <p>We coordinate the entire process so you don't have to.</p>
          </div>
        </div>
      </div>
    </div>

    <div className={CSS.task_section}>
      <TaskListItem task={moverBookingTask} onLaunch={handleCreateMoverBooking} />
    </div>
  </>);
};
