import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';

import { useNotify, useUser, userService, format } from '@moved/services';
import { LayoutPartnerPromo, DefaultPageNotFound } from '@moved/product';
import { LoaderOverlay, Icon, ContentRenderer } from '@moved/ui';

import { SignupForm } from '../../user';
import { CouponTicket } from './CouponTicket';
import { getLandingPage } from '../actions';
import { useLandingPage } from '../actions/selectors';

import CSS from '../styles/LandingPage.module.scss';

export const LandingPage = (props) => {
  // hooks
  const { landingPageSlug } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, hasDomain } = useUser();
  const Notify = useNotify();
  const [showForm, setShowForm] = useState(false);
  const [pending, setPending] = useState(true);

  // redux
  const landingPage = useLandingPage(landingPageSlug);

  useEffect(() => {
    if(!hasDomain('guest')) {
      Notify.default(userService.getDomainBasedGreeting(user));
      return userService.redirect(user,history);
    }
    dispatch(getLandingPage(landingPageSlug))
      .finally(() => setPending(false));
  },[landingPageSlug]); // eslint-disable-line

  if(pending) return <LoaderOverlay />;
  if(!landingPage) return <DefaultPageNotFound />;

  const { partner, building, coupon } = landingPage;

  const onComplete = ({ move_step_id, move_id, login_response }) => {
    Notify.default(userService.getDomainBasedGreeting(get(login_response,'user')));
    history.push((move_id && move_step_id) ? `/moves/${move_id}/steps/${move_step_id}` : '/');
  };

  const toggleDisplay = () => {
    history.push('/signin' + (building ? `/${get(building.slug)}` : ''));
  };

  return (
    <LayoutPartnerPromo partner={partner} splashBG={`${process.env.REACT_APP_S3_ASSET_URL}${get(landingPage,'side_column_image_url')}`}>

      <Helmet>
        <title>Welcome to { get(partner,'name') ? `${get(partner,'name')} :` : '' } Moved</title>
      </Helmet>

      { showForm ? (

        <>
          <div className={CSS.view_back} onClick={() => setShowForm(false)}>
            <Icon symbol={'Arrow-left'} library={'navigation'} color='gray' className={'mr-5'} />
            Back
          </div>

          <div className={CSS.view_toggle}>
            Already registered?
            <span className="faux-link ml-5" onClick={toggleDisplay}>Sign in</span>
          </div>

          <div className={CSS.wrapper}>
            <SignupForm
              building={building}
              partner={partner}
              coupon={coupon}
              onComplete={onComplete}
              title='Welcome!'
              subtitle='Let&apos;s get you moved.'
            />
          </div>
        </>

      ) : (

        <div className={CSS.wrapper}>
          <h1 className={CSS.title}>{get(landingPage,'title')}</h1>
          <ContentRenderer content={get(landingPage,'content')} className={CSS.subtitle} />
          { get(landingPage,'coupon') ? (
            <CouponTicket
              coupon={get(landingPage,'coupon')}
              title={format.address(get(landingPage,'building'),{ zipcode: false })}
              cta={get(landingPage,'cta_text','Get started')}
              onClick={() => setShowForm(true)}
            />
          ) : (
            <label
              role="button"
              className={CSS.button}
              tabIndex="0"
              onClick={() => setShowForm(true)}
            >
              { get(landingPage,'cta_text','Get started') }
              <Icon symbol={'Arrow-right'} library={'navigation'} size={'24px'} color={'white'} className={CSS.arrow} />
            </label>

          )}
          { get(landingPage,'footer_text') && (
            <div className={CSS.footer}>{ get(landingPage,'footer_text') }</div>
          )}
        </div>

      )}
    </LayoutPartnerPromo>
  );
};
