import React from 'react';
import { get } from 'lodash';

import { format } from '@moved/services';
import { Icon } from '@moved/ui';

import CSS from './styles/SummaryFields.module.scss';

export const SummaryFields = ({ taskDetails }) => {

  const selectedRate = get(taskDetails,'mover_booking.selected_partner_rate');
  const bookedServiceOrder = get(taskDetails,'mover_booking.booked_service_order');
  const crew_size = bookedServiceOrder ? get(bookedServiceOrder,'crew_size') : get(selectedRate,'crew_size');
  const type = bookedServiceOrder ? get(bookedServiceOrder,'rate_type') : get(selectedRate,'type');

  const fields = [
    {
      icon: { symbol: 'Date', library: 'code' },
      label: 'Move date',
      value: format.date(get(taskDetails,'mover_booking.requested_pickup_date'),'date'),
    },
    ...get(taskDetails,'mover_booking.stops',[]).map((stop, index, allStops) => {
      let label = `Stop ${index}`;
      if(index === 0) label = 'Origin';
      if(index === allStops.length-1) label = 'Destination';
      return {
        label,
        value: format.address(stop.address),
      }
    }),
    {
      icon: { symbol: 'Money', library: 'shopping' },
      label: 'Rate type',
      value: type === 'flat' ? "Flat rate" : "Hourly",
    },
    type === 'hourly' && {
      icon: { symbol: `Crew-${crew_size}`, library: 'people' },
      label: 'Crew size',
      value: `${crew_size} movers`,
    },
  ].filter(v => v && v.value);

  return fields.map(field => (
    <div className={CSS.info} key={field.label}>
      <div className={CSS.info_icon}>
        { field.icon ?
          (
            <Icon symbol={get(field,'icon.symbol')} library={get(field,'icon.library')} size={'24px'} />
          ) :
          (
            <span className={CSS.dot} />
          )
        }
      </div>
      <div className={CSS.row}>
        <span className={CSS.info_label}>{field.label}</span>
        <span className={CSS.info_value}>{field.value}</span>
      </div>
    </div>
  ));
}
