import { merge } from 'lodash';

import { coi as helpers } from '@moved/product';

import { BaseTask } from '../../components/BaseTask';

import * as actions from './actions';
import * as selectors from './actions/selectors';
import { screens } from './screens';

export class Coi extends BaseTask {
  constructor() {
    super({
      screens: screens,
      actions: actions,
      selectors: {
        useTaskable: selectors.useCoiSubmission,
        useTaskablePending: selectors.useCoiSubmissionPending,
        ...selectors,
      }
    });
  }

  static label = 'COI';
  static slug = 'coi';
  static icon = 'File';
  static lockedMessage = 'We can determine what COI details are required after you provide your move details.';
  static helpers = helpers;
  static config = {
    supportsArtifacts: true,
  };

  _flowBuilder(taskData) {
    return [
      this.screens['coi-submit'],
      this.screens['summary'],
    ];
  }

  getStatusCopy(request, taskDetails) {
    const defaultCopy = super.getStatusCopy(request);
    const requiresApproval = taskDetails?.settings?.requires_approval;
    let requestCopy = {};
    switch(request.status) {
      case 'rejected':
        requestCopy = {
          summary: {
            flavor: 'The Certificate of Insurance you submitted requires updates. Review the requested changes below and submit an updated COI.',
          },
        };
        break;
      case 'pending-approval':
        requestCopy = {
          summary: {
            flavor: 'Property Management has been notified of your submitted Certificate of Insurance. You will be notified when your submission is reviewed.',
          },
        };
        break;
      case 'approved':
        requestCopy = {
          summary: {
            flavor: (
              !taskDetails ? 'Your COI has been taken care of.' :
              requiresApproval ? 'Your uploaded COI has been reviewed and approved by property management.' :
              'Your uploaded COI has been submitted.'
            ),
          },
        };
        break;
      case 'override':
        requestCopy = {
          summary: {
            flavor: 'Your moving method does not require a Certificate of Insurance document',
          }
        };
        break;
      default:
        requestCopy = {};
    }
    return merge({},defaultCopy,requestCopy);
  }


  /* START OVERRIDES */
  /* END OVERRIDES */

  /* TASK SPECIFIC */
  /* END TASK SPECIFIC */

}
