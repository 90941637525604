import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom'
import classNames from 'classnames';

import { useNotify, format } from '@moved/services';
import { CompleteCard, FieldList } from '@moved/ui';

import { getMove } from '../../../../moves/actions';
import { Snippet } from '../../../../snippets';
import { ScreenTitle, CompleteScreenActions } from '../../shared';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';

export const ConfirmDiy = ({screen, task, origin, taskBaseRoute}) => {
  // hooks
  const { id, moveId } = useParams();
  const Notify = useNotify();
  const dispatch = useDispatch();
  const history = useHistory();
  const resetPending = task.selectors.useResetMoverBookingTaskPending();

  const _changeSelection = () => {
    // Revert moving vendor option
    dispatch(task.actions.reset(id))
      // refresh the move because reset affects MBT and MT on the move
      .then(r => { dispatch(getMove(moveId)); return r; })
      .then(resp => history.push(`${taskBaseRoute}${task.getStartOverRoute(resp)}`))
      .catch(err => Notify.error(format.error(err)));
  };

  const flavor = (<Snippet tag={'tasks.book-movers.summary.diy'}>Your property does not require any additional information.</Snippet>);

  const fields = [
    {
      label: 'Method',
      value: 'Moving myself',
      icon: { symbol: 'Moving-logistics', library: 'task' },
    },
    {
      value: (
        <div
          className={classNames('btn-gray btn--small',{'loading':resetPending})}
          disabled={resetPending}
          onClick={_changeSelection}
        >
          Change selection
        </div>
      )
    }
  ];

  return (
    <div className={taskCSS.holder}>

      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} />
        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>

            <CompleteCard status={'approved'} flavor={flavor}>
              <FieldList list={fields} />
            </CompleteCard>

          </div>
        </div>

        <CompleteScreenActions origin={origin} />

      </div>

    </div>
  )
};
