import { DynamicContentScreen } from './components';

export const screens = {

  content: {
    component: DynamicContentScreen,
    maxWidth: 800,
  },

};
