import { generate, request } from '@moved/services';

const creator = {
  namespace: 'PERSONAL_TASKS_CREATE_TASK_LIST',
  request: (moveId) => request.post(`/residents/moves/${moveId}/personal-task-lists`).then(r => r?.data?.data?.personal_task_list),
  reducers: {
    success: (state, { response: taskList, params: [moveId] }) => ({
      ...state,
      taskLists: {
        ...state.taskLists,
        [taskList.id]: taskList,
      },
      // In addition to storing the taskList, we update the move's reference to this
      // newly created task list to avoid a full refresh of the move
      moves: {
        ...state.moves,
        [moveId]: {
          ...state.moves[moveId],
          personal_task_list_id: taskList.id,
        },
      }
    }),
  },
};

export const {
  action: createTaskList,
  reducer,
  usePending: useTaskListPending,
} = generate.redux(creator);

export const initialState = {
  taskLists: {},
};
