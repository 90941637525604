import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from "react-router-dom";
import { get } from 'lodash';

import { useNotify, format } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';

import { useMove } from '../../moves/actions/selectors';
import { getPseudoTasks } from '../../marketplace/helpers/getPseudoTasks';
import { useActiveMoveStep } from '../../dashboard';
import { getDashboardRoute } from '../../dashboard/helpers';
import { taskDefinitions } from '../types';
import { getTaskSummary } from '../actions';
import { useTaskSummary } from '../actions/selectors';

const ActiveTask = createContext();

export const useActiveTask = () => useContext(ActiveTask);

export const TaskContextProvider = ({ children }) => {
  const { moveId, stepId, taskId } = useParams();
  const Notify = useNotify();
  const history = useHistory();
  const dispatch = useDispatch();
  const dashboardRoute = getDashboardRoute(moveId, stepId);
  const move = useMove(moveId);
  const moveStep = useActiveMoveStep();
  const [pending, setPending] = useState(true);

  const activeTask = moveStep ?
    get(moveStep,'move_tasks',[]).find(({id}) => id === parseInt(taskId)) :
    getPseudoTasks(move).find(({id}) => id === parseInt(taskId));

  const TaskType = activeTask && taskDefinitions[activeTask.task_type];
  const [activeTaskDefinition] = useState(TaskType && new TaskType());
  const activeTaskBaseRoute = activeTaskDefinition && `${dashboardRoute}${activeTaskDefinition.getBaseRoute(activeTask.id)}`;
  const activeTaskDetails = activeTaskDefinition && activeTaskDefinition.selectors.useTaskable(activeTask.task_details_id);
  const activeTaskSummary = useTaskSummary(taskId);

  useEffect(() => {
    if(!pending) setPending(true);
    if(!activeTaskDefinition) {
      Notify.error('This task is not currently available.');
      history.replace(dashboardRoute);
    }
    else {
      Promise.all([
        activeTaskDefinition.config.supportsArtifacts && dispatch(getTaskSummary(taskId)),
        activeTask.task_details_id && dispatch(activeTaskDefinition.actions.get(activeTask.task_details_id)),
      ])
        .then(() => setPending(false))
        .catch(err => {
          Notify.error(format.error(err));
          history.replace(dashboardRoute);
        });
    }
  },[taskId]); // eslint-disable-line

  if(!activeTask || !activeTaskDefinition) return null;

  if(pending) return <LoaderOverlay />;

  return (
    <ActiveTask.Provider value={{
      activeTask,
      activeTaskDefinition,
      activeTaskBaseRoute,
      activeTaskDetails,
      activeTaskSummary,
    }}>
      { children }
    </ActiveTask.Provider>
  );
};

export const withTask = (Wrapped) => (props) => (<TaskContextProvider><Wrapped {...props}/></TaskContextProvider>);
