import { merge, get } from 'lodash';

import { orientation as helpers } from '@moved/product';

import { BaseTask } from '../../components/BaseTask';

import * as actions from './actions';
import * as selectors from './actions/selectors';
import { screens } from './screens';
import { routes } from './routes';

export class Orientation extends BaseTask {
  constructor() {
    super({
      routes: routes,
      screens: screens,
      actions: actions,
      selectors: {
        useTaskable: selectors.useOrientation,
        useTaskablePending: selectors.useOrientationPending,
        ...selectors,
      }
    });
  }

  static label = 'Orientation';
  static slug = 'orientation';
  static icon = 'Book-open';
  static helpers = helpers;
  static config = {
    supportsArtifacts: true,
  };

  _flowBuilder(taskData) {
    return [
      ...get(taskData,'content',[]).map((screen, index) => {
        return merge({}, this.screens['orientation'], { task_id: taskData.id }, screen);
      }),
      this.screens['summary'],
    ];
  }

  getStatusCopy(request, taskDetails) {
    const defaultCopy = super.getStatusCopy(request);
    const requiresApproval = taskDetails?.settings?.requires_approval;
    let requestCopy = {};
    switch(request.status) {
      case 'rejected':
        requestCopy = {
          summary: {
            flavor: 'Review the requested changes below and submit again.',
          },
        };
        break;
      case 'pending-approval':
        requestCopy = {
          summary: {
            flavor: 'Property Management has been notified of your submission. You will be notified when it is reviewed.',
          },
        };
        break;
      case 'approved':
      case 'override':
        requestCopy = {
          summary: {
            flavor: (
              !taskDetails ?  'This task has been marked complete by property management.' :
              requiresApproval ? // FUTURE: this task does not expose this setting
                'Your submission has been reviewed and approved.' :
                'Your submission has been received.'
              )

          },
        };
        break;
      default:
        requestCopy = {};
    }
    return merge({},defaultCopy,requestCopy);
  }


  /* START OVERRIDES */
  getRecommendedScreen(taskable) {
    const activeRequest = this.helpers.getActiveRequest(taskable);

    if(activeRequest?.status) {
      // if request is not draft show confirmation screen
      if(activeRequest.status !== 'draft') return this._flow[this._flow.length-1];

      // else return chapter based on progress
      return this._flow.find(screen => {
        const { progress } = taskable;
        return !progress[screen.slug];
      });
    }

    return super.getRecommendedScreen(taskable);
  }
  /* END OVERRIDES */

}
