import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { format } from '@moved/services';

import { useOptionalControl } from '../useOptionalControl';
import { Text } from '../Text';

export const PhoneValidation = Yup.string().test(
  'length',
  'Must be a valid US phone number',
  (value='') => (!value || value.length === (value[0] === '1' ? 11 : 10))
);

const sanitizePhone = (value='') => {
  const digits = String(value).replace(/[^0-9]+/g, ''); // only numbers
  return digits.substring(0, (digits[0] === '1' ? 11 : 10)); // max length
};

export const Phone = ({
  type='tel',
  value,
  isControlled,
  name,
  onChange,
  ...props
}) => {
  const [phone, setPhone] = useOptionalControl(sanitizePhone(value),isControlled);

  const handleChange = ({ [name]:newValue }) => {
    const sanitizedValue = sanitizePhone(newValue);
    if(sanitizedValue === phone) return; // only trigger if changed
    setPhone(sanitizedValue);
    onChange?.({[name]:sanitizedValue});
  };

  return (
    <Text
      { ...props }
      name={name}
      value={format.phone(phone)}
      isControlled={true}
      onChange={handleChange}
      type='tel'
    />
  );
};

// Inherits from TEXT, not sure how to keep it in sync DRYly
Phone.propTypes = {
  /** Name to use for the form input */
  name: PropTypes.string.isRequired,
  /** Type to use for the form input */
  type: PropTypes.string,
  /** Text value to use for this input (only initial value if not controlled) */
  value: PropTypes.string,
  /** Flag to make the input a controlled input */
  isControlled: PropTypes.bool,
  /** Label text for the input */
  label: PropTypes.string,
  /** (optional) Second line of text */
  hint: PropTypes.string,
  /** (Optional) Icon to display in the input */
  icon: PropTypes.shape({
    symbol: PropTypes.string,
    library: PropTypes.string,
  }),
  /** (Optional) Icon position relative to text */
  iconPosition: PropTypes.oneOf(['left','right']),
  /** Flag to disable the input */
  disabled: PropTypes.bool,
  /** Flag to readonly the input */
  readOnly: PropTypes.bool,
  /** onChange handler function */
  onChange: PropTypes.func,
  /** class name to add to the input container */
  className: PropTypes.string,
  /** error message to display for this input */
  error: PropTypes.string,
};
