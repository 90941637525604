import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { generate } from '@moved/services';
import { get } from 'lodash';

import { CancellationDisclaimer } from '../../book-movers/components/CancellationDisclaimer';

const types = {
  1: {
    id: 1,
    content: [{
      "side_column_image_url": null,
      "slug": "billing",
      "cta_text": "Continue",
      "order": 1,
      "title": "Billing FAQ",
      "content": {
        "blocks": [
          {
            "data": {
              "icon": {
                "symbol": "Credit-card",
                "library": "stockholm"
              },
              "title": "How will I be charged?",
              "content": "To book your move, you must put a card on file. An authorization will be placed on this card 48 hours prior to the move, for the move total or the move minimum, depending on your quote. After the move is completed, the charge will be finalized by the moving company and will account for any coupons or discounts you may have. You will also receive an email receipt with a breakdown of the charge."
            },
            "type": "definition"
          },
          {
            "data": {
              "icon": {
                "symbol": "Settings",
                "library": "shopping"
              },
              "title": "How do I use my coupon?",
              "content": "If you were provided with a coupon, this amount will be applied at the completion of the move. If the coupon covers the move total, you will not be charged anything. If the coupon is less than the move total, you will be responsible for paying the difference."
            },
            "type": "definition"
          },
          {
            "data": {
              "icon": {
                "symbol": "Time-schedule",
                "library": "stockholm"
              },
              "title": "What if I need to cancel or reschedule?",
              "content": renderToStaticMarkup(<CancellationDisclaimer/>),
            },
            "type": "definition"
          },
          {
            "data": {
              "icon": {
                "symbol": "Calculator",
                "library": "shopping"
              },
              "title": "How is the move time calculated for hourly rate moves?",
              "content": "<b>Arrival fee.</b> This is a flat fee determined by the movers to allow time to travel to the pickup address. Any tolls or parking fees required to complete the move will be included as additional charges in the final price.<br><br><b>Labor time.</b> The clock starts on labor time once the movers arrive at the pickup address and ends once they are done unloading at the drop-off address, rounded up to the nearest half hour. Please note that the travel between pickup and the destination is part of labor time. Any delays caused by elevators, changes to the list of items to be moved, building restrictions, traffic, or anything else will be included in the total time of the move."
            },
            "type": "definition"
          },
          {
            "data": {
              "icon": {
                "symbol": "File",
                "library": "files"
              },
              "title": "Can the movers provide a Certificate of Insurance (COI)?",
              "content": "Absolutely! Once you’ve booked your move, you will be introduced directly to the moving company and they can get this processed for you. Note: there will be a $30 charge on top of your move total if a COI is required."
            },
            "type": "definition"
          }
        ]
      },
      "label": "Billing",
      "subtitle": null,
      "body": null
    }],
  },
  2: {
    id: 2,
    content: [{
      "side_column_image_url": null,
      "slug": "faq",
      "cta_text": "Continue",
      "order": 1,
      "title": "Moving FAQ, Tips, & Tricks",
      "content": {
        "blocks": [
          {
            "data": {
              "icon": {
                "symbol": "Clock",
                "library": "stockholm"
              },
              "title": "How long will the move take?",
              "content": "The amount of time your move takes depends on a few factors - such as how prepared you are, how much needs to be moved, if you have any special items (e.g. a piano), the distance between your front door and the moving truck, and others. Move times will vary, but assuming you’re prepared and ready on move day these are the averages based on home size:<br><b><br>Studio</b> - 2-4 hours<br><b>1 bedroom</b>&nbsp;- 4-7 hours<br><b>2 bedroom</b> - 5-8 hours<br><b>3+ bedroom</b> - 7-10 hours or more<br><br>Please keep in mind that these are averages and your individual move may vary. After booking, you’ll receive an email introducing you to the moving company where you can ask specific questions about your move.<br>"
            },
            "type": "definition"
          },
          {
            "data": {
              "icon": {
                "symbol": "Route",
                "library": "navigation"
              },
              "title": "How do arrival times work?",
              "content": "Your time preference is shared with the moving company, who will provide you with a specific arrival window. For example, if the provided arrival window is 8AM - 11AM, the movers can arrive <em>as early</em> as 8AM (please be ready for them) or <em>as late</em> as 11AM (make sure to account for enough time to complete the move according to any reservations, traffic, etc.)",
            },
            "type": "definition"
          },
          {
            "data": {
              "icon": {
                "symbol": "Shield-check",
                "library": "stockholm"
              },
              "title": "Is my move insured?",
              "content": "Your move is covered at a standard valuation of $.30/lb, with the exception of contents in boxes that were packed by me and any item(s) containing particle board, engineered wood, press board or press wood items (commonly found in IKEA, West Elm, Pottery Barn, etc. furniture). If you’d like additional insurance, you can acquire it by <a href=\"https://go.moved.com/insurance\">submitting a quote request</a>."
            },
            "type": "definition"
          },
          {
            "data": {
              "icon": {
                "symbol": "Shield-user",
                "library": "stockholm"
              },
              "title": "What is additional moving insurance?",
              "content": "Additional moving insurance can be purchased to cover the cost of replacing your belongings if they are damaged during the move. To determine if you need to purchase it, <a href=\"https://moved.com/2018/05/03/2018-05-03-do-you-need-moving-insurance/\">this blog post</a> help may be helpful. We recommend getting additional moving insurance for any high-value items. You can obtain this extra coverage by <a href=\"https://go.moved.com/insurance\">submitting a quote request</a>."
            },
            "type": "definition"
          },
          {
            "data": {
              "icon": {
                "symbol": "File",
                "library": "stockholm"
              },
              "title": "Can the movers provide a Certificate of Insurance (COI)?",
              "content": "Absolutely! Once you’ve booked your move, you will be introduced directly to the moving company and they can get this processed for you. Note: there will be a $30 charge on top of your move total if a COI is required."
            },
            "type": "definition"
          },
          {
            "data": {
              "icon": {
                "symbol": "Box#2",
                "library": "stockholm"
              },
              "title": "Will the movers provide packing materials?",
              "content": "The movers will automatically protect all large furniture and mattresses with the use of furniture blankets and pads. This is free of charge. If you’d like to keep these materials or purchase additional materials, you can do so directly through the moving company. You should let the moving company know ahead of time if you plan to do so."
            },
            "type": "definition"
          },
          {
            "data": {
              "icon": {
                "symbol": "Box#3",
                "library": "stockholm"
              },
              "title": "What if I need help packing?",
              "content": "The movers can help pack your things, if you’d like. If you’re booking an hourly rate, this will extend the time it takes to finish the move. For flat rates, this will need to be quoted separately. After booking your move, you’ll be introduced to the moving company directly. If you haven’t already indicated it, make sure you let them know you’d like packing services as soon as possible."
            },
            "type": "definition"
          },
          {
            "data": {
              "icon": {
                "symbol": "Screwdriver",
                "library": "stockholm"
              },
              "title": "Will the movers disassemble and reassemble my items?",
              "content": "Yes! Standard disassembly of basic furniture (i.e. basic metal bed frames) is included. Disassembly/reassembly of more complicated furniture items such as cribs, bunk beds, or murphy beds may be subject to an additional fee. After booking you'll be introduced to the moving company and you should let them know if you have any such items - e.g. murphy beds, bunk beds, IKEA closets, cribs, wardrobes, custom shelving, chandeliers, pianos, and any other complex, time-consuming items."
            },
            "type": "definition"
          },
          {
            "data": {
              "icon": {
                "symbol": "Trash",
                "library": "stockholm"
              },
              "title": "Can the movers help with junk removal?",
              "content": "Yes, they can. After booking your move, you’ll be introduced to the moving company directly. You should let them know what items you’d like to be disposed of before moving day."
            },
            "type": "definition"
          }
        ]
      },
      "label": "Tips & Tricks",
      "subtitle": null,
      "body": null
    }],
  },
}

const creator = {
  namespace: 'TASKS_GET_FAQ_DIRECT',
  request: (id) => new Promise((resolve) => resolve(types[id])),
  selector: (state, id) => get(state, `faqs.${id}`),
};

export const {
  action,
  reducer,
  useResource: useFaq,
  usePending: useFaqPending,
} = generate.redux(creator);

export const initialState = {
  faqs: {
    ...types,
  },
};
