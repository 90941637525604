import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';

import { useUser, useNotify, userService } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';
import { LayoutBuildingPromo } from '@moved/product';

import { useBuilding } from '../../common/actions/selectors';
import { ClaimAccountForm } from './ClaimAccountForm';
import { ClaimAccountSummary } from './ClaimAccountSummary';
import { ClaimAccountInvalid } from './ClaimAccountInvalid';
import { getClaimCode } from '../actions';

import CSS from '../styles/ClaimAccount.module.scss';

export const ClaimAccount = () => {
  // hooks
  const { code, buildingSlug } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, isAuthenticated, logout } = useUser();
  const Notify = useNotify();
  const building = useBuilding(buildingSlug);
  const [claim, setClaim] = useState(false);
  const [pending, setPending] = useState(true);

  const title = claim.move_step_type === 'move-in' ? 'Welcome!' : 'Sorry to see you go!';
  const subtitle = claim.move_step_type === 'move-in' ? `Let's get you moved in.` : 'We have a few final tasks for you to take care of.';

  useEffect(() => {
    dispatch(getClaimCode(code))
      .then((claimData) => {
        if(claimData.building_slug !== buildingSlug) history.replace(`/signup/${claimData.building_slug}/${code}`);
        setClaim(claimData);
        setPending(false);
      })
      .catch((error) => {
        // special handler to redirect to signin screen if this token was already claimed
        if(get(error,'response.data.errors',[]).find(error => error.code === 'tenant-event-already-claimed')) {
          return history.replace(`/signin/${buildingSlug}`);
        }
        Notify.error('This link is no longer valid.');
        setPending(false);
      });
  },[code]); // eslint-disable-line

  const onComplete = ({ move_step_id, move_id, login_response }) => {
    Notify.default(userService.getDomainBasedGreeting(get(login_response,'user')));
    if(move_id && move_step_id) history.push(`/moves/${move_id}/steps/${move_step_id}`);
    else userService.redirect(get(login_response,'user'),history);
  };

  if(pending || !building) return <LoaderOverlay />;

  return (
    <LayoutBuildingPromo building={building}>
      <Helmet>
        <title>Welcome to { building ? `${get(building, 'settings.display_name', '')} :` : '' } Moved</title>
      </Helmet>

      { isAuthenticated && (
        <div className={CSS.view_toggle}>
          <span className="mr-10">{ user.fullname }</span>
          <span className="faux-link" onClick={() => logout()}>
            Sign out
          </span>
        </div>
      )}

      <div className={CSS.form_wrapper}>
        { (!pending && !claim) ? (
          <ClaimAccountInvalid
            title='Oops'
            subtitle='This link is not valid. Please double check the url, or reach out to your building for a new invitation link.'
            building={building}
          />
        ) : isAuthenticated ? (
          <ClaimAccountSummary
            title={title}
            subtitle={subtitle}
            code={code}
            claim={claim}
            onComplete={onComplete}
          />
        ) : (
          <ClaimAccountForm
            title={title}
            subtitle={subtitle}
            code={code}
            claim={claim}
            onComplete={onComplete}
          />
        )}
      </div>

    </LayoutBuildingPromo>
  );
};
