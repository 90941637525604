import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom'
import { get } from 'lodash';

import { useModal, useNotify } from '@moved/services';
import { ConfirmationModal, Button } from '@moved/ui';

import { getMove } from '../../../../moves/actions';
import { SummaryScreen } from '../../shared/components/SummaryScreen';

import { KeyPickupInstructionsLink } from './KeyPickupInstructionsLink';

export const KeyPickupSummary = ({ screen, taskDefinition, taskSummary, taskDetails, taskBaseRoute, origin }) => {
  // hooks
  const { taskId, moveId } = useParams();
  const history = useHistory();
  const Modal = useModal();
  const Notify = useNotify();
  const dispatch = useDispatch();

  // redux
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const building = taskDefinition.selectors.useBuilding(get(activeMoveStep,'building.slug'));

  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);
  const artifacts = taskDefinition.helpers.getArtifacts(taskSummary) || [];
  const activeAppointment = artifacts[0];
  const pickupInstructions = get(activeRequest || activeAppointment, 'building_calendar.instructions');

  const isAppointmentMine = (
    (activeRequest && taskDetails?.responsibility === 'self') ||
    (activeAppointment && activeAppointment?.tenant?.id === activeMoveStep?.tenant?.id)
  );

  const rescheduleReservation = () => {
    const resetTaskPromise = taskDetails?.id ? Promise.resolve(taskDetails) :
      dispatch(taskDefinition.actions.create(taskId))
    return resetTaskPromise
      .then(newTaskDetails => dispatch(taskDefinition.actions.createRequest(newTaskDetails.id)))
      // refresh the move because reset affects MT on the move
      .then(r => { dispatch(getMove(moveId)); return r; })
      .then((resp) => history.push(`${taskBaseRoute}${taskDefinition.getStartOverRoute(resp)}`))
      .catch(error => Notify.error( get(error, 'response.data.message') ));
  };

  const handleReschedule = () => {
    return Modal.open(
      <ConfirmationModal
        title={'Edit appointment'}
        copy={(
          !activeAppointment ? 'Your existing request will be canceled.' :
          isAppointmentMine ? 'Note: Your existing appointment will be held for you while you edit. It will be canceled and replaced with a new appointment, only once the new appointment has been submitted and confirmed by the property.' :
            `Note: This will not replace or change your roommate's appointment, but will create a separate appointment for yourself.`
        )}
        confirmText={'Proceed with editing'}
        cancelText={'Never mind'}
        onConfirm={rescheduleReservation}
      />,
      { sondheim: true },
    );
  };

  const extraAttributes = [
    (get(pickupInstructions,'blocks',[]).length > 0) && {
      label: 'Pick up instructions',
      value: <KeyPickupInstructionsLink instructions={pickupInstructions} />,
      icon: { symbol: 'Info-circle', library: 'code' },
    },
    get(building,'settings.key_pickup_appointment_rescheduling') && {
      value: (
        <Button
          size='small'
          color='secondary'
          onClick={handleReschedule}
          text={isAppointmentMine ? 'Request a different time' : 'Remove me and start over'}
          disabled={taskDefinition.selectors.useCreateRequestPending()}
        />
      )
    }
  ].filter(Boolean);

  return (
    <SummaryScreen
      origin={origin}
      screen={screen}
      extraAttributes={extraAttributes}
    />
  );

};
