import { CableInternetProviderServices, Providers, Complete } from './components';

export const screens = {

  'providers': {
    slug: 'providers',
    label: 'Providers',
    title: 'Choose your provider',
    maxWidth: '828px',
    component: Providers,
    isCompleted: (taskable) => taskable?.submitted_at,
  },

  'providers-v2': {
    slug: 'providers',
    label: 'Select service',
    title: 'Choose a new plan',
    subtitle: 'Tailor your internet experience by choosing from a range of provider plans below.',
    maxWidth: '1016px',
    component: CableInternetProviderServices,
    isCompleted: (taskable) => taskable?.submitted_at,
  },

  'complete': {
    slug: 'complete',
    label: "Summary",
    title: 'Cable and Internet',
    component: Complete,
    maxWidth: 600,
  },

};
