import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { useDrawer } from '@moved/services';
import { Button, Card } from '@moved/ui';

import CSS from './BookMoversLink.module.scss';

export const BookMoversLink = ({ taskDetails, move }) => {
  const Drawer = useDrawer();
  const history = useHistory();

  const linkToResidentBookMovers = () => {
    // need to search the move's moveSteps for a moveTask that matches the taskType of the current task
    // store the matching moveStep and the matching moveTask to use below in the url for the link
    let residentBookMoversTask;
    const residentMoveStep = move?.move_steps?.find(moveStep => moveStep?.move_tasks?.find(moveTask => {
      if(moveTask.task_type === taskDetails.task_type) {
        residentBookMoversTask = moveTask;
        return true;
      }
      else return false;
    }));
    history.push(`/moves/${move.id}/steps/${residentMoveStep.id}/tasks/${residentBookMoversTask.id}`);
    Drawer.close();
  };

  return (
    <div className={classNames(CSS.adWrapper,'stackHorizontal gap-24 stackVertical--mobile')}>
      <div className='stackVertical gap-4 padding-8'>
        <div className='stackHorizontal gap-12'>
          <span className='headingS contentPrimary'>
            Did you know?
          </span>
        </div>
        <div className='labelM contentSecondary'>
          Moved has a curated network of professional moving companies with exclusive rates available for residents like you.
        </div>
      </div>
      <Card className={classNames(CSS.quotesBlock,'stackVertical gap-16 justify-center')}>
        <div className='labelM contentSecondary'>
          Answer a few questions and get instant quotes.
        </div>
        <Button
          text='Get quotes'
          onClick={linkToResidentBookMovers}
        />
      </Card>
    </div>
  );
}
