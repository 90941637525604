import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_BOOK_MOVER_BOOKING',
  request: (taskId, bookingId, data) => request.post(`/mover-bookings/${bookingId}/book`, data).then(r => get(r,'data.data.mover_booking')),
  reducers: {
    success: (state, { response: booking, params: [taskId] }) => ({
      ...state,
      moverBookingTasks: {
        ...state.moverBookingTasks,
        [taskId]: {
          ...get(state,`moverBookingTasks.${taskId}`,{}),
          mover_booking: booking,
        },
      },
    }),
  }
}

export const {
  action,
  reducer,
  usePending: useBookMoverBookingPending,
} = generate.redux(creator);
