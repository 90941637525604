import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import classNames from 'classnames';
import { get, merge } from 'lodash';

import { useNotify, format } from '@moved/services';

import { ResidentContentRenderer } from '../../../../common';
import { ScreenTitle } from '../../shared';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/AdContent.module.scss';

export const AdContent = ({ screen, changeScreen, task }) => {
  // hooks
  const { id } = useParams();
  const Notify = useNotify();
  const dispatch = useDispatch();
  const [selectPending, setSelectPending] = useState();
  const [declinePending, setDeclinePending] = useState();
  const pending = selectPending || declinePending;

  const providerAd = get(screen,'ad');

  const selectProvider = () => {
    if(pending) return;
    setSelectPending(true);
    return dispatch(task.actions.update(id, {[`selected_${screen.type}_provider_ad_id`]: providerAd.id}))
      .then(r => dispatch(task.actions.complete(id)))
      .then(r => changeScreen('next',r))
      .catch(err => {
        setSelectPending(false);
        Notify.error(format.error(err));
      });
  };

  const declineProvider = () => {
    if(pending) return;
    setDeclinePending(true);
    return dispatch(task.actions.complete(id))
      .then(r => changeScreen('next',r))
      .catch(err => {
        setDeclinePending(false);
        Notify.error(format.error(err));
      });
  };

  return (
    <div className={taskCSS.holder}>
      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} />
        <div className={taskCSS.content}>
          <div className={CSS.sizer}>
            <div className={CSS.container}>
              <ResidentContentRenderer content={merge({},{id:screen.id},screen.content)} />
            </div>
            {screen.side_column_image_url && (
              <div className={CSS.side_column} style={{ backgroundImage: `url(${screen.side_column_image_url})`}} />
            )}
          </div>
        </div>
        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_choice}>
            <button
              className={classNames('btn-primary btn--full', { loading: selectPending })}
              onClick={selectProvider}
              disabled={pending}
            >I'm using {providerAd.provider_name}</button>
            <button
              className={classNames('btn-gray btn--full',{ loading: declinePending })}
              onClick={declineProvider}
              disabled={pending}
            >
              No thanks
            </button>
          </div>
        </div>
      </div>
    </div>
  );

};
