import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { get } from 'lodash';

import { format } from '@moved/services';
import { Icon } from '@moved/ui';
import { MoveStepTypeIcon } from '@moved/product';

import { getDashboardRoute } from '../../dashboard/helpers';

import CSS from './styles/MoveSummary.module.scss';

// helper for fallback display values
const buildDefaultBlock = (move) => ({
  id: 'default',
  moveId: get(move,'id'),
  thumbnail: `${process.env.REACT_APP_S3_ASSET_URL}/partners/default/splash-landscape.jpg`,
  date: `Started on ${format.date(get(move,'created_at'),'MMMM D, YYYY')}`,
  name: 'My future move',
  address: 'Location: TBD',
});

// helper for move step based display values
const buildMoveStepBlock = (moveStep, move) => ({
  id: get(moveStep,'id'),
  moveId: get(move,'id'),
  stepId: get(moveStep,'id'),
  thumbnail: get(moveStep,'thumbnail_url'),
  type: get(moveStep,'type'),
  date: format.date(get(moveStep,'date'),'MMMM D, YYYY'),
  name: get(moveStep,'building_name'),
  address: format.address(get(moveStep,'address')),
});

// helper for mover booking based display values
// supports partial completion overrides
const buildMoverBookingBlock = (moverBooking, move) => ({
  ...buildDefaultBlock(move),
  id: get(moverBooking,'id'),
  ...(get(moverBooking,'requested_pickup_date') && {
    type: 'move-out',
    date: format.date(get(moverBooking,'requested_pickup_date')),
    name: `${format.date(get(moverBooking,'requested_pickup_date'),'MMMM YYYY')} Move`
  }),
  ...(get(moverBooking,'stops[0].address') && {
    address: format.address(get(moverBooking,'stops[0].address')),
  }),
});

export const MoveSummary = ({ move }) => {
  const summaryBlocks = get(move,'move_step_summaries.length') > 0
    ? get(move,'move_step_summaries',[]).map(step => buildMoveStepBlock(step, move))
    : get(move,'mover_bookings.length') > 0
      ? get(move,'mover_bookings',[]).map(booking => buildMoverBookingBlock(booking, move))
      : [buildDefaultBlock(move)];
  return (
    <div className={CSS.container}>
      <div className={classNames(CSS.move_steps_holder)}>
        { summaryBlocks.map(block => (
          <MoveBlockSummary key={block.id} {...block}/>
        ))}
      </div>
    </div>
  );
};

const MoveBlockSummary = ({ moveId, stepId, thumbnail, type, date, name, address }) => (
  <Link
    to={getDashboardRoute(moveId,stepId)}
    className={CSS.step_link}
  >
    { thumbnail && (
      <div className={CSS.thumbnail} style={{
        backgroundImage: `url('${thumbnail}')`
      }} />
    )}
    <div className={CSS.column}>
      <div className={CSS.move_step_label}>
        { type && (<MoveStepTypeIcon type={type} size={'16px'} className={CSS.icon}/>)}
        { date }
      </div>
      <h2 className={CSS.move_step_heading}>{ name }</h2>
      <div className={CSS.move_step_label}>{ address }</div>
    </div>
    <div className={classNames(CSS.column,CSS.action)}>
      <div className={'btn-primary'} style={{width:'32px',height:'32px', padding: 0}}>
        <Icon symbol='Arrow-right' library='navigation' color='white' size='20px'/>
      </div>
    </div>
  </Link>
);
