import { LandingPage } from './components/LandingPage';
import { ApiLandingPage } from './components/ApiLandingPage';

const routes = [
  {
    path: '/via/:landingPageSlug',
    name: 'Landing Page',
    component: LandingPage,
    allowGuest: true,
    exact: true,
  },
  {
    path: '/via/:landingPageSlug/:token([0-9a-fA-F]{48})',
    name: 'API Token Landing Page',
    component: ApiLandingPage,
    allowGuest: true,
    exact: true,
  },
];

export default routes;
