import { get, isNil } from 'lodash';
import * as Yup from 'yup';

import {
  LongDistance,
  QuoteFAQs, // subcomponent
} from './components';

import { screens as sharedScreens } from '../book-movers/screens';

export const screens = {

  'inventory': {
    slug: 'inventory',
    label: 'Inventory',
    title: 'Provide a list of your inventory',
    subtitle: 'Please include an estimated number of boxes as well as items such as furniture, appliances, bicycles, etc.',
    noteBox: {
      title: 'Help us understand the size and scope of your move.',
      copy: 'A more thorough inventory list leads to more accurate quotes and a smoother move day experience.',
      icon: {
        symbol: 'Info',
      },
    },
    maxWidth: 800,
    isCompleted: (data) => !isNil(get(data,'mover_booking.inventory_text')),
    formStyle: 'stacked',
    fields:  (taskable = {}) => [{
      type: 'hidden',
      name: 'mover_booking.id',
      value: get(taskable,'mover_booking.id'),
    },
    {
      label: 'Inventory list',
      type: 'textarea',
      name: 'mover_booking.inventory_text',
      minRows: 5,
      required: true,
      placeholder: 'Enter an estimated box count and an itemized list of your larger items',
      value: get(taskable,'mover_booking.inventory_text'),
    }],
    validation: Yup.object({
      mover_booking: Yup.object({
        inventory_text: Yup.string()
          .min(20, 'Must be at least 20 characters'),
      }),
    }),
  },

  'moving-quotes': {
    ...sharedScreens['moving-quotes'],
    faq: QuoteFAQs,
    showOptOut: false,
  },

  'long-distance': {
    slug: 'long-distance',
    component: LongDistance,
    label: "Long distance",
    title: 'It looks like you are moving long distance!',
    isCompleted: (taskable) => get(taskable,'mover_booking.contact_email') &&
      get(taskable,'mover_booking.contact_phone'),
    maxWidth: 1060,
  },

};
