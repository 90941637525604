import { merge } from 'lodash';

import * as create from './create';
import * as get from './get';
import * as update from './update';

export const reducers = [
  create.reducer,
  get.reducer,
  update.reducer,
];

export const initialState = merge(
  create.initialState,
  get.initialState,
);
