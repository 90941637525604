import { get } from 'lodash';

import { BookMovers } from '../book-movers/definition';

import * as actions from './actions';
import { screens } from './screens';

export class BookMoversDirect extends BookMovers {
  constructor() {
    super();
    // extend and override screens
    this.screens = {
      ...this.screens,
      ...screens,
    };
    // extend and override actions
    this.actions = {
      ...this.actions,
      ...actions,
    };
  }

  static slug = 'hire-movers';

  _flowBuilder(taskData) {
    // unknown geography
    if(get(taskData,'mover_booking.stops',[]).length < 2) return [
      this.screens['date'],
      this.screens['time-preference'],
      this.screens['address'],
      this.screens['mystery'],
      this.screens['confirmation'],
    ];
    // full long distance flow
    if(get(taskData,'mover_booking.long_distance')) return [
      this.screens['date'],
      this.screens['time-preference'],
      this.screens['address'],
      this.screens['long-distance'],
      this.isBooked(taskData) ? this.screens['confirmation-book-movers'] : this.screens['confirmation-long-distance'],
    ];
    // full non-core-geo flow
    if(get(taskData,'mover_booking.eligible_for_rates') === false) return [
      this.screens['date'],
      this.screens['time-preference'],
      this.screens['address'],
      this.screens['confirmation-non-core-geo'],
    ];
    // full local move flow
    return [
      this.screens['date'],
      this.screens['time-preference'],
      this.screens['address'],
      this.screens['move-size'],
      this.screens['special'],
      this.screens['inventory'],
      this.screens['moving-quotes'],
      this.screens['moving-summary'],
      this.screens['agreements'],
      this.screens['moving-payment'],
      this.screens['confirmation-book-movers'],
    ];
  }

  /* START OVERRIDES */
  static getMoverBookingStatus(moverBooking) {
    if(!moverBooking) return 'not-started';
    if(
      get(moverBooking,'booked_transaction') || // complete and booked
      (get(moverBooking,'stops',[]).length > 1 && !get(moverBooking,'eligible_for_rates')) || // complete and outside geography
      (get(moverBooking,'stops',[]).length > 1 && get(moverBooking,'long_distance') && get(moverBooking,'contact_email')) // complete and long distance
    ) return 'complete';
    else return 'in-progress';
  };
  /* END OVERRIDES */

  /* TASK SPECIFIC */
  /* END TASK SPECIFIC */

}
