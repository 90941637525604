import { get } from 'lodash';

import { SelectDateScreen } from './components/SelectDateScreen';
import { Summary } from './components/Summary';

export const screens = {

  'reserve-time': {
    slug: 'reserve-time',
    component: SelectDateScreen,
    label: "Reserve time",
    title: 'Reserve move date & time of your move',
    subtitle: 'Reserve a loading area or elevator for your moving date.',
    maxWidth: 804,
    isCompleted: (taskable, task) => {
      const activeRequest = task.helpers.getActiveRequest(taskable);
      return get(taskable, 'forgo') ||
      (
        activeRequest ?
        get(activeRequest,'status') !== 'draft' :
        get(taskable,'reservation.status') === 'approved'
      )
    },
  },

  'summary': {
    slug: 'summary',
    component: Summary,
    label: "Summary",
    backRestricted: true,
  },

};
