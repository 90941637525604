import React, { createRef } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { get, findIndex } from 'lodash';

import { OptionalLink } from '@moved/ui';

import { useActiveTask } from '../contexts/TaskContext';

import CSS from './styles/TrainDesktop.module.scss';

export const TrainDesktop = ({ flow, activeScreenIndex=0, nestedIndex=0 }) => {

  const { id } = useParams();
  const { activeTaskDefinition, activeTaskBaseRoute } = useActiveTask();
  const taskDetails = activeTaskDefinition.selectors.useTaskable(id);
  const lineRef = createRef();

  const _renderSteps = (steps) => {

    let unknown = false;

    return steps.map((step, index) => {

      if(step.unknown) {
        unknown = true;
        return (
          <span key={step} className={CSS.dotted} />
        );
      }

      let link = null;
      if(index <= nestedIndex) {
        if(step.isCategory) {
          const subScreen = step.screens[0];
          if(activeTaskDefinition.canAccessScreen(taskDetails, subScreen.slug, subScreen.context)) {
            link = `${activeTaskBaseRoute}${activeTaskDefinition.getScreenRoute(step.screens[0],taskDetails)}`;
          }
        } else {
          if(activeTaskDefinition.canAccessScreen(taskDetails, step.slug, step.context)) {
            link = `${activeTaskBaseRoute}${activeTaskDefinition.getScreenRoute(step,taskDetails)}`;
          }
        }
      }

      const insideCategory = get(activeTaskDefinition.flowNested[nestedIndex],'key') === step.key;

      return step.isCategory
        ? (
          <React.Fragment key={step.key || step.label}>
            <OptionalLink to={link} className={classNames(CSS.step, { [CSS.active]: index <= nestedIndex })}>
              <span className={CSS.number}>{index + 1}</span>
              <span className={CSS.text}>{step.label}</span>
            </OptionalLink>
            {insideCategory && step.screens.map(screen => {
              const subStepIndex = findIndex(flow, item => item.slug === screen.slug && (!item.context || item.context === screen.context));
              if(subStepIndex <= activeScreenIndex && activeTaskDefinition.canAccessScreen(taskDetails, screen.slug, screen.context)) {
                link = `${activeTaskBaseRoute}${activeTaskDefinition.getScreenRoute(screen,taskDetails)}`;
              }

              return (
                <OptionalLink key={screen.key || screen.slug} to={link} className={classNames(CSS.subStep, { [CSS.subStepActive]: subStepIndex <= activeScreenIndex })}>
                  <span className={CSS.text}>{screen.label}</span>
                </OptionalLink>
              );
            })}
          </React.Fragment>
        )
        : (
          <OptionalLink key={step.key || step.slug} to={link} className={classNames(CSS.step, { [CSS.active]: index <= nestedIndex })}>
            <span className={CSS.number}>{unknown ? '?' : index + 1}</span>
            <span className={CSS.text}>{step.label}</span>
          </OptionalLink>
        );
    });
  };

  const _renderCircles = (steps) => {
    return steps.map((step, index) => {

      if(step.unknown) {
        return (
          <span key={step} className={CSS.unknown} />
        );
      }

      const insideCategory = get(activeTaskDefinition.flowNested[nestedIndex],'key') === step.key;
      return step.isCategory
        ? (
          <React.Fragment key={step.key || step.label}>
            <span className={classNames(CSS.circle,  { [CSS.active_circle]: index >= nestedIndex })} />
            {insideCategory && step.screens.map(screen => (
              <span key={screen.key || screen.slug} className={CSS.subCircle} />
            ))}
          </React.Fragment>
        )
        : (
        <span key={step.key || step.slug} className={classNames(CSS.circle,  { [CSS.active_circle]: index >= nestedIndex })} />
      );
    });
  };

  // don't attempt to render the train until the details are loaded
  if(!taskDetails) return null;

  const getLineHeight = () => {

    const nestedStep = activeTaskDefinition.flowNested[nestedIndex] || {};
    const screen = flow[activeScreenIndex];

    const categories = nestedStep.isCategory ? findIndex(nestedStep.screens, item => item.slug === get(screen,'slug')) : 0;

    return (
      ( (nestedIndex + 1) * 76 )
      // active subSteps
      + ((categories) * 36)
      //normalizer
      - 16
    );
  }

  return (
    <div className={CSS.train}>

      <div className={CSS.station}>
        <div className={CSS.steps}>
          {_renderSteps(activeTaskDefinition.flowNested)}

          <div className={CSS.lines} style={{ height: getLineHeight() }} ref={lineRef}>
            {_renderCircles(activeTaskDefinition.flowNested)}
          </div>
        </div>
      </div>


    </div>
  );
};
