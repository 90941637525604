import { withContactForm } from '../contact';
import { TaskContextProvider } from './contexts/TaskContext';
import { TaskFlow } from './components/TaskFlow';
import { TaskRedirect } from './components/TaskRedirect';
import { TaskSummary } from './components/TaskSummary';
import { LegacyRouteRedirect } from './components/LegacyRouteRedirect';

import { taskDefinitions } from './types';

const taskTypes = Object.keys(taskDefinitions).map(type => (new taskDefinitions[type]()));

const routes = [
  // TEMPORARY FORWARDING (for emails in the wild before route changes)
  ...taskTypes.map(({slug}) => ({
    path: `/tasks/:taskType(${slug})/:taskDetailsId(\\d+)?`,
    name: 'taskType',
    viewKey: ({taskType}) => `task-${taskType}`,
    component: LegacyRouteRedirect,
  })),
  {
    path: `/tasks/:taskId(\\d+)`,
    name: 'moveTask',
    viewKey: ({taskId}) => `task-${taskId}`,
    transition: 'scale',
    component: withContactForm(TaskContextProvider),
    children: [
      {
        path: `/`,
        name: 'TaskRedirect',
        component: TaskRedirect,
        exact: true,
      },
      ...taskTypes.map(({slug, screens, routes=[]}) => ({
        path: `/:taskType(${slug})`,
        name: 'taskType',
        viewKey: ({taskType}) => `task-${taskType}`,
        children: [
          {
            path: `/`,
            name: 'TaskRedirect',
            component: TaskRedirect,
            exact: true,
          },
          {
            path: `/summary`,
            name: 'TaskSummary',
            component: TaskSummary,
            exact: true,
          },
          {
            path: `/:id(\\d+)`,
            name: 'taskDetails',
            viewKey: ({taskType,id}) => `task-${taskType}-${id}`,
            children: [
              {
                path: `/`,
                name: 'taskFlowRedirect',
                component: TaskFlow,
                viewKey: ({taskType,id}) => `task-${taskType}-${id}`,
                exact: true,
              },
              // extend with custom routes per task type (defaults to [])
              ...routes,
              // add the default screen routes pre-powered by the static screens for each task type
              {
                path: `/:screenSlug(${Object.keys(screens).map(screen => screens[screen].slug).filter(v=>v).join('|')})`,
                name: 'taskFlowScreen',
                component: TaskFlow,
                viewKey: ({taskType,id}) => `task-${taskType}-${id}`,
                exact: true,
              },
            ]
          },
        ]
      }))
    ],
  },

];

export default routes;
