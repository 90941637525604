import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import classNames from 'classnames';
import { useFormikContext } from 'formik';

import { DynamicForm, Notebox, Button } from '@moved/ui';
import { useNotify, format } from '@moved/services';

import { ScreenTitle } from '../../shared';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';

const EpremiumListener = () => {
  const { setFieldValue, submitForm } = useFormikContext();
  useEffect(() => {
    const ePremiumListenerHandler = (event) => {
      if(event.origin.includes('epremiuminsurance.com')) {
        setFieldValue('policy_number', event.data);
        submitForm();
      }
    };
    window.addEventListener('message', ePremiumListenerHandler);
    return () => window.removeEventListener('message', ePremiumListenerHandler);
  }, [setFieldValue, submitForm]);
  return null;
};

export const VerifyEpremium = ({ screen, changeScreen, task }) => {
  // hooks
  const { id } = useParams();
  const Notify = useNotify();
  const dispatch = useDispatch();
  // redux
  const taskDetails = task.selectors.useRenterInsurance(id);
  const pending = task.selectors.useUpdateRenterInsuranceRequestPending();

  const selectedPurchaseOption = get(taskDetails,'selected_purchase_option',{});
  const { defaultProvider } = task.getPurchaseOptionFlowImplications(selectedPurchaseOption);
  const activeRequest = task.helpers.getActiveRequest(taskDetails);

  const _handleSubmit = (data) => {
    if(pending) return;
    dispatch(task.actions.verifyEpremiumPolicy(activeRequest.id, data))
      .then(resp => changeScreen('next', resp))
      .catch(err => Notify.error(format.error(err)));
  };

  const handleProviderRedirect = () => {
    return dispatch(task.actions.getProviderLink(id, get(defaultProvider, 'id')))
      .then(link => window.open(decodeURIComponent(link)));
  };

  const fields = [
    {
      label: 'Policy number',
      type: 'text',
      name: 'policy_number',
      value: get(activeRequest,'policy_number'),
      required: true,
    },
    {
      type: 'content',
      name: 'epremium_listener',
      content: (<EpremiumListener />),
    },
  ];

  return (
    <div className={taskCSS.holder}>

      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} />
        <Notebox
          style={{maxWidth:'660px'}}
          heading='Still need to purchase?'
          body={`Shop for an ePremium policy and satisfy renters insurance requirements`}
          color="blue"
          icon={{ library: 'code', symbol: 'Info-circle' }}
          actions={(
            <Button text={get(defaultProvider,'cta','Enroll now')} onClick={handleProviderRedirect} />
          )}
        />

        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
            <div className={taskCSS.screen_edit_form}>
              <DynamicForm
                id={`${screen.slug}-form`}
                fields={fields}
                formStyle={'underline'}
                onSubmit={_handleSubmit}
                disabled={pending}
              />
            </div>
          </div>
        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            <label
              htmlFor={`${screen.slug}-form-submit`}
              tabIndex="0"
              role="button"
              className={classNames('btn-primary btn--full',{'loading':pending})}
              disabled={pending}
            >
              Next
            </label>
          </div>
        </div>
      </div>

    </div>
  );
}
