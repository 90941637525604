import React from 'react';
import classNames from 'classnames';

import { format } from '@moved/services';
import { Icon } from '@moved/ui';

import { useResidentTags } from '../../../../common/hooks/useResidentTags';

import CSS from './styles/SelectProvider.module.scss';

export const ProviderName = ({ provider }) => {
  return provider.image ? (
    <div className={CSS.provider_logo_container}>
      <img src={provider.image} alt={provider.name} className={CSS.provider_logo} />
    </div>
  ) : (
    <h3>{provider.name}</h3>
  );
};

export const ProviderDescription = ({ provider }) => {
  return (
    <div className={CSS.provider_description}>
      {provider.description}
    </div>
  );
};

export const ProviderLinks = ({ provider }) => {
  const residentTags = useResidentTags();
  return (
    <div className={CSS.provider_links}>
      {
        provider.url && (
          <a
            className={classNames(CSS.provider_cta)}
            href={format.url(format.interpolate(provider.url,residentTags)).href}
            target='_blank'
            rel='noopener noreferrer'
            onClick={e => e.stopPropagation()}
            >
            <Icon library='devices' symbol='Wi-fi' size='20px' className={CSS.cta_icon}/>
            <span>{format.simpleHostname(provider.url)}</span>
          </a>
        )
      }
      {
        provider.phone && (
          <a
            className={classNames(CSS.provider_cta)}
            href={`tel:${provider.phone}`}
            target='_blank'
            rel='noopener noreferrer'
            onClick={e => e.stopPropagation()}
          >
            <Icon library='communication' symbol='Call' size='20px' className={CSS.cta_icon}/>
            <span>{format.phone(provider.phone)}</span>
          </a>
        )
      }
      {
        provider.sales_contact_email && (
          <a
            className={classNames(CSS.provider_cta)}
            href={`mailto:${provider.sales_contact_email}`}
            target='_blank'
            rel='noopener noreferrer'
            onClick={e => e.stopPropagation()}
          >
            <Icon library='communication' symbol='Mail' size='20px' className={CSS.cta_icon}/>
            <span>{provider.sales_contact_name ? provider.sales_contact_name : provider.sales_contact_email}</span>
          </a>
        )
      }
    </div>
  );
};

export const ProviderDetails = ({ provider, task }) => {
  return provider && (
    <div className={CSS.chosen_provider}>
      <div className={CSS.name}>
        <ProviderName provider={provider} />
        <ProviderDescription provider={provider} />
      </div>
      <ProviderLinks provider={provider} task={task} />
    </div>
  );
};
