import { get, merge } from 'lodash';

import { petRegistration as helpers } from '@moved/product';

import { BaseTask } from '../../components/BaseTask';
import * as actions from './actions';
import * as selectors from './actions/selectors';
import { screens } from './screens';
import { routes } from './routes';

export class PetRegistration extends BaseTask {

  constructor() {
    super({
      routes: routes,
      screens: screens,
      actions: actions,
      selectors: {
        useTaskable: selectors.usePetRegistration,
        useTaskablePending: selectors.usePetRegistrationPending,
        ...selectors,
      }
    });
  }

  static label = 'Pet Registration';
  static slug = 'pet-registration';
  static icon = 'Dog';
  static helpers = helpers;
  static config = {
    supportsArtifacts: false,
  };

  _flowBuilder(taskable) {
    const request = get(taskable,'requests[0]');
    const pets = get(request,'pets');
    const settings = get(taskable,'settings') || {};
    const showPetMedical = [
      settings.neutered_status_enabled,
      settings.neutered_date_enabled,
      settings.vaccine_records_enabled,
      settings.license_number_enabled,
      settings.license_documentation_enabled,
    ].some(Boolean);

    switch(taskable.has_pets) {
      case true:
        if(get(pets,'length') > 0) {
          return [
            this.screens['has-pets'],
            ...pets.map(pet => {
              return {
                isCategory: true,
                label: pet.name || "Animal registration",
                key: `${pet.name}_registration`,
                screens: [
                  {
                    ...this.screens['pet-type'],
                    context: pet.id,
                    key: `${this.screens['pet-type'].slug}_${pet.id}`,
                  },
                  {
                    ...this.screens['pet-details'],
                    context: pet.id,
                    key: `${this.screens['pet-details'].slug}_${pet.id}`,
                  },
                  showPetMedical && {
                    ...this.screens['pet-medical'],
                    context: pet.id,
                    key: `${this.screens['pet-medical'].slug}_${pet.id}`,
                  },
                  settings.photo_enabled && {
                    ...this.screens['pet-photo'],
                    context: pet.id,
                    key: `${this.screens['pet-photo'].slug}_${pet.id}`,
                  },
                ].filter(v => v),
              };
            }),
            this.screens['summary'],
            this.screens['confirmation'],
          ].filter(v=>v);
        }
        else {
          return [
            this.screens['has-pets'],
            this.screens['mystery'],
            this.screens['summary'],
            this.screens['confirmation'],
          ];
        }

      case false:
        return [
          this.screens['has-pets'],
          this.screens['confirmation'],
        ];
      default:
        return [
          this.screens['has-pets'],
          this.screens['mystery'],
          this.screens['confirmation'],
        ];
    }
  }

  getStatusCopy(request, taskDetails) {
    const defaultCopy = super.getStatusCopy(request);
    const requiresApproval = taskDetails?.settings?.requires_approval;
    let requestCopy = {};
    switch(request.status) {
      case 'rejected':
        requestCopy = {
          summary: {
            flavor: 'Review the requested changes below and submit updated pet details.',
          },
        };
        break;
      case 'pending-approval':
        requestCopy = {
          summary: {
            flavor: 'Property Management has been notified of your submitted pet details. You will be notified when your submission is reviewed.',
          },
          history: {
            title: `Pet registration pending approval.`,
          },
        };
        break;
      case 'approved':
        requestCopy = {
          summary: {
            flavor: requiresApproval ?
              'The pet details you submitted have been reviewed and approved.' :
              'Your pet or service animal registration details have been submitted.'
          },
        };
        break;
      default:
        requestCopy = {};
    }
    return merge({},defaultCopy,requestCopy);
  }

  /* START OVERRIDES */
  /* END OVERRIDES */

  /* TASK SPECIFIC */
  getActivePet(taskable, id) {
    const request = get(taskable,'requests[0]');
    if(id) return request.pets.find(pet => pet.id === parseInt(id));
    return false;
  }
  /* END TASK SPECIFIC */

}
