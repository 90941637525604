import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import classNames from 'classnames';

import { format, hooks, helpers, useNotify } from '@moved/services';
import { Icon } from '@moved/ui';

import { ResidentTagRenderer } from '../../../../common';
import { ScreenTitle } from '../../shared';
import { KeyPickupInstructionsLink } from './KeyPickupInstructionsLink';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import keyCSS from './styles/KeyPickup.module.scss';

const OptionCard = ({option, action, pending}) => {
  const isMobile = hooks.useMobile();
  const { content } = option;
  const address = get(option, 'address');
  const instructions = get(option,'instructions');
  const [selected, setSelected] = useState();

  const onSelect = (data) => {
    setSelected(true);
    action(data);
  };

  return (
    <div className={keyCSS.card_wrapper}>
      <div className={keyCSS.card_item_wrapper}>
        <div className={keyCSS.card_item}>
          { content.banner_text && <div className={keyCSS.ribbon}><span className={keyCSS.ribbon_text}>{content.banner_text}</span></div> }
          <div className={keyCSS.card_content}>
            <div className={keyCSS.content_top}>
              { content.icon_symbol &&
              <div className={keyCSS.card_icon}>
                <Icon symbol={get(content,'icon_symbol')} library={get(content,'icon_library')} size={isMobile ? '40px' : '48px'} />
              </div>
              }
              <div className={keyCSS.title_wrapper}>
                { content.title && <div className={keyCSS.card_title}>{content.title}</div> }
                { content.subtitle && <div className={keyCSS.card_subtitle}>{content.subtitle}</div> }
              </div>
              <div className={keyCSS.card_action}>
                <div className={keyCSS.button_wrapper}>
                  <button
                    type='button'
                    id={`pickup_option_${option.value}`}
                    className={classNames('btn-primary', keyCSS.card_button, {'loading': pending && selected, 'btn-gray': !option.recommended})}
                    style={content.cta_color ?
                      {
                        backgroundColor: content.cta_color,
                        color: helpers.isHexDark(content.cta_color) ? '#fff' : null
                      } : null
                    }
                    value={option.value}
                    onClick={onSelect}
                    disabled={pending}
                  >Select</button>
                </div>
              </div>
            </div>

            <div className={keyCSS.content_bottom}>
              <div className={keyCSS.card_description}>
                { content.description_html && (
                  <ResidentTagRenderer content={content.description_html} />
                )}

                { (address != null || (get(instructions, 'blocks.length', 0) > 0)) &&
                  <span className={keyCSS.location_info}>
                    { address && (
                      <>
                        <Icon className={'location_icon'} library={'map'} symbol={'Marker#1'} size='20px'/>
                        <span className='flex-auto'>{'Located at ' + format.address(address)}</span>
                      </>
                    )}
                    <KeyPickupInstructionsLink instructions={instructions}/>
                  </span>
                }

              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
  )
};

export const KeySelectCalendarType = ({screen, changeScreen, task}) => {
  const { id } = useParams();
  const Notify = useNotify();
  const dispatch = useDispatch();
  const keyPickupTask = task.selectors.useTaskable(id);
  const createPending = task.selectors.useCreateRequestPending();
  const updatePending = task.selectors.useUpdateRequestPending();
  const calOptions = task.selectors.useKeyCalendar();
  const calendarsPending = task.selectors.useKeyCalendarPending();

  useEffect(() => {
    dispatch(task.actions.getCalendarOptions(id))
      .catch(() => Notify.error('Error loading calendar data'))
  // eslint-disable-next-line
  },[]); // only run on mount

  const handleSelect = data => {
    if (createPending || updatePending || calendarsPending) return;

    const draftRequest = get(keyPickupTask,'requests[0].status') === 'draft' ? keyPickupTask.requests[0] : null;

    if(draftRequest) {
      dispatch(task.actions.updateRequest(draftRequest.id, { 'building_calendar_id': data }))
        .then((resp) => changeScreen('next',resp))
        .catch(error => Notify.error( get(error, 'response.data.message') ));
    } else {
      dispatch(task.actions.createRequest(id))
        .then((resp) => dispatch(task.actions.updateRequest(resp.requests[0].id, {
          'tenant_key_ids': [ keyPickupTask.current_tenant_key.id],
          'building_calendar_id': data
        })))
        .then((resp) => changeScreen('next',resp))
        .catch(error => Notify.error( get(error, 'response.data.message') ));
    }
  }

  return (
    <div className={taskCSS.holder}>
      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} />
        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
            {
              calOptions.map((option, index) => {
                return (
                  <OptionCard
                    key={`option_${option.id}`}
                    option={option}
                    pending={createPending || updatePending}
                    action={() => handleSelect(option.id)}
                  />
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
};
