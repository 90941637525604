import { merge } from 'lodash';

import * as createCustomTask from './createCustomTask';
import * as createTaskList from './createTaskList';
import * as createNewTasks from './createNewTasks';
import * as deleteTask from './deleteTask';
import * as getAvailablePersonalTaskTypes from './getAvailablePersonalTaskTypes'
import * as getTask from './getTask';
import * as getTaskList from './getTaskList';
import * as reorderTaskList from './reorderTaskList';
import * as updateTask from './updateTask';
import * as updateTaskList from './updateTaskList';

export const initialState = merge(
  createCustomTask.initialState,
  createTaskList.initialState,
  createNewTasks.initialState,
  deleteTask.initialState,
  getAvailablePersonalTaskTypes.initialState,
  getTask.initialState,
  getTaskList.initialState,
  reorderTaskList.initialState,
  updateTask.initialState,
  updateTaskList.initialState,
);

export const reducers = [
  createCustomTask.reducer,
  createTaskList.reducer,
  createNewTasks.reducer,
  deleteTask.reducer,
  getAvailablePersonalTaskTypes.reducer,
  getTask.reducer,
  getTaskList.reducer,
  reorderTaskList.reducer,
  updateTask.reducer,
  updateTaskList.reducer,
];
