import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useNotify, format } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';

import { getMoveSummaries } from '../actions';
import { useMoveSummariesPending } from '../actions/selectors';

export const MoveSummaries = ({ children }) => {
  // hooks
  const Notify = useNotify();
  // redux
  const dispatch = useDispatch();
  const pending = useMoveSummariesPending();

  useEffect(() => {
    dispatch(getMoveSummaries())
      .catch(error => Notify.error(format.error(error)));
  },[dispatch,Notify]);

  return (
    <>
     { pending && <LoaderOverlay/> }
     { children }
    </>
  );
};

export const withMoveSummaries = (Wrapped) => (props) => (<MoveSummaries><Wrapped {...props}/></MoveSummaries>);
