import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_MOVER_BOOKING_TASK',
  request: (id) => request.get(`/mover-booking-tasks/${id}`).then(r => get(r, 'data.data.mover_booking_task')),
  selector: (state, id) => get(state,`moverBookingTasks.${id}`),
  reducers: {
    success: (state, { response: bookingTask }) => {
      return {
        ...state,
        moverBookingTasks: {
          ...state.moverBookingTasks,
          [bookingTask.id]: bookingTask,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  useResource: useMoverBookingTask,
  usePending: useMoverBookingTaskPending,
} = generate.redux(creator);

export const initialState = {
  moverBookingTasks: {},
};
