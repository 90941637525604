import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import classNames from 'classnames';
import { get } from 'lodash';

import { DynamicForm, AtomSpinner } from '@moved/ui';
import { useNotify, fieldService, format } from '@moved/services';

import { OtherPets } from './';
import { ScreenTitle } from '../../shared';
import taskCSS from '../../../components/styles/TaskFlow.module.scss';

// Multi-file document upload screen component
export const HavePets = ({ screen, changeScreen, taskDefinition, taskDetails }) => {
  // HOOKS
  const { id } = useParams();
  const dispatch = useDispatch();
  const Notify = useNotify();

  // REDUX
  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);
  const requestPending = taskDefinition.selectors.useCreatePetRequestPending();
  const updatePending = taskDefinition.selectors.useUpdatePetRegistrationPending();
  const loading = taskDefinition.selectors.usePetRegistrationPending();

  const pending = requestPending || updatePending;

  const fields = [
    {
      type: 'itemList',
      name: 'has_pets',
      label: 'Do you have any pets?',
      hiddenLabel: true,
      required: 'You must tell us if you have any pets',
      options: [
        {
          label: 'I have one or more animals to register',
          value: true,
          icon: { symbol: 'Check', library: 'navigation' },
        },
        {
          label: (<>No, I don&rsquo;t own any pets or service animals</>),
          value: false,
          icon: { symbol: 'Close', library: 'navigation' },
        },
      ]
    }
  ];
  fieldService.hydrate(fields,taskDetails);


  const _handleSubmit = formData => {
    if(pending) return false;

    // Create pet helper
    const createPet = (request, resp) => {
      return get(request,'pets.length') === 0
        ? dispatch(taskDefinition.actions.createPet(request.id, {}))
        : new Promise((resolve) => resolve(resp));
    };

    return dispatch(taskDefinition.actions.update(id, formData))
      .then((resp) => {
        if (formData.has_pets === false) return resp;

        if (!activeRequest) {
          return dispatch(taskDefinition.actions.createRequest(id))
            .then(resp => {
              // create pet on newly created request
              const request = taskDefinition.helpers.getActiveRequest(resp);
              return createPet(request);
            })
            .then(() => dispatch(taskDefinition.actions.update(id, formData)))
            .then(resp => changeScreen('next',resp));
        }
        // normal screen submit
        else {
          return createPet(activeRequest)
            .then(resp => changeScreen('next',resp))
        }
      })
      .catch(error => Notify.error(format.error(error)));
  };

  return (
    <div className={taskCSS.holder}>
      <div className={classNames(taskCSS.task)}>
        <ScreenTitle screen={screen} />

        <OtherPets taskDetails={taskDetails} />

        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
            <div className={'mb-50'}>
              { (!loading) ? (
                <DynamicForm
                  id={`${screen.slug}-form`}
                  fields={fields}
                  formStyle={'underline'}
                  onSubmit={_handleSubmit}
                  disabled={pending}
                />
              ) : (
                <AtomSpinner />
              )}
            </div>
          </div>
        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            <label
              htmlFor={`${screen.slug}-form-submit`}
              tabIndex="0"
              role="button"
              className={classNames('btn-primary','btn--full',{ loading: pending })}
              disabled={pending}
            >
              Submit
            </label>
          </div>
        </div>

      </div>
    </div>
  );
};
