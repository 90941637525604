import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { userService, useUser, useNotify } from '@moved/services';

import { Four04 } from '../Four04';
import { LayoutSimple } from '../LayoutSimple';

export const DefaultPageNotFound = ({ message }) => {
  const { user, isAuthenticated } = useUser();
  const history = useHistory();
  const Notify = useNotify();

  useEffect(() => {
    if(!isAuthenticated) {
      // adding a 0 offset timeout gives one render cycle for a "logout" event
      // to handle it's own redirect before this component attempts to redirect
      const handleRedirect = setTimeout(() => {
        Notify.default('Please sign in to continue');
        userService.redirect(user,history);
      }, 0);
      return () => clearTimeout(handleRedirect);
    }
  },[]); // eslint-disable-line

  if(!isAuthenticated) return null;

  return (
    <LayoutSimple>
      <Four04 message={message} />
    </LayoutSimple>
  );
}
