import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom'
import { get } from 'lodash';

import { useModal, useNotify } from '@moved/services';
import { ConfirmationModal, Button } from '@moved/ui';

import { getMove } from '../../../../moves/actions';
import { SummaryScreen } from '../../shared/components/SummaryScreen';

import CSS from './styles/Summary.module.scss';

export const Summary = ({ screen, task, origin, taskBaseRoute }) => {
  // hooks
  const { id, moveId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const Modal = useModal();
  const Notify = useNotify();

  // redux
  const activeMoveStep = task.selectors.useActiveMoveStep();
  const building = task.selectors.useBuilding(get(activeMoveStep,'building.slug'));
  const reservation = task.selectors.useTaskable(id);
  const pending = task.selectors.useCreateRequestPending();

  const confirmResetReservation = () => {
    return Modal.open(
      <ConfirmationModal
        title={'Edit appointment'}
        copy={'Note: Your existing appointment will be held for you while you edit. It will be canceled and replaced with a new appointment, only once the new appointment has been submitted and confirmed by the property.'}
        confirmText={'Proceed with editing'}
        cancelText={'Never mind'}
        onConfirm={rescheduleReservation}
      />,
      { sondheim: true },
    );
  };

  const rescheduleReservation = () => {
    return dispatch(task.actions.createRequest(id))
      // refresh the move because reset affects MT on the move
      .then(r => dispatch(getMove(moveId)).then(() => r))
      .then((resp) => history.push(`${taskBaseRoute}${task.getStartOverRoute(resp)}`))
      .catch(error => Notify.error( get(error, 'response.data.message') ));
  }

  const extraAttributes = [
    get(building,'settings.building_reservation_rescheduling') && {
      value: (
        <div className={CSS.reschedule}>
          <Button
            size='small'
            color='secondary'
            text='Request a different time'
            onClick={() => get(reservation,'reservation.status') === 'approved' ? confirmResetReservation() : rescheduleReservation()}
            disabled={pending}
          />
        </div>
      )
    },
  ].filter(Boolean);

  return (
    <SummaryScreen
      origin={origin}
      screen={screen}
      extraAttributes={extraAttributes}
    />
  )
};
