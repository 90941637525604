import { get } from 'lodash';

import { BookMoversDirect } from '../book-movers-for-direct/definition';

import * as defaultActions from '../book-movers/actions';
import * as actions from './actions';
import { screens } from './screens';

export class BookMoversStorage extends BookMoversDirect {
  constructor() {
    super();
    // extend and override screens
    this.screens = {
      ...this.screens,
      ...screens,
    };
    // extend and override actions
    this.actions = {
      ...this.actions,
      // need to restore the overridden default actions for get and update
      get: defaultActions.get,
      update: defaultActions.update,
      ...actions,
    };
  }

  static slug = 'book-movers-for-storage';

  _flowBuilder(taskData) {
    // unknown geography
    if(get(taskData,'mover_booking.stops',[]).length < 2) return [
      this.screens['date'],
      this.screens['time-preference'],
      this.screens['address'],
      this.screens['mystery'],
      this.screens['confirmation'],
    ];
    // full long distance flow
    if(get(taskData,'mover_booking.long_distance')) return [
      this.screens['date'],
      this.screens['time-preference'],
      this.screens['address'],
      this.screens['long-distance'],
      this.isBooked(taskData) ? this.screens['confirmation-book-movers'] : this.screens['confirmation-long-distance'],
    ];
    // full non-core-geo flow
    if(get(taskData,'mover_booking.eligible_for_rates') === false) return [
      this.screens['date'],
      this.screens['time-preference'],
      this.screens['address'],
      this.screens['confirmation-non-core-geo'],
    ];
    // full local move flow
    return [
      this.screens['date'],
      this.screens['time-preference'],
      this.screens['address'],
      this.screens['special'],
      this.screens['inventory'],
      this.screens['moving-quotes'],
      this.screens['moving-summary'],
      this.screens['agreements'],
      this.screens['moving-payment'],
      this.screens['confirmation-book-movers'],
    ];
  }

  /* START OVERRIDES */
  /* END OVERRIDES */

  /* TASK SPECIFIC */
  /* END TASK SPECIFIC */

}
