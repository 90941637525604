import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';

import { Transitions, Greeting } from '@moved/ui';
import { useUser, hooks } from '@moved/services';

import { LayoutCollapsibleHeader, LayoutSidebar, SplashBG } from '../../common';
import { useMove } from '../../moves/actions/selectors';
import { getMoveNavigation } from '../../moves/helpers/navigation';

import { getMarketplaceAds } from '../actions';
import { useMarketplaceAds } from '../actions/selectors';

import { SponsoredAds } from './SponsoredAds';
import { MarketplaceAds } from './MarketplaceAds';
import { BookMovers } from './BookMovers';
import { Faqs } from './Faqs';

import CSS from './styles/Marketplace.module.scss';

export const Marketplace = () => {
  // hooks
  const dispatch = useDispatch();
  const { moveId } = useParams();
  const { user } = useUser();
  const isMobile = hooks.useMobile();
  const move = useMove(moveId);
  const moveStep = get(move,'move_steps[0]');
  // we want the partner, but it's not available, so use the building as a proxy
  const moveStepBuilding = moveStep && get(moveStep,'building');

  const marketplaceAds = useMarketplaceAds(move.id) || [];
  const movedAds = marketplaceAds.find(group => get(group,'sponsor_partner.name') === 'Moved');
  const partnerAds = marketplaceAds.find(group => get(group,'sponsor_partner.name') !== 'Moved');

  useEffect(() => {
    dispatch(getMarketplaceAds(move.id));
  },[]); // eslint-disable-line

  // Decision to temporarily keep marketplace using the old collapsible header layout
  // for consumer direct moves (i.e. no moveSteps). In the future marketplace for consumer
  // direct will coexist with personal task dashboard and then we'll consolidate
  // to the sidebar layout.
  const LayoutComponent = moveStep != null ? LayoutSidebar : LayoutCollapsibleHeader;

  return (
    <LayoutComponent className={CSS.layout_wrapper} navigation={getMoveNavigation(move)} withParallax>
      <Helmet>
        <title>{`Welcome to Moved`}</title>
      </Helmet>

      <SplashBG
        background={isMobile && `${process.env.REACT_APP_S3_ASSET_URL}/partners/default/splash-landscape.jpg`}
        video={!isMobile && `${process.env.REACT_APP_S3_ASSET_URL}/partners/default/splash-landscape.mp4`}
        className={CSS.splash}
      >
        { moveStep ? (
          <div>
            { moveStepBuilding && (
              <div className={CSS.building_logo}>
                <img src={moveStepBuilding.logo_url} alt={moveStepBuilding.name} />
              </div>
            )}
            <div className={CSS.greeting}>Moving Offers</div>
          </div>
        ) : (
          <Greeting user={user} className={CSS.greeting}/>
        )}
      </SplashBG>

      <section id='dashboard' className={CSS.dashboard}>
        <div className={CSS.transition_wrapper}>
          <Transitions pageKey={'marketplace'} transition={'fade'}>
            <div className={CSS.tasks_section}>
              <div className='container'>

                <SponsoredAds adCollection={partnerAds} move={move} />

                {/*
                  TODO: once we've done the work to share one mover_booking
                  across multiple move_steps (or without one), we can show this
                  even for residents
                */}
                { !moveStep && (
                  <BookMovers move={move} />
                )}

                <MarketplaceAds adCollection={movedAds} move={move} />

                <Faqs />

              </div>
            </div>
          </Transitions>
        </div>
      </section>

    </LayoutComponent>
  );
}
