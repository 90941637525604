import React from 'react';
import { useParams, Link } from 'react-router-dom'
import { get } from 'lodash';

import { useUser, format, useModal } from '@moved/services';
import { CreditCardLogo, CompleteCard, Accordion, Notebox, FieldList } from '@moved/ui';

import { Snippet } from '../../../../snippets';
import { ScreenTitle, CompleteScreenActions } from '../../shared';

import { BookBreakdown } from './BookBreakdown';
import { SummaryFields } from './SummaryFields';
import { ResolvePaymentIssueModal } from './ResolvePaymentIssueModal';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import confirmCSS from './styles/ConfirmBook.module.scss';

export const ConfirmBook = ({ screen, changeScreen, task, origin }) => {

  const { id, } = useParams();
  const { user } = useUser();
  const Modal = useModal();

  const taskDetails = task.selectors.useTaskable(id);
  const bookedServiceOrder = get(taskDetails,'mover_booking.booked_service_order');
  const orderStatus = get(bookedServiceOrder,'order_status');
  const paymentStatus = get(bookedServiceOrder,'payment_status');
  let needsUpdatedCard = false;

  const resolvePaymentIssue = () => {
    Modal.open(<ResolvePaymentIssueModal order={bookedServiceOrder}/>);
  };

  let statusCardDetails = {};

  switch(orderStatus) {
    case 'cancelled':
      statusCardDetails = {
        status: 'cancelled',
        icon: { symbol: 'Sad', color: 'red'},
        title: 'Order canceled',
        flavor: (
          <Snippet tag={'tasks.book-movers.summary.moved.cancelled'}>
            Your previously placed order for professional movers has been canceled.<br/>
            All holds have been released on your card minus applicable cancellation fees.
          </Snippet>
        ),
      };
      break;
    case 'completed':
    default:
      switch(paymentStatus) {
        case 'pre_authorization_failed':
          needsUpdatedCard = true;
          statusCardDetails = {
            color: 'red',
            icon: { symbol: 'Warning#2', color: 'red'},
            title: 'Update payment method',
            flavor: (
              <Snippet tag={'tasks.book-movers.summary.moved.pre-auth-failed'}>
                Your card was declined when attempting to authorize your payment.<br/>
                You will need to update your card on file to ensure your booking can proceed.
              </Snippet>
            ),
          };
          break;
        case 'payment_issue':
          needsUpdatedCard = true;
          statusCardDetails = {
            color: 'red',
            icon: { symbol: 'Warning#2', color: 'red'},
            title: 'Update payment method',
            flavor: (
              <Snippet tag={'tasks.book-movers.summary.moved.payment-issue'}>
                When attempting to capture the final payment your card was declined.<br/>
                Please update your card below to complete your transaction.
              </Snippet>
            ),
          };
          break;
        case 'paid':
          statusCardDetails = {
            color: 'green',
            icon: { symbol: 'Done', color: 'green'},
            title: 'Your move is complete!',
            flavor: (
              <Snippet tag={'tasks.book-movers.summary.moved'}>
                We sent a receipt of your move details to {user.email}.<br/>
                If you're moving again you can
                {' '}<Link to='/moves'>start a new move</Link>{' '}
                anytime.
              </Snippet>
            ),
          };
          break;
        case 'no_payment_method':
        case 'valid_payment_method':
        case 'authorized':
        default:
          statusCardDetails = {
            color: 'green',
            icon: { symbol: 'Sending', color: 'green'},
            title: 'Great, your order has been placed!',
            flavor: (
              <Snippet tag={'tasks.book-movers.summary.moved'}>
                We sent a confirmation of your move details to {user.email}.<br/>
                You will be introduced to your movers soon!
              </Snippet>
            ),
          };
          break;
      }
      break;
  }

  const transactionFields = [
    {
      icon: { symbol: 'Truck', library: 'home' },
      label: 'Moving company',
      value: get(bookedServiceOrder,'vendor.name'),
    },
    {
      icon: { symbol: 'Check', library: 'navigation' },
      label: 'Confirmation number',
      value: `#${get(bookedServiceOrder,'confirmation_number')}`,
    },
    {
      icon: { symbol: 'Date', library: 'code' },
      label: 'Booking date',
      value: format.date(get(bookedServiceOrder,'purchase_date_time'),'dateTime'),
    },
    {
      icon: needsUpdatedCard ? { symbol: 'Warning#2', color: 'red' } : { symbol: 'Credit-card', library: 'shopping' },
      label: needsUpdatedCard ? (<span className='red'>Payment method</span>) : 'Payment method',
      value: (
        <>
          <CreditCardLogo symbol={get(bookedServiceOrder,'payment_information.data.card_type')} />
          <span className={'ml-10'}>
            {format.capitalize(get(bookedServiceOrder,'payment_information.data.card_type'))} ending in {get(bookedServiceOrder,'payment_information.data.card_last4')}
          </span>
          { needsUpdatedCard && (
            <span className='ml-15 btn-primary btn--small' onClick={resolvePaymentIssue}>Update</span>
          )}
        </>
      ),
    },
  ].filter(v=>v&&v.value);

  return (
    <div className={taskCSS.holder}>

      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} />

        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>

            <div className={confirmCSS.content}>
              <div className={confirmCSS.confirmation_content}>

                <CompleteCard {...statusCardDetails} detailsTitle={null}>
                  { !['completed','cancelled'].includes(orderStatus) && (
                    <Notebox
                      icon={{symbol:'Info'}}
                      heading='Please note'
                      body={(<>
                        Once introduced to your movers, you'll need to confirm a
                        few details, <strong>including the detailed inventory list</strong>,
                        best contact phone number, and a few other items.
                      </>)}
                    />
                  )}
                  <FieldList list={transactionFields} />
                </CompleteCard>

                <div className={confirmCSS.summary}>
                  <Accordion
                    panels={[{
                        label: 'Move summary',
                        content: (
                          <div className={confirmCSS.summary_fields}>
                            <SummaryFields taskDetails={taskDetails} />
                          </div>
                        ),
                    }]}
                  />
                </div>

              </div>

              <BookBreakdown
                taskDetails={taskDetails}
                discounts={get(bookedServiceOrder,'discounts')}
                showCancellationPolicy={true}
                showInsuranceDisclaimer={true}
              />

            </div> {/* confirmCSS.content */}
          </div> {/* taskCSS.folder */}

        </div>

        <CompleteScreenActions origin={origin} />

      </div>

    </div>
  );
}
