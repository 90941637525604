// Search through flow screen by screen
// to find the next screen the customer should see
// based on the data collected from each screen
export const getRecommendedScreen = (taskable, flow, taskDefinition) => {
  let activeScreen;
  flow.some(screen => {
    // if screen has no requirements put it as active unless there's already an active
    if(!screen.isCompleted) activeScreen = activeScreen || screen;
    // if screen is explicitly complete, clear the active screen\
    else if(screen.isCompleted(taskable, taskDefinition, screen.context)) activeScreen = false;
    // if screen is explicitly incomplete
    else {
      // leave any unrequired screens as still active, if none, use this screen
      activeScreen = activeScreen || screen;
      return true; // stop searching
    }
    return false; // keep searching
  });
  return activeScreen;
};

// Default screen access control based on assumptions:
// a) don't let the user go beyond any required inputs unless they are satisfied
//    in the taskable data
// b) don't let the user go backward if they are past a screen with flag
//    `backRestricted == true` (also supports function backRestricted(taskable))
export const canAccessScreen = (taskable, slug, context, taskDefinition) => {
  const flow = taskDefinition.flow;
  const requestedIndex = flow.findIndex(screen => screen.slug === slug);
  if(requestedIndex === -1) return false; // can not access a screen not in the flow

  const recommendedScreen = taskDefinition.getRecommendedScreen(taskable, context);
  const recommendedIndex = flow.findIndex(screen => screen.slug === recommendedScreen.slug);
  const nextRequiredIndex = flow.findIndex((screen,index) => {
    return (screen.isCompleted && !screen.isCompleted(taskable,taskDefinition,screen.context)) || index === flow.length-1;
  });

  // Generic block from navigating forward more than the next required screen
  if(requestedIndex > nextRequiredIndex) return false;
  // Generic block from navigating back from any restricted screen
  const backRestricted = typeof recommendedScreen.backRestricted === 'function' ?
    recommendedScreen.backRestricted(taskable) : recommendedScreen.backRestricted;

  if(backRestricted && requestedIndex < recommendedIndex) return false;

  // Allow access to all other cases
  return true;
};

// Convenience function to construct the base route for a task
// requires taskDefinition to have: slug
export const getBaseRoute = (taskId, taskDefinition) => `/tasks/${taskId}/${taskDefinition.slug}`;

// Convenience function to construct the route to a specific screen
// requires taskDefinition to have: slug
export const getScreenRoute = (screen, taskableId) => `/${taskableId}/${screen.slug}${screen.context ? '/'+screen.context : ''}`;
