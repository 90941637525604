import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import * as Yup from 'yup';
import classNames from 'classnames';

import { useNotify, format, useUser, hooks } from '@moved/services';
import {
  AtomSpinner,
  Checkbox,
  Icon,
  Rating,
  Form,
  FormText,
  FormPhone,
  FormButton,
  PhoneValidation,
  BouncingDots
} from '@moved/ui';

import { useSnippet } from '../../../../snippets/hooks/useSnippet';

import { ScreenLayout } from '../../shared/components/ScreenLayout';
import { ScreenTitle } from '../../shared/components/ScreenTitle';
import { ScreenContent } from '../../shared/components/ScreenContent';
import { ScreenActions } from '../../shared/components/ScreenActions';

import { MoversAnimation } from './MoversAnimation';

import CSS from './styles/MovingCompanyLeads.module.scss';

export const MovingCompanyLeads = ({ screen, changeScreen, task }) => {
  const { id } = useParams();
  const Notify = useNotify();
  const dispatch = useDispatch();
  const { user } = useUser();
  const isMobile = hooks.useMobile();

  const taskDetails = task.selectors.useTaskable(id);
  const { featured_companies=[], non_featured_companies=[] } = task.selectors.useMovingCompanyAds(id) || {};
  const updatePending = task.selectors.useUpdateMoverBookingTaskPending();
  const completePending = task.selectors.useCompleteMoverBookingTaskPending();
  const pending = updatePending || completePending;

  const companyAds = [
    ...featured_companies,
    ...non_featured_companies,
  ];

  const previouslySubmitted = taskDetails?.selected_moving_company_ads?.length > 0;

  const [selected, setSelected] = useState(taskDetails?.selected_moving_company_ads?.[0]?.id);
  const [loading, setLoading] = useState(true);
  const [displayTransition, setDisplayTransition] = useState();

  const declineSnippet = useSnippet('tasks.book-movers.moving-companies.decline-text', 'Decline text');

  useEffect(() => {
    dispatch(task.actions.getMovingCompanyAds(id))
      .finally(() => setLoading(false))
      .catch(() => Notify.error('Unable to retrieve moving company list.'))
  },[id, task, dispatch, Notify]);

  // auto skip this screen if returning after previous submission
  useEffect(() => {
    if(previouslySubmitted) dispatch(task.actions.complete(id))
      .then(r => changeScreen('next', r))
      .catch(err => Notify.error(format.error(err)));
  },[]);  // eslint-disable-line

  const onSelect = (ad) => setSelected(ad.id);

  // Submit form function
  const handleSubmit = (formData) => {
    if(pending || !selected) return;
    const data = {
      mover_booking: {
        id: taskDetails?.mover_booking?.id,
        ...formData,
      },
      selected_moving_company_ad_ids: [selected],
    };
    setDisplayTransition(true);
    new Promise((res) => setTimeout(() => res(), 5000))
      .then(() => dispatch(task.actions.update(id, data)))
      .then(() => dispatch(task.actions.complete(id)))
      .then(r => changeScreen('next', r))
      .catch(err => Notify.error(format.error(err, {'moving-company-ad-responses-exist':'Contact preferences already saved.'})));
  };

  const handleSkip = () => {
    if(pending) return;
    const data = { selected_moving_company_ad_ids: [] };
    dispatch(task.actions.update(id, data))
      .then(() => dispatch(task.actions.complete(id)))
      .then(r => changeScreen('next', r))
      .catch(err => Notify.error(format.error(err, {'moving-company-ad-responses-exist':'Contact preferences already saved.'})));
  };

  const validation = Yup.object({
    contact_name: Yup.string().required('Required'),
    contact_email: Yup.string().email('Must be a valid email').required('Required'),
    contact_phone: PhoneValidation.required('Required'),
  });

  return (
    <Form
      name='contact-info'
      validation={validation}
      onSubmit={handleSubmit}
      className='height-full'
    >
      <ScreenLayout>
        <ScreenTitle screen={screen} />
        <ScreenContent screen={screen}>
          { loading ? (
            <div className='stackVertical-24 items-center'>
              <AtomSpinner />
              <div className='labelXS contentSecondary'>
                Gathering recommendations
              </div>
            </div>
          ) : displayTransition ? (
            <div className='stackVertical-24 items-center'>
              <MoversAnimation />
              <div className='headingM contentPrimary'>
                Finding best match<BouncingDots/>
              </div>
            </div>
          ) : companyAds.length > 0 && (
            <div className='stackVertical-48'>
              <section className='stackVertical-16'>
                <div className='labelL contentPrimary'>All we need to know is - What is most important to you?</div>
                { companyAds.map(ad => (
                  <div
                    key={ad.id}
                    className={classNames(CSS.company_card, {[CSS.selected_item]: selected === ad.id})}
                    onClick={() => !previouslySubmitted && onSelect(ad)}
                  >
                    { ad.tagline_icon && (
                      <Icon
                        className={CSS.icon}
                        symbol={ad.tagline_icon.symbol}
                        library={ad.tagline_icon.library}
                        size={isMobile ? '32px' : '48px'}
                      />
                    )}
                    <div className='stackVertical width-half'>
                      <label className='headingS contentPrimary'>{ad.tagline}</label>
                      <div className='stackHorizontal gap-8'>
                        <span className='labelS contentSecondary'>Average pricing</span>
                        <span className='labelXS contentSecondary'>&bull;</span>
                        <em className='labelS contentTertiary'>
                          {
                            ad.tagline === 'Highest Rated' ? (<strong><span className='contentPrimary'>$$$$</span>$</strong>) :
                            ad.tagline === 'Most Popular' ? (<strong><span className='contentPrimary'>$$$</span>$$</strong>) :
                            ad.tagline === 'Most Affordable' ? (<strong><span className='contentPrimary'>$$</span>$$$</strong>) :
                            (<strong><span className='contentPrimary'>$$</span>$$$</strong>)
                          }
                        </em>
                      </div>
                    </div>
                    <div className='stackVertical items-end width-half'>
                      <div className={classNames('labelL contentSecondary',CSS.reviews)}>
                        <Rating
                          value={ad.review_rating}
                          precision={0.5}
                          readOnly={true}
                          className='marginRight-4'
                          size={isMobile ? 'small' : 'medium'}
                        />
                        { ad.review_rating }
                      </div>
                      <em className='labelS contentSecondary marginLeft-8'>Average rating</em>
                    </div>
                    <div className={CSS.checkmark}>
                      <Checkbox
                        name={`lead-${ad.id}`}
                        value={selected === ad.id}
                        isControlled={true}
                      />
                    </div>
                  </div>
                ))}
              </section>
              <section className='stackVertical-12'>
                <div className='labelL contentPrimary'>Confirm your contact information</div>
                <FormText
                  name='contact_name'
                  label='Full name'
                  value={taskDetails?.mover_booking?.contact_name ?? format.fullname(user) ?? ''}
                />
                <FormText
                  name='contact_email'
                  label='Email address'
                  value={taskDetails?.mover_booking?.contact_email ?? user.email ?? ''}
                  type='email'
                />
                <FormPhone
                  name='contact_phone'
                  label='Phone number'
                  value={taskDetails?.mover_booking?.contact_phone ?? user.phone ?? ''}
                />
                <div className='stackHorizontal-8'>
                  <Icon symbol='Info-circle' library='code' color='blue' />
                  <div className='stackVertical-4 paddingTop-2'>
                    <div className='labelM contentPrimary'>
                      We respect your privacy
                    </div>
                    <div className='labelS contentSecondary'>
                      Your contact information will <b className='contentPrimary'>only be
                      shared with a single moving company</b> selected from our network of
                      vetted professionals to match your specific move based on your
                      geography, building, and preferences.
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )}
        </ScreenContent>
        <ScreenActions>
          <FormButton
            text='Next'
            type='submit'
            disabled={ pending || !selected || displayTransition }
            className={CSS.primary}
          />
          <FormButton
            text={declineSnippet ?? `I've already hired movers`}
            color='tertiary'
            onClick={() => handleSkip()}
            disabled={ pending || displayTransition }
          />
        </ScreenActions>
      </ScreenLayout>
    </Form>
  );
}
