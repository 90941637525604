import { get } from 'lodash';

// private convenience function for sorting moveSteps
const stepTypeOrder = ['move-out','move-in'];
const stepSort = (a,b) => stepTypeOrder.indexOf(a.type) - stepTypeOrder.indexOf(b.type);

// Use this to reconstruct the dashboard route if you already have the context
export const getDashboardRoute = (moveId, moveStepId) => {
  if(moveStepId) return `/moves/${moveId}/steps/${moveStepId}`;
  return `/moves/${moveId}/marketplace`;
};

// Use this if you don't know which dashboard already
export const getRecommendedDashboardRoute = (move) => {
  // sort and select the first move_step to be the active one (fail safe if no move_steps)
  const activeMoveStep = get(move,'move_steps',[]).sort(stepSort)[0];
  return getDashboardRoute(get(move,'id'),get(activeMoveStep,'id'));
};
