import { get } from 'lodash';

import { moveOutDetails as helpers } from '@moved/product';

import { BaseTask } from '../../components/BaseTask';

import * as actions from './actions';
import * as selectors from './actions/selectors';

import { screens } from './screens';

export class MoveOutDetails extends BaseTask {
  constructor() {
    super({
      screens: screens,
      actions: actions,
      selectors: {
        useTaskable: selectors.useMoveOutDetails,
        useTaskablePending: selectors.useMoveOutDetailsPending,
        ...selectors,
      }
    });
  }

  static label = 'Move Out Details';
  static slug = 'move-out-details';
  static icon = 'Sign-out';
  static helpers = helpers;

  _flowBuilder(taskData) {
    return [
      this.screens['forwarding'],
      this.screens['reasoning'],
      this.screens['feedback'],
      get(taskData,'net_promoter_score_enabled') && this.screens['score'],
      this.screens['summary'],
    ].filter(v=>v);
  }

  /* START OVERRIDES */
  canAccessScreen(taskData, screen) {
    return true;
  }
  /* END OVERRIDES */

  /* TASK SPECIFIC */
  /* END TASK SPECIFIC */

}
