import React from 'react';
import { get } from 'lodash';
import classNames from 'classnames';

import { hooks } from '@moved/services';

import { MarketplaceAd } from './MarketplaceAd';

import CSS from './styles/MarketplaceAds.module.scss';

export const MarketplaceAds = ({ adCollection, move }) => {

  const isMobile = hooks.useMobile();
  const movedAds = get(adCollection,'marketplace_ads', []);

  const blockClasses = (block) => {
    if(isMobile) return;
    const classes = [];
    const index = movedAds.findIndex(ad => ad.id === block.id);
    if(movedAds.length % 4 === 1) {
      if(index < 2) classes.push(CSS.large);
      else if(index < 5) classes.push(CSS.medium);
    }
    else if(movedAds.length % 4 === 2) {
      if(index < 6) classes.push(CSS.medium);
    }
    else if(movedAds.length % 4 === 3) {
      if(index < 3) classes.push(CSS.medium);
    }
    return classes;
  };

  return movedAds.length ? (
    <div className={CSS.ad_section}>
      <h1 className='mb-15'>Recommended</h1>
      <div className={CSS.ads_container}>
        { movedAds.map(ad => (
          <MarketplaceAd
            key={ad.id}
            ad={ad}
            moveId={move.id}
            className={classNames(CSS.ad_block,blockClasses(ad))}
            origin='marketplace'
          />
        ))}
      </div>
    </div>
  ) : null;

};
