import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import * as Yup from 'yup';

import { Icon, DynamicForm } from '@moved/ui';
import { useUser, useNotify, format } from '@moved/services';

import { LayoutCollapsibleHeader } from '../../common';

import { updateUser } from '../actions';
import { useUpdateUserPending } from '../actions/selectors';

// import CSS from '../styles/Profile.module.scss';
import CSS from '../styles/PasswordChange.module.scss';

export const PasswordChange = (props) => {
  // hooks
  const dispatch = useDispatch();
  const history = useHistory();
  const Notify = useNotify();
  const { updateUser: updateUserInContext } = useUser();
  const pending = useUpdateUserPending();

  const fields = [
    { label: 'New Password', type: 'password', name: 'password', required: true, value: '' },
    { label: 'Confirm Password', type: 'password', name: 'confirm', required: true, value: '' },
  ];

  const validation = Yup.object({
    confirm: Yup.string()
      .when('password', {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          'Passwords don\'t match'
        )
      })
  });

  const handleSetPassword = ({ password }) => {
    if(pending) return;
    dispatch(updateUser({ password }))
      .then((user) => {
        updateUserInContext(user);
        Notify.default(`Successfully updated your password.`);
        history.push('/profile');
      })
      .catch((err) => Notify.error(format.error(err)));
  };

  return (
    <LayoutCollapsibleHeader theme='light'>
      <div className={CSS.dashboard}>
        <div className={CSS.content}>
          <div className={CSS.headliner}>
            <Link to='/profile' className={CSS.back_icon}>
              <Icon library='navigation' symbol='Arrow-left' size='28px' />
            </Link>
            <h1>Change Password</h1>
          </div>
          <section>
            <div className={CSS.section_header}>
              <div className={CSS.left_column}>
                <h4>Change password</h4>
                <p>Enter and confirm your new password below.</p>
              </div>
            </div>
            <div className={CSS.form_fields}>
              <DynamicForm
                id='password-form'
                fields={fields}
                validation={validation}
                onSubmit={handleSetPassword}
                disabled={pending}
                formStyle='underline'
              />
            </div>
            <div className={classNames(CSS.form_actions,'mt-40')}>
              <label
                className={classNames('btn-primary','inline-flex',{'loading':pending})}
                htmlFor='password-form-submit'
                role="button"
                disabled={pending}
              >
                Change password
              </label>
            </div>
          </section>
        </div>
      </div>
    </LayoutCollapsibleHeader>
  );
}
