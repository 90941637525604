import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_MOVING_COMPANY_ADS',
  request: (id) => request.get(`/mover-booking-tasks/${id}/moving-company-ads`).then(r => r.data.data),
  selector: (state, id) => get(state, `movingCompanyAds.${id}`),
  reducers: {
    success: (state, { response: movingCompanyAds, params: [id] }) => {
      return {
        ...state,
        movingCompanyAds: {
          ...state.movingCompanyAds,
          [id]: movingCompanyAds,
        },
      }
    }
  }
}

export const {
  action,
  reducer,
  useResource: useMovingCompanyAds,
  usePending: useMovingCompanyAdsPending,
} = generate.redux(creator);

export const initialState = {
  movingCompanyAds: {},
};
