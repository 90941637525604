import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';

import { useNotify, format } from '@moved/services';
import { Button, CompleteCard, FieldList } from '@moved/ui';

import { ScreenTitle, CompleteScreenActions } from '../../shared';
import { Snippet } from '../../../../snippets';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';

const statusMap = {
  Scheduled: {
    status: 'pending',
    title: 'In Progress',
    flavor: 'Thanks for starting your move-in inspection. Once it is confirmed completed in HappyCo, this task will update automatically within 24 hours.',
  },
  Draft: {
    status: 'pending',
    title: 'In Progress',
    flavor: 'Thanks for starting your move-in inspection. Once it is confirmed completed in HappyCo, this task will update automatically within 24 hours.',
  },
  Expired: {
    status: 'cancelled',
    title: 'Link Expired',
    flavor: 'The link to complete your move-in inspection is no longer valid. Please reach out to your property to complete the inspection.',
  },
  Complete: {
    status: 'approved',
    title: 'Inspection Complete',
    flavor: 'Thanks for completing your move-in inspection!',
  },
}

export const Summary = ({ screen, task, origin, changeScreen }) => {
  // HOOKS
  const { id } = useParams();
  const dispatch = useDispatch();
  const Notify = useNotify();
  const taskDetails = task.selectors.useTaskable(id);
  const inspection = task.helpers.getActiveRequest(taskDetails);

  const {
    status,
    title,
    flavor,
  } = statusMap[inspection.status] || {};

  const linkToHappyco = () => {
    dispatch(task.actions.click(get(inspection,'id')))
      .then(link => window.open(decodeURIComponent(link)))
      .catch(err => Notify.error(format.error(err)));
  };

  const attributes = [
    ...task.helpers.getSummaryFields(taskDetails),
    {
      value: (
        <div className={taskCSS.start_over}>
          { status !== 'approved' && (
            <Button
              text='Continue Inspection'
              size='small'
              color='tertiary'
              onClick={linkToHappyco}
            />
          )}
          <Button
            text='Review Instructions'
            size='small'
            color='secondary'
            onClick={() => changeScreen('back')}
          />
        </div>
      ),
    },
  ].filter(v=>v);

  return (
    <div className={taskCSS.holder}>

      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} />
        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
            <CompleteCard
              status={status}
              title={(
                <Snippet tag={`tasks.${task.task_type}.summary.${status}.title`}>
                  {title}
                </Snippet>
              )}
              flavor={(
                <Snippet tag={`tasks.${task.task_type}.summary.${status}.flavor`}>
                  {flavor}
                </Snippet>
              )}
            >
              <FieldList list={attributes} />
            </CompleteCard>
          </div>
        </div>
        <CompleteScreenActions origin={origin} />
      </div>

    </div>
  )


};
