import React from 'react';

import { Accordion } from '@moved/ui';

import { InsuranceDisclaimer } from '../../book-movers/components/InsuranceDisclaimer';
import { CancellationDisclaimer } from '../../book-movers/components/CancellationDisclaimer';

import CSS from '../../book-movers/components/styles/QuoteFAQs.module.scss';

const sections = [
  {
    label: 'Who is my moving company?',
    content: (
      <div className={CSS.answer}>
        <p>Your move will be matched with a top-tier company in Moved’s vetted network of moving companies. You’ll receive an email introducing you to the moving company shortly after you’ve booked the move.</p>
      </div>
    ),
  },
  {
    label: 'When will the movers show up?',
    content: (
      <div className={CSS.answer}>
        <p>
          Your time preference is shared with the moving company, who will provide you with a specific arrival window.
          For example, if the provided arrival window is 8AM - 11AM, the movers can arrive <em>as early</em> as 8AM (please be ready for them) or <em>as late</em> as 11AM (make sure to account for enough time to complete the move according to any reservations, traffic, etc.)
        </p>
      </div>
    ),
  },
  {
    label: 'What if I need to cancel or reschedule?',
    content: (
      <div className={CSS.answer}>
        <p>
          <CancellationDisclaimer />
        </p>
      </div>
    ),
  },
  {
    label: 'Is my move insured?',
    content: (
      <div className={CSS.answer}>
        <p>
          <InsuranceDisclaimer />
        </p>
      </div>
    ),
  },
  {
    label: 'What is additional moving insurance?',
    content: (
      <div className={CSS.answer}>
        <p>
          Additional moving insurance can be purchased to cover the cost of replacing your belongings if they are damaged during the move. To determine if you need to purchase it, <a href="https://moved.com/2018/05/03/2018-05-03-do-you-need-moving-insurance/" target="_blank" rel="noopener noreferrer">this blog post</a> may be helpful. We recommend getting additional moving insurance for any high-value items. You can obtain this extra coverage by&nbsp;
          <a href="https://go.moved.com/insurance" target="_blank" rel="noopener noreferrer">submitting a quote request</a>.
        </p>
      </div>
    ),
  },
];

export const QuoteFAQs = (props) => (
  <div className={CSS.questions}>
    <h3 className={'mb-20'}>Questions?</h3>
    <Accordion panels={sections}/>
  </div>
);
