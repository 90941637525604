import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from "react-router-dom";
import * as Yup from 'yup';
import classNames from 'classnames';
import { get } from 'lodash';

import { useModal, useNotify, format } from '@moved/services';
import { Icon, StyledForm, CardSetupField, DynamicField } from '@moved/ui';

import { ScreenTitle } from '../../shared';

import { BookBreakdown } from './BookBreakdown';
import { LastMinuteNotice } from './LastMinuteNotice';
import { PastDeadlineWarning } from './PastDeadlineWarning';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import payCSS from './styles/BookPayment.module.scss';

export const BookPayment = ({ screen, changeScreen, task, taskBaseRoute }) => {
  const { id, moveId } = useParams();
  const Modal = useModal();
  const Notify = useNotify();
  const history = useHistory();
  const stripeCard = useRef(); // to be assigned to CardSetupField component
  const dispatch = useDispatch();

  const taskDetails = task.selectors.useTaskable(id);
  const { discounts = [] } = task.selectors.useMove(moveId);
  const { mover_booking } = taskDetails;

  // STATE
  const [pending, setPending] = useState(false);

  // local data
  const validation = Yup.object({
    nameOnCard: Yup.string().required('Required'),
    mover_booking: Yup.object({
      contact_phone: Yup.string().required('Required'),
    }),
  });
  const initialValues = {
    nameOnCard: '',
    mover_booking: {
      id: get(taskDetails,'mover_booking.id'),
      contact_phone: get(taskDetails,'mover_booking.contact_phone') || '',
    },
  };
  const phoneField = {
    label: 'Phone Number',
    name: 'mover_booking.contact_phone',
    type: 'tel',
  };

  const handleSubmit = (formData) => {
    if(pending || !stripeCard.current) return;
    if(!task.canBookDate(get(mover_booking,'requested_pickup_date'))) {
      return Modal.open(<PastDeadlineWarning />, {
        hideCloseButton: true,
        onClose:() => history.push(`${taskBaseRoute}${task.getScreenRoute({slug:'date'},taskDetails)}`),
      })
    }
    setPending(true);
    stripeCard.current.confirmCard()
      .then(({setupIntent}) => {
        if(!setupIntent) return; // stripe input card validation error
        return dispatch(task.actions.update(id, { mover_booking: formData.mover_booking }))
          .then(() => dispatch(task.actions.bookMoverBooking(id, get(mover_booking,'id'), { stripe_payment_method_id: setupIntent.payment_method })))
          .then((resp) => changeScreen('next'));
      })
      .catch(error => {
        Notify.error(
          format.error(error,false,format.stripeError), // map stripe errors
          { autoHideDuration:6000 }, // slightly longer than normal display times
        );
      })
      .finally(() => setPending(false));
  };

  return (
    <div className={taskCSS.holder}>

      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} />

        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>

            <div className={payCSS.content}>

              <div className={payCSS.summary}>

                <LastMinuteNotice moverBooking={mover_booking} />

                <div className={payCSS.form_content}>

                  <StyledForm
                    id="payment-form"
                    onSubmit={handleSubmit}
                    formStyle={'underline'}
                    initialValues={initialValues}
                    validation={validation}
                    disabled={pending}
                  >
                    {form => (<>
                      <DynamicField input={{ type: 'title', label: 'Card details', }} />
                      <DynamicField input={{ type: 'hidden', name: 'mover_booking.id', }} />
                      <CardSetupField form={form} ref={stripeCard} />
                      <DynamicField input={{
                        name: 'ui.contact_blurb',
                        content: (
                          <>
                          <h3 className={payCSS.contact_title}>Contact info</h3>
                          <p>The moving company needs to have this on file so the crew can contact you on the day of the move.</p>
                          </>
                        ),
                        type: 'content',
                      }} />
                      <DynamicField form={form} input={phoneField} formStyle={'underline'} />
                    </>)}
                  </StyledForm>
                </div>

                <div className={taskCSS.navigate}>
                  <div className={payCSS.navigate_next}>

                    <div className={payCSS.stripe}>
                      <Icon symbol={'Lock'} size={'14px'} className={payCSS.lock} />
                      <span className={payCSS.stripe_text}>We’ve partnered with <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">Stripe</a> to ensure secure payment.</span>
                    </div>

                    <label
                      id='payment-form-button'
                      htmlFor="payment-form-submit"
                      tabIndex="0"
                      role="button"
                      className={classNames('btn-primary btn--full',{'loading':pending})}
                      disabled={pending}
                    >
                      Authorize card
                    </label>

                  </div>
                </div>

              </div>

              <div className={payCSS.break}>
                <BookBreakdown
                  taskDetails={taskDetails}
                  discounts={discounts}
                />
              </div>

            </div> {/* payCSS.content */}
          </div> {/* taskCSS.folder */}

        </div>

      </div>

    </div>
  );
}
