import React from 'react';
import { useParams } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import classNames from 'classnames';

// import { useNotify, useUser, format } from '@moved/services';
// import { Button, Card } from '@moved/ui';

import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
} from '../../shared';

import CSS from './styles/PublicGridScreen.module.scss';

export const PublicGridScreen = ({ screen, changeScreen, task }) => {

  // TODO: implement an iframe message listener (like epremium) to react
  // when the iframe flow is complete:
  //    - recieve the confirmation number
  //    - update the request with the confirmation number
  //    - submit the request
  //    - advance to the next screen

  // should we have a way of manually entering the confirmation number
  // in case the message mechanism fails or the customer purchased outside
  // of this flow?
  const { moveId } = useParams()
  const move = task.selectors.useMove(moveId);
  const activeMoveStep = task.selectors.useActiveMoveStep();
  const building = activeMoveStep?.building;

  return (
    <ScreenLayout className={CSS.wrapper}>
      <ScreenTitle screen={screen} />
      <ScreenContent screen={screen} className={CSS.container}>
        <iframe
          // TODO: double check the shortCode = `moved${building.id}`
          src={`https://staging.onepublicgrid.com/move-in?shortCode=moved${building?.id}&guid=${move?.guid}`}
          height='100%'
          width='100%'
          style={{border:'none', flex: '1 1 auto'}}
          title='Public Grid Registration Flow'
        />
      </ScreenContent>
    </ScreenLayout>
  );
};
