import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import classNames from 'classnames';
import { get, merge } from 'lodash';

import { useNotify } from '@moved/services';

import { ResidentContentRenderer } from '../../../../common';
import { ScreenTitle } from '../../shared';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/Orientation.module.scss';

export const OrientationScreens = ({ screen, changeScreen, task }) => {
  // hooks
  const { id } = useParams();
  const Notify = useNotify();
  const dispatch = useDispatch();
  const completePending = task.selectors.useCompletePending();
  const createPending = task.selectors.useCreateOrientationRequestPending();
  const taskDetails = task.selectors.useTaskable(id);

  const pending = completePending || createPending;

  const _handleNext = () => {
    const data = {
      [screen.slug]: true,
      status: 'completed',
    }
    const screens = get(task,'flow',[]).map(screen => screen.slug);
    const activeRequest = get(taskDetails,'requests[0]');

    // Create request if none exists
    if(!activeRequest) {
      if(screens[screens.length-2] === screen.slug) {
        return dispatch(task.actions.createRequest(id))
          .then(resp => dispatch(task.actions.complete(id, data)))
          .then(resp => dispatch(task.actions.submitRequest(resp.requests[0].id)))
          .then(() => changeScreen('next'))
          .catch(error => Notify.error());
      } else {
        return dispatch(task.actions.createRequest(id))
          .then(resp => dispatch(task.actions.complete(id, data)))
          .then(() => changeScreen('next'))
          .catch(error => Notify.error());
      }
    }
    // Submit most recent request on last screen
    // if status is 'draft'
    else if(screens[screens.length-2] === screen.slug && activeRequest.status === 'draft') {
      return dispatch(task.actions.complete(id, data))
        .then(resp => dispatch(task.actions.submitRequest(activeRequest.id)))
        .then(() => changeScreen('next'))
        .catch(error => Notify.error());
    }
    // normal screen submit
    else {
      return dispatch(task.actions.complete(id, data))
        .then(() => changeScreen('next'))
        .catch(error => Notify.error());
    }
  };

  return (
    <div className={taskCSS.holder}>
      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} />
        <div className={taskCSS.content}>
          <div className={CSS.sizer}>
            <div className={CSS.container}>
              <ResidentContentRenderer content={merge({id:screen.id},screen.content)} />
            </div>
            {screen.side_column_image_url && (
              <div className={CSS.side_column} style={{ backgroundImage: `url(${screen.side_column_image_url})`}} />
            )}
          </div>
        </div>
        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            <button
              id='orientation_screen_button'
              type='button'
              className={classNames('btn-primary', 'btn--full', {'loading': pending})}
              onClick={_handleNext}
            >{screen.cta_text}</button>
          </div>
        </div>
      </div>
    </div>
  );

};
