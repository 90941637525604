import React from 'react';
import classNames from 'classnames';

import { hooks } from '@moved/services';
import { ScrollMenu, ScrollMenuItem } from '@moved/ui';

import CSS from './styles/Picker.module.scss';

const MobileSlots = ({ times, onSelect }) => times.map((time, index) => (
  <div
    key={time.id}
    className={classNames(CSS.option_content, CSS.option_time, CSS.option_mobile, {
      [CSS.option_active]: time.isSelected,
      [CSS.option_disabled]: time.isDisabled,
    })}
    onClick={() => onSelect(time)}>
    { time.content }
  </div>
));

export const TimePicker = ({ times, onSelect, className }) => {
  const isMobile = hooks.useMobile();

  return (
    <div className={classNames(CSS.holder,CSS.time_picker,className)}>

      <div className={CSS.header}>
        <span>Pick a time</span>
      </div>

      { (!Array.isArray(times) || !times.length) ? (
        <p><em>We apologize, there is no availability on this date, choose another date from the options above.</em></p>
      ) : (
        isMobile ? (
          <MobileSlots times={times} onSelect={onSelect} />
        ) : (
          <ScrollMenu>
            { times.map((time, index) => {
              return (
                <ScrollMenuItem
                  key={time.id}
                  itemId={time.id}
                  onSelect={() => onSelect(time)}
                  isSelected={time.isSelected}
                  isDisabled={time.isDisabled}
                >
                  { time.content }
                </ScrollMenuItem>
              )
            })}
          </ScrollMenu>
        )
      )}

    </div>
  );
}
