/*
*   Component that renders a link to the NEXT TASK in the current active move
*   *MUST* be used within a task flow screen where it can be determined from
*   params which task is currently active and find the next appropriate
*   task from the activeMove task list
*/
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom'
import classNames from 'classnames';

import { useActiveMoveStep } from '../../../../dashboard';
import { getMove } from '../../../../moves/actions';
import { useActiveTask } from '../../../contexts/TaskContext';

import { ScreenActions } from './ScreenActions';

export const CompleteScreenActions = ({className, origin}) => {
  const { moveId } = useParams();
  const dispatch = useDispatch();
  const activeMoveStep = useActiveMoveStep();
  const { activeTask } = useActiveTask();

  // force refresh of move since task complete status may have changed.
  useEffect(() => {
    dispatch(getMove(moveId))
  },[moveId,dispatch]);

  // filter task list to be only tasks with the same requirement as the active task
  const filteredMoveTasks = (activeMoveStep?.move_tasks ?? []).filter(task => task.requirement === activeTask.requirement);
  // find current task in moveTasks
  const currentTaskIndex = filteredMoveTasks.findIndex(({ id }) => id === activeTask.id);
  // create array of only tasks that follow the current task
  const trailingTasks = currentTaskIndex !== -1 ? filteredMoveTasks.slice(currentTaskIndex + 1) : [];
  // find the next task that is not locked or complete
  const nextAvailableTask = trailingTasks.find(({status}) => !['locked','complete'].includes(status));

  return (
    <ScreenActions className={className}>
      { nextAvailableTask && (
        <Link
          to={`${origin}/tasks/${nextAvailableTask.id}/${nextAvailableTask.task_type}`}
          task={nextAvailableTask}
          className={classNames('btn-green-dark')}
        >
          { nextAvailableTask.title }
        </Link>
      )}
      <Link
        to={origin}
        className={classNames(nextAvailableTask ? 'btn-ghost' : 'btn-green-dark')}
      >
        Back to Dashboard
      </Link>
    </ScreenActions>
  );
};
