import React from 'react';
import { Link } from "react-router-dom";
import classNames from 'classnames';
import { get } from 'lodash';

import { hooks, useUser } from '@moved/services';
import { Icon } from '@moved/ui';
import { LogoBlock } from '@moved/product';

import { SnippetEditor } from '../../snippets';

import { useActiveTask } from '../contexts/TaskContext';

import CSS from './styles/TaskLayout.module.scss';

export const TaskLayout = ({ flowProgress, origin, children }) => {
  const isMobile = hooks.useMobile();
  const { isProxy } = useUser();

  const { activeTaskDefinition } = useActiveTask();
  const activeMoveStep = activeTaskDefinition.selectors.useActiveMoveStep();
  const building = activeTaskDefinition.selectors.useBuilding(get(activeMoveStep,'building.slug'));
  const buildingName = get(building,'settings.display_name') || get(building,'name');

  return (
    <div className={CSS.layout}>

      <div className={CSS.sidebar}>
        <div className={CSS.sidebarContent}>

          { !isMobile && (
            building ? (
              <Link to={origin}>
                <img src={building.logo_url} alt={buildingName} className={CSS.building_logo} />
              </Link>
            ) : (
              <LogoBlock redirect={origin}/>
            )
          )}

          <div className={CSS.navigation}>
            { flowProgress }
          </div>

          { !isMobile && building && (
            <LogoBlock redirect={origin} className={CSS.moved_logo}/>
          )}

        </div>
      </div>

      <div className={CSS.content}>
        { children }
      </div>

      { !isMobile && (
        <div className={classNames(CSS.actions,'stackHorizontal-12')}>

          { isProxy && (
            <SnippetEditor className={CSS.button}>
              <Icon symbol={'Pen-tool-vector'} library={'design'} size={'24px'} />
            </SnippetEditor>
          )}

          <Link to={origin} className={CSS.button}>
            <Icon symbol={'Close'} library={'navigation'} size={'24px'} />
          </Link>

        </div>
      )}
    </div>
  );
};
