import * as screenComponents from './components';
import { get } from 'lodash';

export const screens = {

  orientation: {
    component: screenComponents.OrientationScreens,
    maxWidth: 800,
  },

  'summary': {
    slug: 'complete',
    label: "Summary",
    component: screenComponents.Complete,
    maxWidth: 800,
    backRestricted: (data) => get(data,'requests[0].status') === 'rejected',
  },

};
