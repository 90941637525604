import React from 'react';
import { useParams } from 'react-router-dom'
import classNames from 'classnames';

import { Icon } from '@moved/ui';

import { useActiveTask } from '../contexts/TaskContext';

import CSS from './styles/TrainMobile.module.scss';

export const TrainMobile = ({ flow, activeScreenIndex=0, close, back }) => {
  const { id } = useParams();
  const { activeTaskDefinition } = useActiveTask();
  const taskDetails = activeTaskDefinition.selectors.useTaskable(id);

  // don't attempt to render the train until the details are loaded
  if(!taskDetails) return null;

  const percentage = ((activeScreenIndex+1)/flow.length)*100;

  return (
    <div className={CSS.train}>

      <div onClick={back} className={CSS.nav}>
        <Icon symbol='Chevron-left' library='navigation' size='24px' />
      </div>

      <div className={CSS.station}>
        <div
          className={classNames(CSS.rail, { [CSS.complete]: (percentage>=100) })}
          style={{ width: `${percentage}%`, }}
        />
      </div>

      <div onClick={close} className={CSS.nav}>
        <Icon symbol='Close' library='navigation' size='24px' />
      </div>

    </div>
  );
};
