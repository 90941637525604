import React from 'react';
import { get } from 'lodash';

import { format } from '@moved/services';

import coupCSS from './styles/BookCoupon.module.scss';

export const BookCoupon = ({ discount }) => (
  <div className={coupCSS.coupon}>
    <div className={coupCSS.coupon_text}>
      <span className={coupCSS.coupon_title}>Coupon</span>
      <span>
        Courtesy of {get(discount, 'sponsor.is_partner') ? `${get(discount, 'sponsor.name')}, in partnership with Moved` : 'Moved'}
      </span>
    </div>
    <div className={coupCSS.coupon_amount}>
      <span>
        {(function() {
          switch(discount.type) {
            case 'cents':
              return format.currency(discount.cents);
            case 'percent':
              return format.percent(discount.percent);
            default:
              return null;
          };
        })()}
      </span>
    </div>
  </div>
);
