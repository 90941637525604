import { get, merge } from 'lodash';

import { format } from '@moved/services';
import { keys as helpers } from '@moved/product';

import { BaseTask } from '../../components/BaseTask';

import * as actions from './actions';
import * as selectors from './actions/selectors';
import { screens } from './screens';

export class Keys extends BaseTask {
  constructor() {
    super({
      screens: screens,
      actions: actions,
      selectors: {
        useTaskable: selectors.useKeyPickupTask,
        useTaskablePending: selectors.useKeyPickupTaskPending,
        ...selectors,
      }
    });
  }
  static label = 'Key Pick Up';
  static slug = 'keys';
  static icon = 'Key';
  static lockedMessage = 'Complete the tasks above before scheduling your key pick up time.';
  static helpers = helpers;
  static config = {
    supportsArtifacts: true,
    supportsOnHoldArtifact: true,
  };

  /**
   * flow builder documentation:
   *
   * there are up to 5 screens total:
   * PICKUP: who will pick up your keys? self or roommate
   * ROOMMATES: select other roommates to include on this appointment
   * OPTION: how are you planning to pick up your keys? amazon or in-person
   * DATE: pickup date - day/time picker
   * SUMMARY: completeCard component
   *
   * SINGLE TENANT FLOW
   * - lease can be null or can be populated, either way this step should be hidden if there's only a single tenant
   * - if `calendar_options` has a length of 1 or more, display the OPTION screen
   * - DATE & SUMMARY are mandatory screens
   *
   * MULTI-TENANT FLOW
   * - lease should always be populated
   * - lease.active_tenant should be the current user
   * - lease.roommates should be populated with list of tenants
   * - if the `responsibility` key exists, whether it's null, 'self', or 'roommate', always display PICKUP screen
   *
  */
  _flowBuilder(taskData) {
    const activeRequest = this.helpers.getActiveRequest(taskData);
    const hasCalendarOptions = get(taskData, 'building_calendar_options.length', 0) > 1;
    const selectDateScreen = (activeRequest && get(activeRequest,'building_calendar.booking_type') === 'all-day') ? 'select-date-express' : 'select-date';

    const hasMultipleTenants = get(taskData,'roommate_keys.length',0) > 0;
    const isRoommateResponsible = hasMultipleTenants &&
      get(taskData,'current_tenant_key.key_pickup_appointment_id') &&
      get(taskData,'responsibility') === 'roommate';
    const hasRoommateWithAppointment = hasMultipleTenants && get(taskData,'roommate_appointments.length', 0) > 0;
    const hasUnassignedRoommate = hasMultipleTenants && get(taskData,'roommate_keys',[]).find(key => !get(key,'key_pickup_appointment_id'));

    // Do not show select appointment or select roommmates screen if partner setting is disabled
    const keyPickupByRoommateEnabled = get(taskData, 'key_pickup_by_roommate_enabled')

    const flow = [
      ((isRoommateResponsible || hasRoommateWithAppointment) && keyPickupByRoommateEnabled) &&
        this.screens['select-appointment'],
      !isRoommateResponsible && hasUnassignedRoommate && keyPickupByRoommateEnabled &&
        this.screens['select-roommates'],
      !isRoommateResponsible && hasCalendarOptions &&
        this.screens['select-calendar'],
      !isRoommateResponsible &&
        this.screens[selectDateScreen],
      this.screens['summary'],
    ].filter(v => v);

    return flow;
  }

  getStatusCopy(request, taskDetails) {
    const defaultCopy = super.getStatusCopy(request);
    const requiresApproval = taskDetails?.requires_approval;
    let requestCopy = {};
    switch(request.status) {
      case 'rejected':
        requestCopy = {
          summary: {
            flavor: 'The request below was declined. Please submit a new key pick up appointment request.',
          },
        };
        break;
      case 'pending-approval':
        requestCopy = {
          summary: {
            flavor: 'Property Management has been notified of your submitted key pick up request. You will be notified when your request is reviewed.',
          },
          history: {
            title: `Key pick up appointment request pending approval.`,
          },
        };
        break;
      case 'approved':
        requestCopy = {
          summary: {
            flavor: (
              <>
                { taskDetails?.responsibility === 'roommate' ? (
                  <p className='mt-10'>
                    We confirm that we will hand your keys over to { format.fullname(get(taskDetails,'appointment.tenant',{})) }.
                  </p>
                ) : (
                  <p>
                    { !taskDetails ? 'Your key pick up appointment has been taken care of.' :
                      requiresApproval ? 'Your key pick up appointment request has been approved.' :
                      'Your key pick up appointment request has been submitted. Your property will contact you if there are any issues.'
                    }
                  </p>
                )}
              </>
            )
          },
          history: {
            title: `Current appointment`,
            onHoldDisclaimer: `This is your currently active appointment. We won't cancel it until a new one is approved.`
          }
        };
        break;
      default:
        requestCopy = {};
    }
    return merge({},defaultCopy,requestCopy);
  }

  /* START OVERRIDES */
  getRecommendedScreen(taskData) {
    // if already approved and no new draft request show summary screen
    const activeRequest = this.helpers.getActiveRequest(taskData);
    if (activeRequest && activeRequest.status !== 'draft') return this.screens['summary'];
    return super.getRecommendedScreen(taskData);
  }
  /* END OVERRIDES */

  /* TASK SPECIFIC */
  /* END TASK SPECIFIC */

}
