import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { get } from 'lodash';

import { LoaderOverlay } from '@moved/ui';

import { useActiveMoveStep } from '../../dashboard';
import { getDashboardRoute } from '../../dashboard/helpers';
import { taskDefinitions } from '../types';

export const LegacyRouteRedirect = () => {
  // hooks
  const { moveId, stepId, taskType, taskDetailsId } = useParams();
  const history = useHistory();
  const activeMoveStep = useActiveMoveStep(stepId);
  const dashboardRoute = getDashboardRoute(moveId,stepId);

  useEffect(() => {
    if(activeMoveStep) {
      const activeTask = get(activeMoveStep,'move_tasks',[])
        .find(({ task_details_id, task_type }) => {
          return taskDetailsId ? task_details_id === parseInt(taskDetailsId) : taskType === task_type;
        });
      const TaskType = activeTask && taskDefinitions[activeTask.task_type];
      const activeTaskDefinition = TaskType && new TaskType();
      const activeTaskBaseRoute = `${dashboardRoute}${activeTaskDefinition.getBaseRoute(activeTask.id)}`;

      if(activeTask && activeTaskBaseRoute) history.push(activeTaskBaseRoute);
      else history.push(dashboardRoute);
    }
  },[activeMoveStep]); // eslint-disable-line

  return <LoaderOverlay/>;

};
