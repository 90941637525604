import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import classNames from 'classnames';

import { useOptionalControl } from '../useOptionalControl';
import { Checkbox } from './Checkbox';

export const CheckboxList = ({
  name,
  options=[],
  value,
  isControlled,
  disabled,
  error,
  onChange=noop,
  className,
}) => {
  const [selected, setSelected] = useOptionalControl(value ?? [], isControlled);
  const handleChange = (update) => {
    if(disabled) return;
    const newSelection = [
      ...selected.filter(name => name !== update.name),
      (update.value && update.name),
    ].filter(v=>v);
    setSelected(newSelection);
    onChange({[name]:newSelection});
  };

  return (
    <div className={classNames('stackVertical-12', className)}>
      { options.map(option => (
        <Checkbox
          key={option.value}
          {...option}
          name={option.value}
          value={selected.includes(option.value)}
          isControlled={true}
          disabled={disabled}
          onChange={({[option.value]:isChecked}) => handleChange({name:option.value,value:isChecked})}
        />
      ))}
      { error && (
        <div className='labelS contentError'>{ error }</div>
      )}
    </div>
  );
};

CheckboxList.propTypes = {
  /** Name to use for the form input (shared for this checkbox group) */
  name: PropTypes.string.isRequired,
  /** List of options for individual checkboxes in this group */
  options: PropTypes.arrayOf(PropTypes.shape({
    /** Value to use for this checkbox input */
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    /** Label text for the input */
    label: PropTypes.node.isRequired,
    /** Second line of text */
    description: PropTypes.node,
    /** class name to add to the checkbox component */
    className: PropTypes.string
  })),
  /** Checkbox selected value (only initial value if not controlled) */
  value: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  /** Flag to make the input a controlled input */
  isControlled: PropTypes.bool,
  /** Flag to disable the input */
  disabled: PropTypes.bool,
  /** Error to display on the input */
  error: PropTypes.string,
  /** onChange handler function */
  onChange: PropTypes.func,
  /** (optional) class name to add to the checkbox group component */
  className: PropTypes.string
};
