import React from 'react';
import { useParams } from "react-router-dom";

import { CreditCardLogo } from '@moved/ui';

import { ScreenTitle } from '../../shared';
import { LastMinuteNotice } from './LastMinuteNotice';
import { BookBreakdown } from './BookBreakdown';
import { SummaryFields } from './SummaryFields';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import sumCSS from './styles/MoveSummary.module.scss';

export const MoveSummary = ({ screen, changeScreen, task }) => {

  const { id, moveId } = useParams();

  const taskDetails = task.selectors.useTaskable(id);
  const { discounts = [] } = task.selectors.useMove(moveId);

  const _handleSubmit = (e) => {
    e.preventDefault();
    return changeScreen('next');
  };

  return (
    <div className={taskCSS.holder}>

      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} />
        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>

            <div className={sumCSS.content}>

              <div className={sumCSS.summary}>

                <LastMinuteNotice moverBooking={taskDetails?.mover_booking} />

                <div className={sumCSS.info_content}>
                  <SummaryFields taskDetails={taskDetails} />
                </div>
              </div>

              <BookBreakdown
                taskDetails={taskDetails}
                discounts={discounts}
              />

            </div> {/* sumCSS.content */}
          </div> {/* taskCSS.folder */}

        </div>

        <div className={taskCSS.navigate}>
          <div className={sumCSS.navigate_next}>

            <div className='stackHorizontal gap-8 marginBottom-20'>
              <CreditCardLogo symbol={'visa'} />
              <CreditCardLogo symbol={'mastercard'} />
              <CreditCardLogo symbol={'amex'} />
              <CreditCardLogo symbol={'apple-pay'} />
              <CreditCardLogo symbol={'discover'} />
            </div>

            <button
              id='quote_form_button'
              className={'btn-primary btn--full'}
              onClick={_handleSubmit}
              type='submit'
            >
              Next
            </button>

          </div>
        </div>

      </div>

    </div>
  );
}
