import { merge } from 'lodash';
import * as create from './create';
import * as get from './get';
import * as update from './update';
import * as reset from './reset';
import * as complete from './complete';

import * as getMovingCompanyAds from './getMovingCompanyAds';

import * as getSpecialList from './getSpecialList';
import * as getPartnerRates from './getPartnerRates';
import * as getSoldOutDates from './getSoldOutDates';
import * as longDistanceEmail from './longDistanceEmail';

import * as createMoverBooking from './createMoverBooking';
import * as bookMoverBooking from './bookMoverBooking';
import * as retryPreauth from './retryPreauth';
import * as retryPayment from './retryPayment';

export const reducers = [
  // shared
  create.reducer,
  get.reducer,
  update.reducer,
  reset.reducer,
  complete.reducer,
  createMoverBooking.reducer,
  bookMoverBooking.reducer,
  getMovingCompanyAds.reducer,
  getPartnerRates.reducer,
  getSoldOutDates.reducer,
  getSpecialList.reducer,
  retryPreauth.reducer,
  retryPayment.reducer,
  longDistanceEmail.reducer,
];

export const initialState = merge(
  get.initialState,
  reset.initialState,
  complete.initialState,
  createMoverBooking.initialState,
  getMovingCompanyAds.initialState,
  getPartnerRates.initialState,
  getSoldOutDates.initialState,
  getSpecialList.initialState,
);
