import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import classNames from 'classnames';
import { get } from 'lodash';
import * as Yup from 'yup';

import { useModal, useNotify, format, hooks } from '@moved/services';
import { DynamicForm, Icon, Notebox } from '@moved/ui';

import { ScreenTitle } from '../../shared';
import { AVBTermsConditionsModal } from './AVBTermsConditionsModal';
import { AVBConsentModal } from './AVBConsentModal';
import { PaymentSummaryCards } from './PaymentSummaryCards';

import check from '../../../images/check.png';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/Payment.module.scss';

// Deposit Payment Screen component
export const DepositPayment = ({ screen, changeScreen, task }) => {
  // hooks
  const { id } = useParams();
  const Modal = useModal();
  const Notify = useNotify();
  const isMobile = hooks.useMobile();
  const dispatch = useDispatch();
  // redux
  const deposit = task.selectors.useTaskable(id);
  const pending = task.selectors.usePaymentPending();
  // state
  const [values, setValues] = useState({ amount: deposit.balance || 0 });

  const paymentOptions = [
    {label:'Full',value:'full'},
    {label:'Other amount',value:'other'},
  ];

  const openTAndCModal = (e) => {
    e.preventDefault();
    Modal.open(<AVBTermsConditionsModal/>);
  };

  const openConsentModal = (e) => {
    e.preventDefault();
    Modal.open(<AVBConsentModal/>);
  };

  const handleSubmit = data => {
    const { confirm_account_number, confirm_routing_number, ui, paymentOption, ...submitData } = data;
    if(paymentOption === 'full') submitData.amount = deposit.balance;
    dispatch(task.actions.payment(id,submitData))
      .then((resp) => changeScreen('next',resp))
      .catch(err => Notify.error());
  };

  const onChange = (values, form) => {
    if(values.paymentOption === 'full' && values.amount !== deposit.balance) {
      form.setFieldValue('amount',deposit.balance,true);
    }
    setValues(values);
  };

  const validation = Yup.object({
    amount: Yup.number()
      .min(1, 'Payment amount is required'),
    bank_account_number: Yup.string()
      .min(4, 'Must be at least 4 characters')
      .max(17, 'Must be no longer than 17 characters'),
    confirm_account_number: Yup.string()
      .when('bank_account_number', {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('bank_account_number')],'Account numbers must match')
      }),
    bank_routing_number: Yup.string()
      .length(9, 'Must be 9 characters'),
    confirm_routing_number: Yup.string()
      .when('bank_routing_number', {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('bank_routing_number')],'Routing numbers must match')
      }),
  });

  const digitsOnly = (e, setFieldValue, name) => {
    e.target.value = e.target.value.replace(/\D/g,'');
  };

  const fields = [
    { name: 'paymentOption', type: 'slideToggle', value: paymentOptions[0].value, options: paymentOptions, required: true,
      toggleProps: {full:true, rectangular:true}},
    { name: 'amount', label: 'Enter amount', type: 'currency', value: deposit.balance || 0, className: CSS.amount_field,
      custom: { hidden: { compare: { field: 'paymentOption' }, boolean: 'eq', value: 'full' }}},
    { name: 'ui.title', type: 'title', label: 'Payment Method'},
    { name: 'bank_account_number', label: 'Account number', type: 'text', value: '', required: true, half: true, className: 'fs-exclude', onChange: digitsOnly },
    { name: 'confirm_account_number', label: 'Confirm account number', type: 'text', value: '', required: true, half: true, className: 'fs-exclude', onChange: digitsOnly },
    { name: 'bank_routing_number', label: 'Routing number', type: 'text', value: '', required: true, half: true, className: 'fs-exclude', onChange: digitsOnly },
    { name: 'confirm_routing_number', label: 'Confirm routing number', type: 'text', value: '', required: true, half: true, className: 'fs-exclude', onChange: digitsOnly },
    !isMobile ?
      { name: 'ui.check', content: (<img src={check} alt="Example Check" width="100%"/>), type: 'content', className: CSS.check } :
      { name: 'ui.summary_card', content: (<><PaymentSummaryCards deposit={deposit} currentAmount={values.amount} /><h3 className={'mt-20'}>Terms & Conditions</h3></>), type: 'content', className: CSS.mobile_summary },
    {
      name: 'accept_terms',
      label: (<span className={CSS.radio_label}>By checking this box, I verify that I have read and agree to the following <span className="faux-link" onClick={openTAndCModal}>Terms and Conditions.</span></span>),
      type: 'checkbox',
      value: false,
      required: 'Must accept the terms and conditions to continue',
    },
    {
      name: 'accept_consent',
      label: (<span className={CSS.radio_label}>I have read and understood this <span className="faux-link" onClick={openConsentModal}>Consent for Electronic Communications</span> and give my consent to electronically receive payment-related communications from Avalon.</span>),
      type: 'checkbox',
      value: false,
      required: 'Must accept the communication agreement to continue',
    },
  ];

  return (
    <div className={taskCSS.holder}>
      <div className={classNames(taskCSS.task,CSS.container)}>
        <ScreenTitle screen={screen}/>
        <div className={classNames(taskCSS.content,CSS.content)}>
          <div className={CSS.wrapper}>
            { task.isDeadlineApproaching(deposit) && (
              <Notebox
                heading={`Don't wait!`}
                body={`Your building requires at least ${get(deposit,'submission_lead_time_in_days')} days before your lease start to process any online payments.`}
                color='orange'
                icon={{ library:'code', symbol:'Warning-2' }}
              />
            )}
            <h3 className={'mt-20 mb-30'}>Payment amount</h3>
            <DynamicForm
              id="payment-form"
              className={CSS.form}
              fields={fields}
              formStyle={'underline'}
              onChange={onChange}
              onSubmit={handleSubmit}
              validation={validation}
              disabled={pending}
            />
          </div>

          {!isMobile && <PaymentSummaryCards deposit={deposit} currentAmount={values.amount} />}

        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>

            <label
              htmlFor="payment-form-submit"
              tabIndex="0"
              role="button"
              className={classNames('btn-green-dark btn--full',{'loading':pending})}
              disabled={pending}
            >
              <Icon symbol={'Lock'} size={'16px'} color={'white'} className="icon" />
              Pay&nbsp;
              { values.amount === deposit.balance ? 'now' : format.currency(values.amount) }
            </label>

          </div>
        </div>


      </div>

    </div>
  );
};
