import { merge } from 'lodash';

import { docSubmission as helpers } from '@moved/product';

import { BaseTask } from '../../components/BaseTask';
import * as actions from './actions';
import * as selectors from './actions/selectors';
import { screens } from './screens';

export class Documents extends BaseTask {

  constructor() {
    super({
      screens: screens,
      actions: actions,
      selectors: {
        useTaskable: selectors.useDocSubmission,
        useTaskablePending: selectors.useDocSubmissionPending,
        ...selectors,
      }
    });
  }

  static label = 'Document Submission';
  static slug = 'document-submission';
  static icon = 'Stamp';
  static helpers = helpers;

  _flowBuilder(taskDetails) {
    return [
      this.screens['document-upload'],
      this.screens['document-complete'],
    ];
  }

  getStatusCopy(request, taskDetails) {
    const defaultCopy = super.getStatusCopy(request);
    const requiresApproval = taskDetails?.settings?.requires_approval;
    let requestCopy = {};
    switch(request.status) {
      case 'rejected':
        requestCopy = {
          summary: {
            flavor: 'Review the requested changes below and submit updated documents.',
          },
        };
        break;
      case 'pending-approval':
        requestCopy = {
          summary: {
            flavor: 'Property Management has been notified of your submitted documents. You will be notified when your submission is reviewed.',
          },
          history: {
            title: 'Document submission pending approval.',
          },
        };
        break;
      case 'approved':
        requestCopy = {
          summary: {
            flavor: requiresApproval ?
              'The documents you submitted have been reviewed and approved.' :
              'Your documents have been submitted.',
          },
        };
        break;
      default:
        requestCopy = {};
    }
    return merge({},defaultCopy,requestCopy);
  }
  /* START OVERRIDES */
  /* END OVERRIDES */

  /* TASK SPECIFIC */
  /* END TASK SPECIFIC */

}
