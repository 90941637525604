import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import moment from 'moment';

import CSS from './styles/HireAHelper.module.scss';

export const HireAHelper = forwardRef(({ date, zip, options={} }, ref) => {

  /*
  * Documentation for the hireahelper widget:
  * https://www.hireahelper.com/affiliates/secure/widgets/
  */
  const libraryNamespace = 'hireahelperAffiliateWidget';
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // register global variable for widget options
    window[`${libraryNamespace}Opts`] = {
      affiliateKey: options.affiliateKey || process.env.REACT_APP_HIRE_A_HELPER_KEY,
      numToShow: options.numToShow || 3,
      advancedOpts: {
        showHeader: false,
        showDatePickerIcon: false,
        afterFullyLoaded: () => {
          // need to kill any styles imported automatically by this library (ugly but effective)
          Array.from(document.getElementsByTagName('link')).forEach(e => {
            if(e.href.includes('hireahelper')) e.parentNode.removeChild(e);
          });
          if(options.afterFullyLoaded) options.afterFullyLoaded();
        }
      },
    };
    // cleanup the global namespace on unmount
    return () => window[`${libraryNamespace}Opts`] = undefined;
  },[]); // eslint-disable-line

  useEffect(() => {
    // reload the results whenever the zip or date change
    if(zip && date && window[libraryNamespace]) window[libraryNamespace].reInit();
    // hacky solution to transition slightly better (2 second loading state)
    setLoading(true);
    const loaderTimer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(loaderTimer);
  },[zip,date]);

  // expose the api functions to the parent via reference
  useImperativeHandle(ref, () => ({
    results: () => window[libraryNamespace].results(),
    init: () => window[libraryNamespace].init(),
    reInit: () => window[libraryNamespace].reInit(),
  }));

  return (
    <div className={classNames(CSS.wrapper, {[CSS.loading]: loading})}>
      <Helmet>
        <script src="https://www.hireahelper.com/affiliate/widgetloaderjs/" type="text/javascript" />
      </Helmet>

      {/* id based hidden inputs for the widget to have access to the react form data */}
      <input id="hhWidgetDateInput" type="hidden" value={moment(date).format('MM/DD/YYYY')} />
      <input id="hhWidgetZipInput" type="hidden" value={zip} />
      <label id="hhSearch" style={{display:'none'}}>Search</label>

      {/* id based container for the widget to inject into */}
      <div id="hhQuoteWidget"></div>

      <div className={CSS.powered_by}>
        Powered by <img className={CSS.logo} src={`${process.env.REACT_APP_S3_ASSET_URL}/service-providers/hireahelper/logo-2color.svg`} alt="Hire A Helper"/>
      </div>

    </div>
  );
});
