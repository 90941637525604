import React from 'react';
import classNames from 'classnames';
import { get, merge } from 'lodash';

import { Icon } from '../../../sondheim/components/Icon';
import { Card } from '../../../sondheim/components/Card';

import CSS from './CompleteCard.module.scss';

const statusDefaults = {
  approved: {
    color: 'green',
    title: "Congrats, you're all set!",
    icon: {
      symbol: 'Done',
      library: 'stockholm',
      color: 'green',
    },
  },
  rejected: {
    color: 'red',
    title: "Declined",
    icon: {
      symbol: 'Warning',
      library: 'stockholm',
      color: 'red',
    },
  },
  'pending-approval': {
    color: 'brown',
    title: "Waiting for approval",
    icon: {
      symbol: 'Time-schedule',
      library: 'stockholm',
      color: 'brown',
    },
  },
  cancelled: {
    color: 'red',
    title: "Canceled",
    icon: {
      symbol: 'Warning',
      library: 'stockholm',
      color: 'red',
    },
  },
  loading: {
    color: 'orange',
    title: (<>Loading status&hellip;</>),
    icon: {
      symbol: 'Info',
      library: 'stockholm',
      color: 'orange',
    },
  },
};

export const CompleteCard = ({ status = 'loading', title, color, icon = {}, flavor, detailsTitle = 'Details', notes, children }) => {
  if (status === 'pending') status = 'pending-approval';

  const defaults = statusDefaults[status]
  const options = merge({}, defaults, { color, title, icon });

  return (
    <Card className='stackVertical-12 marginBottom-48'>
      <div className={classNames(CSS.header_section,CSS[`${options.color}`])}>
        <h2>
          <Icon
            symbol={get(options,'icon.symbol')}
            library={get(options,'icon.library')}
            color={get(options,'icon.color')}
            size={'44px'}
            className={classNames(CSS.icon,CSS[`${get(options,'icon.color')}`])}
          />
          { options.title }
        </h2>
        {flavor && (<div className={CSS.flavor}>{flavor}</div>)}
        {notes && (<p className='txt-center italic'>{notes}</p>)}
      </div>
      <div className={CSS.details_section}>
        { detailsTitle && (<h3 className='labelL contentPrimary'>{detailsTitle}</h3>)}
        { children && (<div className={CSS.details}>{children}</div>)}
      </div>
    </Card>
  );
};
