import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { get, find } from 'lodash';

import { DynamicForm, AnimatedCheckmark, AtomSpinner } from '@moved/ui';
import { useModal, useNotify, format } from '@moved/services';

import { useBuilding } from '../../common/actions/selectors';

import { getSnippets, editSnippets } from '../actions';
import { useSnippets, useSnippetsPending, useEditSnippetsPending } from '../actions/selectors';
import CSS from './styles/SnippetEditorModal.module.scss';

const partnerPrefix = 'prt-';
const buildingPrefix = 'bdg-';

const _formatValues = (values = {}) => {
  let valueLevels = { building: [], partner: [] };
  Object.keys(values).forEach(key => {
    const value = values[key];
    if(value.partner_level) valueLevels.partner.push(value);
    else valueLevels.building.push(value);
  });
  return valueLevels;
};

const _generateFields = (list, values) => {
  const valueLevels = _formatValues(values);

  const _formatFields = (vals,prefix,list) => [{
      label: `${prefix === partnerPrefix ? 'Client' : 'Building'} snippets`,
      type: 'title',
    }].concat(list.map(snip => {
    const value = find(vals, val => val.key === snip.tag) || {};
    return {
      label: snip.description || 'Snippet',
      type: 'text',
      name: `${prefix}${snip.tag.replaceAll('.','|')}`,
      flavor: snip.tag,
      value: value.text,
      required: false,
    };
  }));

  const buildingFields = _formatFields(valueLevels.building, buildingPrefix, list);
  const partnerFields = _formatFields(valueLevels.partner, partnerPrefix, list);

  return buildingFields.concat(partnerFields);
}

export const SnippetEditorModal = ({ list, activeMoveStep }) => {
  // hooks
  const building = useBuilding(get(activeMoveStep,'building.slug'));
  const dispatch = useDispatch();
  const { close } = useModal();
  const Notify = useNotify();
  // state
  const [submitted, setSubmitted] = useState(false);
  // redux
  const snippets = useSnippets();
  // actions
  const pending = useEditSnippetsPending();

  useEffect(() => {
    const keys = list.map(snip => snip.tag).join(',');
    dispatch(getSnippets(building.id, { keys }))
      .catch((error) => Notify.error(format.error(error)))
  },[ building.id, list, dispatch, Notify ]);

  // helper functions
  const handleSubmit = (data) => {
    let massagedData = [];
    Object.keys(data).forEach(key => {

      // Determine partner vs building level with prefix
      const prefix = key.substr(0,4);

      // Format key for submitting
      const keyFormat = key.substr(4).replaceAll('|','.')

      // Push into data array to be submitted
      massagedData.push({
        key: keyFormat,
        text: data[key],
        partner_level: prefix === partnerPrefix,
      });

    });
    dispatch(editSnippets(building.id, massagedData)).then(resp => {
      setTimeout(close, 650);
      setSubmitted(true);
    })
    .catch(error => Notify.error(format.error(error)));
  };

  if(useSnippetsPending()) return (
    <div className={CSS.modal}>
      <AtomSpinner />
    </div>
  );

  return (
    <div className={CSS.modal}>
      { submitted && (<div className={CSS.confirm}><AnimatedCheckmark isChecked={true} fast={true} /></div>) }
      <div className={CSS.title}>
        <h3>Edit page</h3>
      </div>
      <div className={CSS.block_edit_form}>
        <DynamicForm
          fields={_generateFields(list, snippets)}
          formStyle={'underline'}
          id="block-form"
          onSubmit={handleSubmit}
        />
      </div>
      <div className={CSS.buttons}>
        <label
          className={'btn-gray mr-10'}
          disabled={pending}
          onClick={e => close(false)}
        >
          Cancel
        </label>
        <label
          htmlFor="block-form-submit"
          tabIndex="0"
          role="button"
          className={'btn-primary ' + (pending  ? ' loading' : '')}
          disabled={pending}
        >
          Save
        </label>
      </div>
    </div>
  );
}
