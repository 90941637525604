import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_CREATE_MOVER_BOOKING',
  request: (id, data) => request.post(`/mover-booking-tasks/${id}/mover-bookings`, data).then(r => get(r, 'data.data.mover_booking_task')),
  reducers: {
    success: (state, { response: bookingTask }) => {
      return {
        ...state,
        moverBookingTasks: {
          ...state.moverBookingTasks,
          [bookingTask.id]: bookingTask,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useCreateMoverBookingPending,
} = generate.redux(creator);

export const initialState = {
  moverBookings: {},
};
