import React, { useState } from 'react';
import { get } from 'lodash';

import { useModal, addressService } from '@moved/services';

import { DynamicForm } from '../DynamicForm';
import CSS from './AddressModal.module.scss';

const _floorOnChange = (value, setFieldValue, name) => {
  if(value.requireElevator) return setFieldValue('flights_of_stairs','has_elevator',false);
};

const formatAddressData = ({ type, address, unit, floor, flights_of_stairs, deed }, { simple }) => {
  // cleanup stairs/elevator data
  const has_elevator = flights_of_stairs === 'has_elevator' ? true : false
  if(!Number.isInteger(flights_of_stairs)) flights_of_stairs = 0;
  // send back only the appropriate data
  switch(type) {
    case 'storage':
      return { type, address };
    case 'house':
      return simple ? { type, address, deed } : { type, address, flights_of_stairs, deed };
    case 'apartment':
    case 'other':
    default:
      address.unit = unit;
      return simple ? { type, address, deed } : { type, address, floor, flights_of_stairs, deed, has_elevator };
  }
};


export const AddressModal = ({hide, input, field, showDeed }) => {

  const Modal = useModal();
  const [values, setValues] = useState({
    ...field.value,
    type: get(field,'value.type') || 'house',
    unit: get(field,'value.address.unit') || '',
  });
  const { simple } = input;
  const fields = [
    {
      type: 'itemList',
      name: 'type',
      value: get(values,'type'),
      className: CSS.property_type,
      isSquare: true,
      required: 'You must select a property type',
      options: simple ? addressService.simpleTypeOptions : addressService.typeOptions,
    },
    {
      label: 'Address',
      type: 'googlePlace',
      name: 'address',
      value: get(values,'address'),
      required: true,
    },
    ['apartment','other'].includes(get(values,'type')) ? {
      label: 'Unit',
      type: 'text',
      name: 'unit',
      value: get(values,'unit'),
    } : {
      type: 'hidden',
      name: 'unit',
      value: get(values,'unit'),
    },
    // If field is simple, don't add additional fields
    ...(simple ? [] : [
      ['apartment','other'].includes(get(values,'type')) && {
        label: 'Floor',
        type: 'select',
        name: 'floor',
        value: get(values,'floor'),
        required: true,
        half: true,
        options: addressService.floorOptions,
        onChange: _floorOnChange,
      },
      ['apartment','other'].includes(get(values,'type')) && {
        label: 'Stairs/Elevator',
        type: 'select',
        name: 'flights_of_stairs',
        value: get(values,'has_elevator') ? 'has_elevator' : get(values,'flights_of_stairs'),
        required: true,
        half: true,
        options: addressService.stairElevatorOptions,
        readOnly: addressService.floorOptions.filter(f => f.requireElevator).map(f => f.value).includes(get(values,'floor')),
      },
      ['house'].includes(get(values,'type')) && {
        label: 'Stairs',
        type: 'select',
        name: 'flights_of_stairs',
        value: get(field.value,'flights_of_stairs'),
        required: true,
        options: addressService.stairOptions,
      },
    ]),
    showDeed && ['house','apartment','other'].includes(get(values,'type')) && {
      type: 'slideToggle',
      name: 'deed',
      value: get(values,'deed'),
      required: 'You must specify if you own or rent',
      className:  CSS.deed,
      options: addressService.ownershipOptions,
      toggleProps: {
        rectangular: true,
        full: true,
      },
    },
  ].filter(v=>v);

  const handleSubmit = (values) => {
    const addressObject = formatAddressData(values,{simple});
    return Modal.close(addressObject);
  };

  const addSavePending = false; //temp

  return (
    <div className={CSS.content}>
      <h3>{input.label}</h3>
      <DynamicForm
        fields={fields}
        id={`address-modal-form`}
        formStyle={'underline'}
        onChange={values => setValues(values)}
        onSubmit={handleSubmit}
        disabled={addSavePending}
      />
      <div className={CSS.next}>
        <label
          htmlFor="address-modal-form-submit"
          tabIndex="0"
          role="button"
          className={'btn-primary btn--full' + (addSavePending  ? ' loading' : '')}
          disabled={addSavePending}
        >
          Add address
        </label>
      </div>
    </div>
  );
};
