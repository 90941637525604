import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { get } from 'lodash';

import { ConfirmationModal, Button } from '@moved/ui';
import { format, useUser, useModal, useNotify } from '@moved/services';

import { getMove } from '../../../../moves/actions';
import { SummaryScreen } from '../../shared/components/SummaryScreen';

export const Summary = ({ screen, task, origin, taskBaseRoute }) => {
  // HOOKS
  const { id, moveId } = useParams();
  const { user } = useUser();
  const dispatch = useDispatch();
  const history = useHistory();
  const Modal = useModal();
  const Notify = useNotify();
  const docs = task.selectors.useTaskable(id);
  const activeRequest = task.helpers.getActiveRequest(docs);
  const activeRequestIsMyRequest = get(activeRequest,'submitted_by.user_id') === user.id;
  const requiresApproval = get(docs,'settings.requires_approval');

  // Reset handler functions
  const confirmReset = () => {
    if(activeRequest.status === 'rejected') return handleReset();
    else Modal.open(
      <ConfirmationModal
        title='Resubmit updated documents'
        copy={'Are you sure? This will replace your existing document submission.'}
        confirmText='Continue'
        cancelText='Never mind'
        onConfirm={handleReset}
      />,
      { sondheim: true },
    );
  };
  const handleReset = () => {
    return dispatch(task.actions.createRequest(id))
      // refresh the move because reset affects MT on the move
      .then(r => { dispatch(getMove(moveId)); return r; })
      .then((resp) => history.push(`${taskBaseRoute}${task.getStartOverRoute(resp)}`))
      .catch((error) => Notify.error(format.error(error)))
  };

  return (
    <SummaryScreen
      origin={origin}
      screen={screen}
      extraAttributes={[
        activeRequestIsMyRequest && !(activeRequest.status === 'approved' && requiresApproval ) && {
          value: (
            <Button
              size='small'
              color='secondary'
              onClick={confirmReset}
              text='Resubmit updated documents'
              disabled={task.selectors.useCreateDocRequestPending()}
            />
          )
        }
      ].filter(Boolean)}
    />
  );

};
