import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'LANDING_PAGES_GET_TOKEN_DATA',
  request: (token) => request.get(`/adhoc-tokens/${token}`,{ignoreUnauthorized:true}).then(r => get(r,'data.data')),
}

export const {
  action,
  reducer,
} = generate.redux(creator);
