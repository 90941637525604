import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { get, isNil, last, first } from 'lodash';

import { useUser, useNotify, format } from '@moved/services';
import { AtomSpinner, DynamicForm } from '@moved/ui';
import { AuthenticationFormWrapper, AuthenticationForm } from '@moved/product';

import { claimAccount, getPartnerBuildings, resendInvitationEmail } from '../actions';
import { usePartnerBuildings, useResendInvitationEmailPending } from '../actions/selectors';

import { ClaimTokenRecoveryForm } from './ClaimTokenRecoveryForm';

const getDefaultTaskList = (building) => last(get(building,'building_task_lists',[]));

export const SignupForm = ({ building, partner, coupon, onComplete, ...props }) => {
  const dispatch = useDispatch();

  const { updateUser } = useUser();
  const Notify = useNotify();

  const [activeBuilding, setActiveBuilding] = useState(building);
  const [selectedTaskListId, setSelectedTaskListId] = useState(get(getDefaultTaskList(building),'id'));
  const [pending, setPending] = useState();
  const buildings = usePartnerBuildings(get(partner,'slug'));
  const resendPending = useResendInvitationEmailPending();

  useEffect(() => {
    if(partner && !building) dispatch(getPartnerBuildings(get(partner,'slug')))
      .then(buildings => {
        setActiveBuilding(first(buildings));
        setSelectedTaskListId(get(getDefaultTaskList(first(buildings)),'id'));
      });
  },[partner,building,dispatch]);

  const resendInvitation = ({ email }) => {
    if(resendPending) return;
    dispatch(resendInvitationEmail({
      email: email.toLowerCase(),
      building_slug: get(activeBuilding,'slug'),
    }))
      .then(() => Notify.default('If the email address provided was found in our system, an new invitation email has been sent.'))
      .catch(() => Notify.error());
  };

  if(!building && !partner) return (
    <AuthenticationFormWrapper title={props.title} subtitle={props.subtitle} cta='Get Signup Code' pending={resendPending}>
      <ClaimTokenRecoveryForm sendRecovery={resendInvitation} pending={resendPending}/>
    </AuthenticationFormWrapper>
  );

  if(!activeBuilding) return <AtomSpinner/>;

  const buildingOptions = (buildings||[building]).map(building => ({label:get(building,'name'),value:get(building,'id')}));
  const claimTokenRequired = get(activeBuilding,'settings.signup_claim_token_required');
  const directionOptions = get(activeBuilding,'building_task_lists',[]).map(taskList => ({label:taskList.move_step_type.display_name,value:taskList.id})).reverse();
  const showDirectionPicker = !claimTokenRequired && directionOptions.length > 1;

  const buildingFields = [
    {
      label: 'Location',
      type: 'select',
      name: 'building',
      readOnly: !isNil(building),
      value: activeBuilding.id,
      options: buildingOptions,
      onChange: (selected, setFieldValue) => {
        const selectedBuilding = buildings.find(building => building.id === selected.value);
        const selectedTaskListId = get(last(get(selectedBuilding,'building_task_lists',[])),'id');
        setActiveBuilding(selectedBuilding);
        setSelectedTaskListId(selectedTaskListId);
        setFieldValue('building_task_list_id',selectedTaskListId);
      },
    },
    showDirectionPicker && {
      label: 'Direction',
      type: 'slideToggle',
      name: 'building_task_list_id',
      value: first(directionOptions).value,
      options: directionOptions,
      toggleProps: { full: true, rectangular: true },
      onChange: (selected) => {
        setSelectedTaskListId(selected);
      },
    },
  ].filter(v=>v);

  const authenticationFields = [
    { label: 'First name', type: 'text', name: 'firstname', value: '', required: true, half: true },
    { label: 'Last name', type: 'text', name: 'lastname', value: '', required: true, half: true },
    { label: 'Email', type: 'email', name: 'email', value: '', required: true },
    { label: 'Create password', type: 'password', name: 'password', value: '', required: 'Password is required' },
    coupon && { name:'coupon_id',type:'hidden',value:get(coupon,'id') },
  ].filter(v=>v);

  const authenticate = ({ email, ...data }) => {
    if(pending) return;
    setPending(true);
    return dispatch(claimAccount(get(activeBuilding,'id'), {
      email: email.toLowerCase(),
      building_task_list_id: selectedTaskListId,
      ...data,
    }))
      .then((response) => {
        const { user, ...tokenData } = get(response,'login_response');
        updateUser(user, tokenData);
        if(onComplete) onComplete(response);
      })
      .catch(err => {
        setPending(false);
        Notify.error(format.error(err));
      });
  };

  return (
    <AuthenticationFormWrapper
      display={claimTokenRequired ? 'limit' : 'signup'}
      pending={claimTokenRequired ? resendPending : pending}
      cta={claimTokenRequired && 'Get Signup Code'}
      {...props}
    >
      <DynamicForm id="building-form"
        formStyle={'underline'}
        fields={buildingFields}
      />
      { claimTokenRequired ? (
        <ClaimTokenRecoveryForm sendRecovery={resendInvitation} pending={resendPending}/>
      ) : (
        <AuthenticationForm
          fields={authenticationFields}
          authenticate={authenticate}
          pending={pending}
        />
      )}
    </AuthenticationFormWrapper>
  );

};
