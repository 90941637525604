import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom'
import { get } from 'lodash';

import { CompleteCard, FieldList, LoaderOverlay } from '@moved/ui';
import { moveOutDetails } from '@moved/product';

import { Snippet } from '../../../../snippets';
import { ScreenTitle, CompleteScreenActions } from '../../shared';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/Complete.module.scss';

export const Complete = ({ screen, task, origin, taskBaseRoute }) => {

  // HOOKS
  const { id } = useParams();
  const details = task.selectors.useTaskable(id);
  const history = useHistory();
  const dispatch = useDispatch();
  const activeMoveStep = task.selectors.useActiveMoveStep();
  const building = task.selectors.useBuilding(get(activeMoveStep,'building.slug'));

  const options = task.selectors.useMoveOutOptionsList(building.id);
  const optionsPending = task.selectors.useMoveOutOptionsListPending();

  const attributes = [
    ...moveOutDetails.getSummaryFields(details, options),
    {
      value: (
        <div className={CSS.start_over}>
          <button
            type="button"
            className={'btn-gray btn--small'}
            onClick={() => history.push(`${taskBaseRoute}${task.getStartOverRoute(details)}`)}
          >
            Change selection
          </button>
        </div>
      )
    },
  ];

  const flavor = (<Snippet tag={'tasks.move-out-details.summary'} />);

  useEffect(() => {
    dispatch(task.actions.getOptionsList(building.id));
  // eslint-disable-next-line
  },[building]); // only run on mount

  return (
    <>
      { optionsPending && (<LoaderOverlay />)}
      <div className={taskCSS.holder}>
        <div className={taskCSS.task}>
          <ScreenTitle screen={screen} />
          <div className={taskCSS.content}>
            <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>

              <CompleteCard status={details.status || 'approved'} flavor={flavor}>
                <FieldList list={attributes} />
              </CompleteCard>

            </div>
          </div>

          <CompleteScreenActions origin={origin} />

        </div>
      </div>
    </>
  )

};
