import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import classNames from 'classnames';

import { Icon, Button } from '@moved/ui';
import { format, useNotify } from '@moved/services';

import { ResidentContentRenderer } from '../../../../common';

import { ScreenTitle } from '../../shared';
import { AllConnect } from './AllConnect';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/Providers.module.scss';

export const Providers = ({ screen, changeScreen, task, origin }) => {
  // hooks
  const dispatch = useDispatch();
  const Notify = useNotify();
  const { id } = useParams();
  const activeMoveStep = task.selectors.useActiveMoveStep();
  const building = task.selectors.useBuilding(activeMoveStep?.building?.slug);
  const taskDetails = task.selectors.useCableInternetTask(id);
  const submitPending = task.selectors.useSubmitCableInternetTaskPending();
  const [pending, setPending] = useState();
  // interface to map the providers for ease of use
  const providers = get(taskDetails,'building_providers',[]).map(provider => ({
    id: provider.id,
    name: get(provider,'cable_internet_provider.name'),
    logo: get(provider,'cable_internet_provider.logo_url'),
    description: get(provider,'description') || get(provider,'cable_internet_provider.description'),
    ctaText: get(provider,'cta_text') || 'Sign up now',
    ctaUrl: get(provider,'cta_url') || get(provider,'cable_internet_provider.corporate_url'),
    contacts: get(provider,'building_sales_contacts',[]),
  }));

  // convenience variables
  const showAllConnect = get(taskDetails,'settings.allconnect_enabled') &&
    (providers.length < 4); // don't show allconnect if we have 4+ integrated providers
  const address = get(activeMoveStep,'lease.address');

  // CTA handler function
  const selectProvider = (provider) => {
    if(pending) return false;
    setPending(true);
    dispatch(task.actions.getProviderLink(id, get(provider, 'id'), 'direct'))
      .then(link => window.open(decodeURIComponent(link)))
      .catch(err => Notify.error(format.error(err)))
      .finally(() => setPending(false));
  };

  // Screen complete function
  const handleNext = () => {
    if(submitPending) return;
    dispatch(task.actions.submit(id))
      .then((resp) => changeScreen('next',resp))
      .catch(err => Notify.error(format.error(err)));
  }

  return (
    <div className={taskCSS.holder}>
      <div className={taskCSS.task}>

        <ScreenTitle screen={screen} />

        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
            { providers.length > 0 && (
              <div className={CSS.section}>
                <div className={CSS.section_label}>Preferred vendors</div>
                { providers.map(provider => (
                  <div key={provider.id} className={CSS.provider}>
                    <div className={CSS.column}>
                      <img src={provider.logo} alt={provider.name} className={CSS.logo} />
                      <ResidentContentRenderer content={provider.description} className={CSS.content} />
                    </div>
                    { provider.ctaText !== 'NO_CTA' && (
                      <div className={classNames(CSS.column,CSS.actions)}>
                        <Button
                          text={provider.ctaText}
                          size='small'
                          icon={{library: 'navigation', symbol: 'Up-right'}}
                          iconPosition='right'
                          onClick={() => selectProvider(provider)}
                        />
                      </div>
                    )}
                    { provider.contacts.map(contact => (
                      <div className={CSS.contact_row} key={contact.id}>
                        <h4>{contact.name}</h4>
                        { contact.contact_url && (
                          <a href={contact.contact_url} className={classNames('btn-ghost btn--small', CSS.button)} target="_blank" rel="noopener noreferrer">
                            Website
                            <Icon library='home' symbol='Globe' size='22px' className='ml-10'/>
                          </a>
                        )}
                        { contact.phone && (
                          <a href={`tel:${contact.phone}`} className={classNames('btn-ghost btn--small', CSS.button)}>
                            { format.phone(contact.phone) }
                            <Icon library='communication' symbol='Active-call' size='22px' className='ml-10'/>
                          </a>
                        )}
                        { contact.email && (
                          <a href={`mailto:${contact.email}`} className={classNames('btn-ghost btn--small', CSS.button)}>
                            { contact.email }
                            <Icon library='communication' symbol='Mail' size='22px' className='ml-10'/>
                          </a>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
            { showAllConnect && (
              <div className={CSS.section}>
                { providers.length > 0 && (
                  <div className={CSS.section_label}>Other vendors</div>
                )}
                <AllConnect address={address} building={building} />
              </div>
            )}
          </div>
        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            <button
              className={classNames('btn-primary btn--full',{'loading':submitPending})}
              onClick={handleNext}
            >
              Complete
            </button>
          </div>
        </div>

      </div>
    </div>
  );
}
