// import { Complete } from './components/Complete';

export const screens = {

  /* UNKNOWN PATH */
  mystery: {
    slug: null,
    unknown: true,
    label: null,
  },

  /* GENERIC CONFIRMATION SCREEN (use after mystery in train) */
  confirmation: {
    slug: null,
    label: 'Confirmation',
  },

  /* GENERIC COMPLETE SCREEN */
  // complete: {
  //   slug: 'complete',
  //   component: Complete,
  //   label: 'Complete',
  // },

};
