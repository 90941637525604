
import React from 'react'
import { format } from '@moved/services';

import { PetDetails } from './PetDetails';

export const getRequestFields = request => {
  if(!request) return [];
  return [
    ...request.pets.map(pet => ({
      label: pet.name,
      value: (<PetDetails pet={pet} />),
      icon: { symbol: format.capitalize(pet.pet_type), library: 'animals'}
    })),
    {
      label: 'Reason for decline',
      value: request?.notes,
      icon: { symbol: 'File', library: 'files' },
    },
  ];
};
