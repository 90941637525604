import React from 'react';

import CSS from './styles/CompareMovingMethods.module.scss';

export const CompareMovingMethods = () => (
  <div className={CSS.blue_box}>
    Not sure which option is best for you?
    <a
      href='https://moved.com/2018/05/10/2018-05-10-should-you-hire-a-moving-company-a-flowchart/' target='_blank' rel='noreferrer nofollow'
      className='btn-primary btn--small'>
      Compare
    </a>
  </div>
);
