import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { get } from 'lodash';

import { DynamicForm } from '@moved/ui';
import { useNotify, useUser, format } from '@moved/services';

import { ScreenTitle } from '../../shared';
import { QuoteTicket } from './QuoteTicket';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import longCSS from './styles/LongDistance.module.scss';

// Render quotes screen
export const LongDistance = ({ screen, changeScreen, task }) => {
  //hooks
  const { id } = useParams();
  const Notify = useNotify();
  const { user } = useUser();
  const dispatch = useDispatch();
  const taskDetails = task.selectors.useTaskable(id);
  const updateTaskPending = task.selectors.useUpdateMoverBookingTaskPending();
  const completeTaskPending = task.selectors.useCompleteMoverBookingTaskPending();
  const pending = updateTaskPending || completeTaskPending;

  //Submit form function
  const _handleSubmit = ({ request_quotes, mover_booking }) => {
    if(pending) return;
    const request = request_quotes ?
      dispatch(task.actions.update(id, { mover_booking }))
        .then(r => dispatch(task.actions.longDistanceEmail(mover_booking.id)).then(() => r))  :
      dispatch(task.actions.complete(id));
    request
      .then(r => changeScreen('next', r))
      .catch(err => Notify.error(format.error(err)));
  };

  const fields = [
    {
      name: 'request_quotes',
      type: 'itemList',
      value: true,
      required: 'You must make a selection',
      className: longCSS.item_list,
      onChange: (option, setFieldValue) => {
        // restore value of email if it was removed
        if(option.value === true) setFieldValue('mover_booking.contact_email', get(taskDetails,'mover_booking.contact_email') || get(user,'email'));
      },
      options: [
        {
          label: 'Yes, contact me with quotes',
          value: true,
          icon: { symbol: 'Mail', library: 'communication' },
          large: true,
        },
        {
          label: 'No thanks',
          value: false,
          icon: { symbol: 'Stop', library: 'code' },
          large: true,
        },
      ],
    },
    {
      type: 'hidden',
      name: 'mover_booking.id',
      value: get(taskDetails,'mover_booking.id'),
    },
    {
      label: 'Confirm your email address is correct.',
      type: 'title',
      custom: {
        hidden: {
          compare: { field: 'request_quotes' },
          boolean: 'not',
          value: true,
        },
      },
    },
    {
      name: 'mover_booking.contact_email',
      label: 'Email address',
      value: get(taskDetails,'mover_booking.contact_email') || get(user,'email'),
      type: 'email',
      required: true,
      className: longCSS.preference_field,
      custom: {
        hidden: {
          compare: { field: 'request_quotes' },
          boolean: 'not',
          value: true,
        },
      },
    },
  ];

  return (
    <div className={taskCSS.holder}>

      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} />
        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>

            <div className={longCSS.content}>

              <div className={longCSS.form}>
                <h5 className={longCSS.title}>Choose an option</h5>
                <DynamicForm id='long-distance-form'
                  fields={fields}
                  formStyle={'underline'}
                  onSubmit={_handleSubmit}
                  disabled={pending}
                />
              </div>

              <QuoteTicket task={task} />

            </div> {/* longCSS.content */}


          </div> {/* taskCSS.folder */}
        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            <label
              htmlFor="long-distance-form-submit"
              tabIndex="0"
              role="button"
              className={'btn-primary btn--full' + (pending  ? ' loading' : '')}
              disabled={pending}
            >
              Next
            </label>
          </div>
        </div>

      </div>

    </div>
  );
}
