import React from 'react';
import { useHistory, useParams } from 'react-router-dom'
import classNames from 'classnames';

import { hooks } from '@moved/services';
import { PopOver, Icon } from '@moved/ui';

import { taskDefinitions } from '../../tasks';
import { getDashboardRoute } from '../helpers';

import CSS from './styles/TaskListItem.module.scss';

const TaskPopOver = ({ children, title, icon, message }) => {
  const isMobile = hooks.useMobile();
  return (
    <PopOver
      customClass={CSS.task_popover}
      customArrowClass={CSS.popover_arrow}
      placement={isMobile ? 'top' : 'left'}
      trigger={isMobile ? 'click' : ['hover','click']}
      hideOnScroll={true}
      tooltip={(
        <div className={CSS.popover_wrapper}>
          <div className={CSS.popover_header}>
            { icon && (
              <Icon color={icon.color} size={'24px'} symbol={icon.symbol} className={CSS.popover_icon} />
            )}
            <span className={CSS.popover_title}>{ title }</span>
          </div>
          <div className={CSS.popover_message}><span>{ message }</span></div>
        </div>
      )}
    >
      { children }
    </PopOver>
  )
};

export const TaskListItem = ({ task, onLaunch }) => {
  const history = useHistory();
  const { moveId, stepId } = useParams();
  const dashboardRoute = getDashboardRoute(moveId, stepId);

  const TaskDefinition = taskDefinitions[task.task_type];
  const classes = classNames(CSS.card, {
    completed: task.status === 'completed',
    [CSS.card_locked]: task.status === 'locked',
  });
  const handleClick = () => {
    // can not launch a locked task
    if(task.status === 'locked') return;
    if(onLaunch) { // special handler for consumer-app direct link into mover-booking
      onLaunch(task).then(updatedTask => {
        history.push(`${dashboardRoute}/tasks/${updatedTask.id}/${updatedTask.task_type}/${updatedTask.task_details_id}`);
      })
    } else {
      history.push(`${dashboardRoute}/tasks/${task.id}/${task.task_type}`);
    }
  }
  return (
    <div id={`task-${task.id}`} onClick={handleClick} className={classes}>
      <div className={CSS.icon}>
        <Icon symbol={task.icon_name || TaskDefinition.icon} size={'65px'} />
      </div>
      <div className={CSS.copy_button_area}>
        <div className={CSS.copy}>
          <h2>{task.title}</h2>
          <p>{task.subtitle}</p>
        </div>
        <div className={CSS.buttons}>
          {(() => {
            switch(task.status) {
              case 'locked':
                return (
                  <TaskPopOver title='Locked!' icon={{ color: 'orange', symbol: 'Shield-protected' }} message={TaskDefinition.lockedMessage}>
                    <span className={classNames('btn-gray', CSS.btn_locked)}><Icon color={'gray'} size={'16px'} symbol={'Lock'} className={CSS.btn_image} />Locked</span>
                  </TaskPopOver>
                )
              case 'in-progress':
                return <span className={classNames('btn-ghost', CSS.btn_task)}><Icon size={'24px'} library={'code'} symbol={'Time-schedule'} className={CSS.btn_image} />In progress</span>;
              case 'pending-approval':
                return <span className={classNames('btn-orange', CSS.btn_task)}><Icon size='24px' library='code' symbol='Loading' color='orange' className={CSS.btn_image} />In review</span>;
              case 'action-required':
                return <span className={classNames('btn-red-light', CSS.btn_task)}><Icon size='24px' library='code' symbol='Warning-2' className={CSS.btn_image} />Action required</span>;
              case 'complete':
                return <span className={classNames('btn-green', CSS.btn_task)}><Icon library={'navigation'} symbol={'Check'} size={'24px'} color={'green'} className={CSS.btn_image} />Done</span>;
              case 'not-started':
                return <span className={classNames('btn-primary', CSS.btn_task)}>{task.cta_copy}</span>;
              default:
                return <span className={classNames('btn-primary', CSS.btn_task)}>Click here</span>;
            }
          })()}
        </div>
      </div>
    </div>
  );
};
