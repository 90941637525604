import { get } from 'lodash';

import {
  Schedule,
  Summary,
} from './components';

export const screens = {

  'schedule': {
    slug: 'schedule',
    component: Schedule,
    label: 'Schedule',
    title: 'Schedule a date & time to conduct your pre-move-out inspection',
    subtitle: 'A representative from property management will take a tour of your apartment with you to prepare for move-out.',
    maxWidth: 804,
    isCompleted: (taskable, task) => {
      const activeRequest = task.helpers.getActiveRequest(taskable);
      return activeRequest ?
        get(activeRequest,'status') !== 'draft' :
        get(taskable,'reservation.status') === 'approved';
    },
  },

  'summary': {
    slug: 'summary',
    component: Summary,
    label: "Summary",
    backRestricted: true,
  },

};
