import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { format, useGoogleMaps } from '@moved/services';

import { useOptionalControl } from '../useOptionalControl';
import { TypeAhead } from '../TypeAhead';

import CSS from './Address.module.scss';

const getOptionFromAddress = address => ({
  label: format.address(address),
  value: address?.google_place_id
});

/*
 * Address is an opinionated wrapper around the generic TypeAhead component
 * It looks and feels like the default TypeAhead but leverages GoogleMapsServices
 * to handle the population of the options as the user types in the text field
 */
export const Address = ({
  name,
  value,
  isControlled,
  label,
  hint,
  icon,
  iconPosition,
  disabled,
  readOnly,
  error,
  onChange,
  className,
}) => {
  // don't prepopulate the address because we need to async validate the google_place_id first
  const [address, setAddress] = useOptionalControl({}, isControlled);
  const [options, setOptions] = useState([]);
  const [pending, setPending] = useState();

  const { getPlaceDetails, getAutocompletePredictions, refreshPlaceId } = useGoogleMaps();

  // on mount only, validate an existing value's google_place_id with the google API
  // if valid, set the internals to the fresh address object, if not, clear
  useEffect(() => {
    if(!value?.google_place_id) return;
    refreshPlaceId(value?.google_place_id)
      .then(freshPlaceId => {
        const updatedAddress = { ...value, google_place_id: freshPlaceId };
        setAddress(updatedAddress);
        setOptions([getOptionFromAddress(updatedAddress)]);
        if(isControlled) onChange?.({[name]:updatedAddress});
      })
      .catch(() => onChange?.({[name]:null}));
  },[]); // eslint-disable-line

  const onSelect = async (placeId='') => {
    let newAddress;
    if(placeId) {
      setPending(true);
      newAddress = await getPlaceDetails(placeId);
      setPending(false);
    }
    setAddress(newAddress);
    setOptions(newAddress ? [getOptionFromAddress(newAddress)] : []);
    onChange?.({[name]:newAddress});
  };

  const handleTypeAhead = async (queryString) => {
    if(disabled) return;
    if(!queryString) return setOptions([]);
    setPending(true);
    const predictions = await getAutocompletePredictions(queryString);
    setPending(false);
    setOptions(predictions.map(prediction => ({
      label: prediction?.description,
      value: prediction?.place_id,
    })));
  };

  return (
    <TypeAhead
      options={options}
      onSearch={handleTypeAhead}
      isSearching={pending}
      value={address?.google_place_id ?? ''}
      isControlled={true}
      onChange={({[name]:placeId}) => onSelect(placeId)}
      className={classNames(CSS.address,className)}
      {...{
        // passthrough these fields directly to the typeahead
        name,
        label,
        hint,
        icon,
        iconPosition,
        disabled,
        readOnly,
        error,
      }}
    />
  );
};

Address.propTypes = {
  /** Name to use for the form input */
  name: PropTypes.string.isRequired,
  /** Value to use for this input (only initial value if not controlled) */
  value: PropTypes.object,
  /** Flag to make the input a controlled input */
  isControlled: PropTypes.bool,
  /** Label text for the input */
  label: PropTypes.string,
  /** Second line of text */
  hint: PropTypes.string,
  /** Icon to display in the input */
  icon: PropTypes.shape({
    symbol: PropTypes.string,
    library: PropTypes.string,
  }),
  /** Icon position relative to text */
  iconPosition: PropTypes.oneOf(['left','right']),
  /** Flag to disable the input */
  disabled: PropTypes.bool,
  /** Flag to readonly the input */
  readOnly: PropTypes.bool,
  /** Error message to display for this input */
  error: PropTypes.string,
  /** onChange handler function */
  onChange: PropTypes.func,
  /** Class name to add to the input container */
  className: PropTypes.string,
};
