import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_RESET_MOVER_BOOKING_TASK',
  request: (id) => request.post(`/mover-booking-tasks/${id}/reset`).then(r => get(r,'data.data.mover_booking_task')),
  reducers: {
    success: (state, { response: bookingTask }) => {
      return {
        ...state,
        moverBookingTasks: {
          ...state.moverBookingTasks,
          [bookingTask.id]: bookingTask,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useResetMoverBookingTaskPending,
} = generate.redux(creator);

export const initialState = {};
