import React from 'react';
import { useParams, useHistory } from 'react-router-dom'

import { CompleteCard } from '@moved/ui';

import { Snippet } from '../../../../snippets';
import { ScreenTitle, CompleteScreenActions } from '../../shared';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/Complete.module.scss';

export const Complete = ({ screen, task, origin, taskBaseRoute }) => {
  // HOOKS
  const { id } = useParams();
  const history = useHistory();

  const taskDetails = task.selectors.useTaskable(id);

  return (
    <div className={taskCSS.holder}>
      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} />
        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>

            <CompleteCard
              status={'approved'}
              flavor={(<Snippet tag={'tasks.cable-internet.summary'} />)}
              detailsTitle={null}
            >
              <p>
                You've marked this task as complete, so we'll assume you've signed
                up for service in your new apartment. If you ever need to check the
                service provider options for this property, they're always here for
                you to review.
              </p>
              <div className={CSS.start_over}>
                <button
                  type="button"
                  className={'btn-gray btn--small'}
                  onClick={() => history.push(`${taskBaseRoute}${task.getStartOverRoute(taskDetails)}`)}
                >
                  Review options
                </button>
              </div>
            </CompleteCard>

          </div>
        </div>

        <CompleteScreenActions origin={origin} />

      </div>
    </div>
  )

};
