import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import classNames from 'classnames';

import { useUser, useNotify, s3, format } from '@moved/services';
import { ImageCropUpload, ImageCrop, Icon } from '@moved/ui';

import { Snippet } from '../../../../snippets';
import { ScreenTitle } from '../../shared';
import { getS3UploadUrl } from '../../shared/actions';
import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/BadgeImage.module.scss';

export const BadgeImage = ({ screen, changeScreen, task }) => {
  // HOOKS
  const { id, taskType } = useParams();
  const { user } = useUser();
  const activeMoveStep = task.selectors.useActiveMoveStep();
  const building = task.selectors.useBuilding(activeMoveStep?.building?.slug);
  const dispatch = useDispatch();
  const Notify = useNotify();

  // STATE
  const [crop, setCrop] = useState(false);
  const [pending, setPending] = useState(false);

  const buildingName = building?.settings?.display_name ?? building?.name;

  const ready = crop?.file ? true : false;

  const handleNext = e => {
    e.preventDefault();
    if(!ready || pending) return;
    setPending(true);
    dispatch(getS3UploadUrl(building.id, {
      filename: s3.getUniqueFilename(crop.file.name, user),
      http_content_type: crop.file.type,
      subdirectory: `badges`,
    }))
      .then(({signed_request, url}) => s3.putFile(crop.file, signed_request).then(() => url))
      .then(url => dispatch(task.actions.upload(id, { image_url: url })))
      .then((resp) => changeScreen('next',resp))
      .catch(err => {
        Notify.error(format.error(err));
        setPending(false);
      });
  };

  const handleReject = () => Notify.error('Oops! The file does not meet the file type requirement.');

  return (
    <div className={taskCSS.holder}>
      <div className={classNames(taskCSS.task)}>
        <ScreenTitle screen={screen} />

        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
            <div className={CSS.wrapper}>
              <div className={CSS.uploader}>
                <ImageCropUpload
                  id={`${taskType}_${screen.slug}_upload`}
                  onCrop={setCrop}
                  onReject={handleReject}
                  cropOptions={{
                    aspect: 1,
                    maxWidth: 1200,
                    minWidth: 400,
                  }}
                />
              </div>

              {!ready ? (
                <div className={CSS.requirements}>
                  <h3 className={CSS.title}>
                    <Icon symbol={'Info-circle'} library={'code'} />
                    <span>Requirements</span>
                  </h3>
                  <Snippet tag={'tasks.badge.upload.requirements'}>
                    <ul>
                      <li>Must be a clear color photo of your face</li>
                      <li>Must have been taken in the last 6 months</li>
                      <li>Must have plain background</li>
                      <li>No hats, sunglasses, or filters</li>
                    </ul>
                  </Snippet>
                </div>
              ) : (
                <div className={CSS.badge_wrapper}>
                  <div className={CSS.preview_title}>Preview</div>
                  <div className={CSS.badge}>
                    <div className={CSS.tab} />
                    <ImageCrop.Preview file={crop.file} className={CSS.preview} />
                    <div className={CSS.name}>
                      <Snippet tag={'tasks.badge.upload.preview_caption'}>
                        <span>{user.fullname}</span>
                      </Snippet>
                    </div>

                    <img src={building.logo_url} alt={buildingName} className={CSS.logo} />
                  </div>
                </div>
              )}

            </div>

          </div>
        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            <button
              id={`${taskType}_${screen.slug}_upload_button`}
              className={classNames('btn-primary btn--full',{'loading':pending})}
              onClick={handleNext}
              disabled={!ready}
            >
              Submit
            </button>
          </div>
        </div>

      </div>
    </div>
  );
};
