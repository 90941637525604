import React from 'react';
import { get, isNil } from 'lodash';
import { format } from '@moved/services';
import {
  AdContent,
  Agreements,
  BookPayment,
  ChooseMoved,
  ConfirmBook,
  ConfirmDiy,
  ConfirmLabor,
  ConfirmLongDistance,
  ConfirmNonCoreGeo,
  ConfirmTruck,
  ConfirmContainer,
  ConfirmUserProvided,
  ConfirmVendorLead,
  // not currently used (incorporated into MovingCompanyLeads)
  // ContactInfo,
  LaborQuotes,
  LongDistance,
  MoveDate,
  MoveSummary,
  MovingCompanyLeads,
  MovingPlan,
  MovingStops,
  ViewMovingCompanyAds,
  ViewQuotes,
  QuoteFAQs, // subcomponent
} from './components';

import { fieldService } from '@moved/services';

export const screens = {

  'moving-plan': {
    slug: 'moving-plan',
    label: 'Moving plan',
    title: 'How are you planning to move your belongings?',
    subtitle: `Whether transporting furniture or just a few personal items, make sure you select the best option for your needs.`,
    isCompleted: (taskable) => get(taskable,'selected_moving_method'),
    component: MovingPlan,
    maxWidth: '825px',
  },

  'moving-provider': {
    slug: 'moving-provider',
    label: 'Moving provider',
    title: 'Great! Provide a few details to see rates through our partner Moved.',
    subtitle: 'This should take two minutes or less.',
    maxWidth: 700,
    component: ChooseMoved,
    isCompleted: (taskable) => !isNil(get(taskable,'interested_in_moved')),
  },

  'date': {
    slug: 'date',
    label: 'Moving date',
    title: 'When will you be moving?',
    isCompleted: (taskable, task) => {
      const requestedDate = get(taskable,'mover_booking.requested_pickup_date')
      return requestedDate && task.canBookDate(requestedDate);
    },
    component: MoveDate,
  },

  /* NOT USED IN RESIDENT APP FLOW (ONLY STORAGE DN DIRECT) */
  'time-preference': {
    slug: 'time',
    label: 'Time preference',
    title: 'When would you like the movers to arrive?',
    subtitle: 'This will help us find a moving company with availability to work with your schedule.',
    noteBox: {
      copy: 'Movers cannot provide an exact time of arrival. You will be provided with a 3-4 hrs arrival window based on your morning/afternoon preference.',
      icon: { symbol:'Info' },
    },
    maxWidth: 660,
    isCompleted: (data) => get(data,'mover_booking.time_preference'),
    fields: (taskable = {}) => {
      const fields = [
        {
          type: 'hidden',
          name: 'mover_booking.id',
        },
        {
          type: 'itemList',
          name: 'mover_booking.time_preference',
          required: 'You must make a selection to continue.',
          options: [
            {
              label: 'Morning',
              value: 'Morning',
              icon: { symbol: 'Sunset#1' },
            },
            {
              label: 'Afternoon',
              value: 'Afternoon',
              icon: { symbol: 'Sun' },
            },
            {
              label: 'No preference',
              value: 'No preference',
              sublabel: 'This will provide more flexibility for movers to be available on your requested date',
              icon: { symbol: 'Smile' },
            },
          ],
        },
      ];
      return fieldService.hydrate(fields,taskable);
    },
  },

  'address': {
    slug: 'address',
    title: 'Where will your move start and finish?',
    subtitle: 'Enter your move distance so we can surface the eligible moving options.',
    label: 'Addresses',
    maxWidth: 480,
    isCompleted: (taskable) => get(taskable,'mover_booking.stops',[]).length >= 2,
    fields: (taskable = {}, additionalData={}) => {
      const { move } = additionalData;
      // prefill with previous taskable data
      let stops = get(taskable,'mover_booking.stops',[]).map((stop) => {
        if(get(stop,'address.google_place_id')) return stop;
        return '';
      });
      // if no existing taskable data, prefill with moveStep lease addresses
      if(stops.length < 2) {
        stops = ['move-out','move-in']
          .map((type) => get(move,'move_steps',[]).find(step => step.type === type))
          .map(step => get(step,'lease.address.google_place_id') ? {
            type: 'apartment',
            address: get(step,'lease.address'),
          } : '');
      }
      return [
        {
          name: 'mover_booking.id',
          type: 'hidden',
          value: get(taskable,'mover_booking.id'),
        },
        {
          label: 'Addresses',
          type: 'addressList',
          name: 'mover_booking.stops',
          value: stops,
          required: 'Address is required',
        }
      ];
    },
  },

  'moving-stops': {
    slug: 'address',
    title: 'Where will your move start and finish?',
    subtitle: 'Enter your move distance so we can surface the eligible moving options.',
    label: 'Addresses',
    maxWidth: 480,
    isCompleted: (taskable) => get(taskable,'mover_booking.stops',[]).length >= 2,
    component: MovingStops,
  },

  'move-size': {
    slug: 'move-size',
    label: 'Move size',
    title: 'How big is your current space?',
    subtitle: 'Understanding the general size of your move helps us pair you with the right pre-approved moving option.',
    isCompleted: (taskable) => get(taskable,'mover_booking.number_of_rooms'),
    fields: (taskable = {}) => {
      const fields = [
        {
          name: 'mover_booking.id',
          type: 'hidden',
          value: get(taskable,'mover_booking.id'),
        },
        {
          type: 'itemList',
          name: 'mover_booking.number_of_rooms',
          value: '',
          required: 'You must select a move size',
          options: [
            {
              label: 'Studio',
              value: 'Studio',
              icon: { symbol: 'Bedroom-studio', library: 'home' },
            },
            {
              label: '1 Bedroom',
              value: '1',
              icon: { symbol: 'Bedroom-1', library: 'home' },
            },
            {
              label: '2 Bedrooms',
              value: '2',
              icon: { symbol: 'Bedroom-2', library: 'home' },
            },
            {
              label: '3 Bedrooms',
              value: '3',
              icon: { symbol: 'Bedroom-3', library: 'home' },
            },
            {
              label: '4+ Bedrooms',
              value: '4+',
              icon: { symbol: 'Bedroom-4', library: 'home' },
            },
          ],
        },
      ];
      return fieldService.hydrate(fields,taskable);
    },
  },

  'special': {
    slug: 'special',
    label: 'Special items',
    title: 'Are you moving any of these?',
    subtitle: (<span>The below require special handling when being moved into the property.<br />Your movers will need to know this to ensure they are equipped for the job.</span>),
    additionalData: { specialList: { action: 'getSpecialList', selector: 'useSpecialList' }},
    isCompleted: (taskable) => !isNil(get(taskable,'mover_booking.special_items')),
    fields: (taskable = {}, additionalData={}) => {
      const special = get(additionalData,'specialList.data');
      return [
        {
          name: 'mover_booking.id',
          type: 'hidden',
          value: get(taskable,'mover_booking.id'),
        },
        {
          type: 'itemList',
          name: 'mover_booking.special_items',
          isMulti: true,
          isTwoCol: true,
          options: special ? special.map((option) => {
            return {
              value: option.name || option, // temp backward compatible
              label: option.label || format.underscore(option), // temp backward compatible
            };
          }) : [],
          value: (get(taskable,'mover_booking.special_items')||[]).map(selected => selected.name), // temp backward compatible
        },
      ];
    },
  },

  'moving-quotes': {
    slug: 'moving-quotes',
    component: ViewQuotes,
    label: 'Moving quotes',
    title: 'Here are your moving options',
    subtitle: 'The below options are based on your move details. These hourly rate quotes are fulfilled by pre-approved, top-tier companies in the Moved network. Learn more about Moved Certified movers below.',
    isCompleted: (taskable) => get(taskable,'mover_booking.selected_partner_rate'),
    faq: QuoteFAQs,
    showOptOut: true,
    maxWidth: 1060,
  },

  'moving-summary': {
    slug: 'summary',
    component: MoveSummary,
    label: 'Summary',
    title: 'Summary',
    maxWidth: 1060,
  },

  'agreements': {
    slug: 'agreements',
    component: Agreements,
    label: 'Agreements',
    title: 'You must agree to the following terms and conditions',
    isCompleted: (taskable) => get(taskable,'mover_booking.terms_accepted_at'),
    maxWidth: 800,
  },

  'moving-payment': {
    slug: 'payment',
    component: BookPayment,
    label: 'Payment',
    title: 'Payment information',
    subtitle: 'Enter your credit card information below.',
    isCompleted: (taskable) => !isNil(get(taskable,'mover_booking.booked_service_order')),
    maxWidth: 1060,
  },

  'moving-company-preference': {
    slug: 'moving-companies',
    component: MovingCompanyLeads,
    label: 'Moving companies',
    title: 'Get a quote from a top-tier Moving Company',
    subtitle: `Get an exclusive discounted quote from a best-in-class local
      moving company based on your specific move details. No commitments.
      It couldn’t be easier.`,
    isCompleted: (taskable) => get(taskable,'selected_moving_company_ads') && get(taskable,'completed_at'),
  },

  'confirmation-book-movers': {
    slug: 'confirmation',
    component: ConfirmBook,
    label: 'Confirmation',
    maxWidth: 1060,
    backRestricted: true,
  },

  'moving-companies': {
    slug: 'moving-companies',
    component: ViewMovingCompanyAds,
    label: 'Moving companies',
    title: 'No worries! If you prefer to research yourself, below are recommended movers for your building.',
    subtitle: `Select companies you’re interested in receiving quotes from. By
      doing so, you agree to be introduced to them via email. Your information
      will never be shared otherwise, or with any companies you do not select.`,
    isCompleted: (taskable) => get(taskable,'selected_moving_company_ads') && get(taskable,'completed_at'),
  },

  'labor-quotes': {
    slug: 'labor-quotes',
    component: LaborQuotes,
    label: 'Quotes',
    title: 'Labor quotes from Hire A Helper',
    subtitle: `Enter where and when you need help, and we'll show quotes from our
      friends at Hire A Helper. If you are interested in booking one of these
      options, please click on the quote and proceed to checkout through
      Hire A Helper's website.`,
    isCompleted: (taskable) => get(taskable,'completed_at'),
  },

  /* USED FOR LABOR AND TRUCK RENTAL AD SCREENS */
  'ad-content': {
    component: AdContent,
    isCompleted: (taskable) => get(taskable,'completed_at'),
  },

  'confirmation-diy': {
    slug: 'confirmation',
    component: ConfirmDiy,
    label: 'Confirmation',
    backRestricted: true,
  },

  'confirmation-labor': {
    slug: 'confirmation',
    component: ConfirmLabor,
    label: 'Confirmation',
    backRestricted: true,
  },

  'confirmation-truck': {
    slug: 'confirmation',
    component: ConfirmTruck,
    label: 'Confirmation',
    backRestricted: true,
  },

  'confirmation-container': {
    slug: 'confirmation',
    component: ConfirmContainer,
    label: 'Confirmation',
    backRestricted: true,
  },

  'confirmation-user-provided': {
    slug: 'confirmation',
    component: ConfirmUserProvided,
    label: 'Confirmation',
    backRestricted: true,
  },

  'confirmation-vendor-lead': {
    slug: 'confirmation',
    component: ConfirmVendorLead,
    label: 'Confirmation',
    title: 'Your moving company recommendation',
    backRestricted: true,
  },

  'long-distance': {
    slug: 'long-distance',
    component: LongDistance,
    label: 'Moving options',
    title: 'It looks like you are moving long distance!',
    subtitle: `If you are interested in having a Moved concierge reach out to you with best-in-class moving quotes, provide your contact info below. Don't worry - this won't be shared with anyone but your Moved concierge.`,
    maxWidth: 1060,
    isCompleted: (taskable) => get(taskable,'completed_at') || get(taskable,'mover_booking.contact_email'),
  },

  'confirmation-long-distance': {
    slug: 'confirmation',
    component: ConfirmLongDistance,
    label: 'Confirmation',
    backRestricted: true,
  },

  'confirmation-non-core-geo': {
    slug: 'confirmation',
    component: ConfirmNonCoreGeo,
    label: 'Confirmation',
    backRestricted: true,
  },

};
