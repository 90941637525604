import { Badge } from '@moved/ui';

export const TaskStatus = ({ taskDetails }) => {
  return (
    <div className='stackVertical gap-8'>
      <div className='stackHorizontal gap-24 items-center justify-start'>
        <div className='labelM contentSecondary width-96'>Status</div>
        <Badge
          text={taskDetails.status}
          type={taskDetails.status === 'todo' ? 'warning' : 'success'}
          size='medium'
        />
      </div>
      {/* MVP fast follow functionality, don't show until it works.
      <div className='stackHorizontal gap-24 items-center justify-start'>
        <div className='labelM contentSecondary width-96'>Due date</div>
        <div className='stackHorizontal gap-8 items-center'>
          <Button
            icon={{library:'code',symbol:'Date-to'}}
            size='small'
            color='tertiary'
          />
          <span className='labelM contentSecondary'>No due date</span>
        </div>
      </div>
      <div className='stackHorizontal gap-24 items-center justify-start'>
        <div className='labelM contentSecondary width-96'>Reminder</div>
        <div className='stackHorizontal gap-8 items-center'>
          <Button
            icon={{library:'code',symbol:'Date-to'}}
            size='small'
            color='tertiary'
          />
          <span className='labelM contentSecondary'>No reminder</span>
        </div>
      </div>
      */}
    </div>
  );
}
