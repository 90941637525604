import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon } from '../Icon';

import CSS from './Badge.module.scss';

export const Badge = ({
  size='small',
  type='default',
  text,
  icon,
  dark,
}) => {
  return (
    <div className={classNames(
      'stackHorizontal gap-4',
      CSS[size],
      CSS[type],
      {[CSS.dark]:dark},
    )}>
      { text && (
        <span className={classNames('labelXS',CSS.text)}>{ text }</span>)
      }
      { icon && (
        <Icon
          library={icon.library}
          symbol={icon.symbol}
          className={CSS.icon}
          size='16px'
        />
      )}
    </div>
  );
};

Badge.propTypes = {
  /** Size of the badge */
  size: PropTypes.oneOf(['small','medium']),
  /** Type of the badge (determines the colors) */
  type: PropTypes.oneOf(['success','info','error','warning','default']),
  /** Text to display on the badge */
  text: PropTypes.string,
  /** Optionally include an icon on the badge (single color icons only) */
  icon: PropTypes.shape(Icon.PropTypes),
  /** Use the dark variants of the colors from type */
  dark: PropTypes.bool,
};
