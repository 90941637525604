import { cableInternet as helpers } from '@moved/product';

import { BaseTask } from '../../components/BaseTask';

import * as actions from './actions';
import * as selectors from './actions/selectors';
import { screens } from './screens';

export class CableInternet extends BaseTask {
  constructor() {
    super({
      screens: screens,
      actions: actions,
      selectors: {
        useTaskable: selectors.useCableInternetTask,
        useTaskablePending: selectors.useGetCableInternetTaskPending,
        ...selectors,
      }
    });
  }

  static label = 'Cable and Internet';
  static slug = 'cable-internet';
  static icon = 'TV#1';
  static helpers = helpers;

  _flowBuilder(taskData) {
    const providerServicesEnabled = taskData?.settings?.allconnect_provider_services_enabled ?? false;

    if (providerServicesEnabled) {
      return [
        this.screens['providers-v2'],
        this.screens['complete'],
      ];
    }

    return [
      this.screens['providers'],
      this.screens['complete'],
    ];
  }

  /* START OVERRIDES */
  /* END OVERRIDES */

  /* TASK SPECIFIC */
  /* END TASK SPECIFIC */

}
