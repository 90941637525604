import React from 'react';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';

import { Icon } from '@moved/ui';

import { BookCoupon } from './BookCoupon';
import quoteCSS from './styles/QuoteTicket.module.scss';

export const QuoteTicket = ({ task }) => {
  const { moveId } = useParams();
  const activeMoveStep = task.selectors.useActiveMoveStep();
  const building = task.selectors.useBuilding(get(activeMoveStep,'building.slug'));
  const { discounts = [] } = task.selectors.useMove(moveId);

  return (
    <div className={quoteCSS.ticket}>
      <div className={quoteCSS.certified}>
        <Icon symbol={'Shield-check'} size={'24px'} className={quoteCSS.shield} />
        <div>
          <h3>Moved Certified</h3>
          <p>
            You are 100% covered by the Moved Happiness Guarantee. You'll be matched with a certified company who can handle your unique move
            { building ? ` and is pre-approved to serve moves at ${get(building, 'partner.name')} properties.` : `.` }
          </p>
        </div>
      </div>

      <div className={quoteCSS.attributes}>
        <h3>What is Moved?</h3>
        <p>Moved matches you with pre-vetted, certified moving companies</p>
        <ul className={quoteCSS.reasons}>

          <li>
            <Icon library='general' symbol='Star' size='24px'/>
            <span>Verified 5 star customer reviews</span>
          </li>
          <li>
            <Icon library='shopping' symbol='Sale#2' size='24px'/>
            <span>Discounted rates exclusively for { building ? `${get(building, 'partner.name')} moves` : 'Moved'}</span>
          </li>
          { building && (
            <li>
              <Icon library='files' symbol='File-done' size='24px'/>
              <span>Pre-approved COI (certificate of insurance)</span>
            </li>
          )}
          <li>
            <Icon library='shopping' symbol='Chart-line#1' size='24px'/>
            <span>10X better performance than industry standard</span>
          </li>
          <li>
            <Icon library='general' symbol='User' size='24px'/>
            <span>Moved Concierge service included</span>
          </li>

        </ul>
      </div>

      {discounts.map((discount,index) => (
        <BookCoupon key={`${get(discount,'sponsor.name')}_${discount.cents || discount.percent}_${index}`} discount={discount} />
      ))}

    </div>
  );
};
