import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams,useHistory } from "react-router-dom";
import classNames from 'classnames';

import { Icon, PopOver, ConfirmationModal, AtomSpinner } from '@moved/ui';
import { useNotify, format, useModal } from '@moved/services';

import { ScreenTitle } from '../../shared';
import { OtherVehicles } from '.';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/VehiclesSummary.module.scss';

const _formatVehicleName = vehicle => `${vehicle.make} ${vehicle.model}`;

const VehicleCard = ({ vehicle, activeRequest, taskDefinition, taskBaseRoute}) => {
  const history = useHistory();
  const { id } = useParams();
  const Modal = useModal();
  const Notify = useNotify();
  const dispatch = useDispatch();

  // Remove vehicle details handler
  const _handleDeleteVehicleDetails = (vehicle, vehicles) => {

    // check if vehicle to be removed is the only vehicle
    if(!(vehicles.length > 1)) {
      return Promise.all([
        dispatch(taskDefinition.actions.cancelVehicleDetails(activeRequest.id, vehicle.id)),
        dispatch(taskDefinition.actions.update(id, { has_vehicles: false }))
      ])
      .then(() => Notify.default(`Details for ${_formatVehicleName(vehicle)} have been removed.`))
      .catch(error => Notify.error(format.error(error)));
    }

    return dispatch(taskDefinition.actions.cancelVehicleDetails(activeRequest.id, vehicle.id))
      .then(() => Notify.default(`Details for ${_formatVehicleName(vehicle)} have been removed.`))
      .catch(error => Notify.error(format.error(error)));
  }


  // remove vehicle details confirmation modal
  const _openRemoveModal = (vehicleDetails, vehicles) => Modal.open(
    <ConfirmationModal
      title={`Cancel registration for ${_formatVehicleName(vehicleDetails)}`}
      copy={(
        <>
          Are you sure you wish to delete all details for <strong>{_formatVehicleName(vehicleDetails)}</strong> <em>({vehicleDetails.year})</em> and withdraw its registration?
          This action cannot be undone.
        </>
      )}
      confirmText='Confirm'
      cancelText='Never mind'
      onConfirm={() => _handleDeleteVehicleDetails(vehicle, vehicles)}
    />,
    { sondheim: true },
  );

  return (
    <div className={CSS.card}>
      <div className={CSS.popover}>
        <PopOver
          id={`more_${vehicle.id}`}
          customClass={CSS.vehicle_popover}
          className={classNames(CSS.icon,CSS.icon_more)}
          hideArrow="true"
          placement="bottom-end"
          trigger="click"
          delayHide={400}
          tooltip={(
            <div id={'popover_more_options'}>
              <div
                className={CSS.popover_action}
                onClick={e => {
                  e.preventDefault();
                  history.push(`${taskBaseRoute}/${id}/details/${vehicle.id}`);
                }}
              >
                <Icon symbol={'Edit'} library={'general'} size={'20px'} className={CSS.popover_icon} />
                <span className={CSS.popover_link}>Edit</span>
              </div>
              <div className={classNames(CSS.popover_action, CSS.delete)} onClick={e => _openRemoveModal(vehicle, activeRequest.vehicles)}>
                <Icon symbol={'Close'} library={'navigation'} size={'20px'} className={CSS.popover_icon} />
                <span className={CSS.popover_link}>Cancel registration</span>
              </div>
            </div>
          )}
          stopPropagation
        >
          {(tooltipShown) => (
            <Icon symbol='Other#2' library='general' className={CSS.more_icon} />
          )}
        </PopOver>
      </div>
      <div className={CSS.circle} />
      <h3 className={CSS.name}>{_formatVehicleName(vehicle)}</h3>
      <p className={CSS.year}>{vehicle.year}</p>
        <div className={CSS.vehicle_icon}>
          <div className={CSS.spacer} />
          <Icon
            symbol={format.capitalize(vehicle.vehicle_type)}
            library={'vehicles'}
            size={'25%'}
          />
        </div>
    </div>
  );
};


export const VehiclesSummary = ({ screen, changeScreen, taskDefinition, taskDetails, taskBaseRoute }) => {
  // HOOKS
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const Notify = useNotify();

  // REDUX
  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);
  const updatePending = taskDefinition.selectors.useVehicleRegistrationPending();
  const submitPending = taskDefinition.selectors.useUpdateVehiclePending();
  const loading = taskDefinition.selectors.useVehicleRegistrationPending();

  // STATE
  const pending = updatePending || submitPending;

  const _addVehicle = () => {
    dispatch(taskDefinition.actions.createVehicle(activeRequest.id, {}))
    .then(() => history.push(`${taskBaseRoute}/${id}`))
    .catch(error => Notify.error(format.error(error)));
  };

  const _declineVehicles = e => {
    e.preventDefault();

    return dispatch(taskDefinition.actions.update(id, { 'has_vehicles': false }))
      .then(resp => changeScreen('next',resp))
      .catch(error => Notify.error(format.error(error)));
  };

  const _handleSubmit = e => {
    e.preventDefault();
    return dispatch(taskDefinition.actions.submitRequest(activeRequest.id))
      .then(resp => changeScreen('next',resp))
      .catch(error => Notify.error(format.error(error)));

  };

  return (
    <div className={taskCSS.holder}>
      <div className={classNames(taskCSS.task)}>
        <ScreenTitle screen={screen} />

        <OtherVehicles taskDetails={taskDetails} />

        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
            {loading ? (
              <AtomSpinner />
            ) : (
              <div className={CSS.card_holder}>
              {activeRequest.vehicles.map(vehicle => (
                <VehicleCard
                  key={vehicle.id}
                  vehicle={vehicle}
                  taskDefinition={taskDefinition}
                  taskBaseRoute={taskBaseRoute}
                  activeRequest={activeRequest}
                />
              ))}
              <div className={CSS.add} onClick={_addVehicle}>
                <div className={CSS.add_button}>
                  <Icon symbol={'Plus'} library={'navigation'} size={'24px'} className={CSS.add_icon} />
                </div>
                <div className={CSS.add_text}>Add another vehicle</div>
              </div>
            </div>
            )}
          </div>
        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            {activeRequest.vehicles.length > 0
              ? (
                <button
                  onClick={_handleSubmit}
                  tabIndex="0"
                  className={classNames('btn-primary btn--full',{ loading: pending })}
                  disabled={pending}
                >
                  Submit
                </button>
              )
              : (
                <button
                  className={classNames('btn-ghost btn--full',{ loading: pending })}
                  onClick={_declineVehicles}
                  disabled={pending}
                >
                  No additional vehicles
                </button>
              )
            }
          </div>
        </div>

      </div>
    </div>
  );
};
