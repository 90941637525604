import { base } from '../base';

import { getRequestFields } from './getRequestFields';
import { getSummaryFields } from './getSummaryFields';

export const petRegistration = {
  ...base,
  getRequestFields,
  getSummaryFields,
};
