import React from 'react';
import { get, isNil } from 'lodash';

import { format } from '@moved/services';
import { CreditCardLogo } from '@moved/ui';

// Map vendor option to display value
// also used in COI override fields
export const getMovingMethodDisplay = (option, taskDetails) => {
  switch(option) {
    case 'professional-movers':
      return `I'm hiring professional movers`;
    case 'hiring-labor':
      const selectedLaborAd = taskDetails?.selected_labor_provider_ad_id && taskDetails?.labor_provider_ads.find(ad => ad.id === taskDetails?.selected_labor_provider_ad_id);
      return `I'm hiring labor` + (selectedLaborAd ? ` through ${selectedLaborAd.provider_name}`:'');
    case 'truck-rental':
      const selectedTruckAd = taskDetails?.selected_truck_provider_ad_id && taskDetails?.truck_provider_ads.find(ad => ad.id === taskDetails?.selected_truck_provider_ad_id);
      return `I'm renting a truck` + (selectedTruckAd ? ` from ${selectedTruckAd.provider_name}`:'');
    case 'container-rental':
      const selectedContainerAd = taskDetails?.selected_container_provider_ad_id && taskDetails?.container_provider_ads.find(ad => ad.id === taskDetails?.selected_container_provider_ad_id);
      return `I'm renting a container` + (selectedContainerAd ? ` from ${selectedContainerAd.provider_name}`:'');
    case 'diy':
      return 'Moving myself';
  }
};

export const getMoverBookingFields = (moverBooking) => {
  return [
    get(moverBooking,'requested_pickup_date') && {
      label: 'Move date',
      value: format.date(get(moverBooking,'requested_pickup_date')),
      icon: { symbol: 'Date', library: 'code' },
    },
    get(moverBooking,'time_preference') && {
      label: 'Time preference',
      value: get(moverBooking,'time_preference'),
      icon: { symbol: 'Clock', library: 'home' },
    },
    ...get(moverBooking, 'stops', []).map((stop, index, stops) => {
      let label = `Stop ${index}`;
      let icon = { symbol: 'Marker#1', library: 'map' };
      if(index === 0) {
        label = 'Origin';
        icon = { symbol: 'Home', library: 'home' };
      }
      if(index === stops.length-1) {
        label = 'Destination';
        icon = { symbol: 'Building', library: 'home' };
      }
      return {
        label,
        value: format.stopDisplay(stops[index]),
        valueText: format.stopDisplayText(stops[index]),
        icon,
      }
    }),
    get(moverBooking,'number_of_rooms') && {
      label: 'Move size',
      value: get(moverBooking,'number_of_rooms') === 'Studio' ? 'Studio' : get(moverBooking,'number_of_rooms') + ' Bedrooms',
      icon: { symbol: 'Layout-top-panel-3', library: 'layout' },
    },
    get(moverBooking,'special_items.length') && {
      label: 'Special items',
      value: get(moverBooking,'special_items').map(item => item.label).join(', '),
      icon: { symbol: 'Warning-2', library: 'code' },
    },
    get(moverBooking,'inventory_text') && {
      label: 'Inventory list (from partner)',
      value: get(moverBooking,'inventory_text'),
      icon: { symbol: 'Clipboard-list', library: 'files' },
    },
    get(moverBooking,'contact_name') && {
      label: 'Contact name',
      value: get(moverBooking,'contact_name'),
      icon: { symbol: 'User', library: 'general' },
    },
    get(moverBooking,'contact_email') && {
      label: 'Contact email',
      value: get(moverBooking,'contact_email'),
      icon: { symbol: 'Mail', library: 'communication' },
    },
    get(moverBooking,'contact_phone') && {
      label: 'Contact phone',
      value: format.phone(get(moverBooking,'contact_phone')),
      icon: { symbol: 'Active-call', library: 'communication' },
    },
  ].filter(v=>v);
};

export const getMoverBookingTransactionFields = (transaction) => {
  return [
    get(transaction,'confirmation_number') && {
      label: 'Confirmation number',
      value: `#${get(transaction,'confirmation_number')}`,
      icon: { symbol: 'Check', library: 'navigation' },
    },
    get(transaction,'purchase_date_time') && {
      label: 'Booking date',
      value: format.date(get(transaction,'purchase_date_time'),'dateTime'),
      icon: { symbol: 'Date', library: 'code' },
    },
    get(transaction,'rate_type') && {
      label: 'Rate type',
      value: get(transaction,'rate_type') === 'flat' ? 'Flat rate' : 'Hourly',
      icon: { symbol: 'Money', library: 'shopping' },
    },
    get(transaction,'crew_size') && {
      label: 'Crew size',
      value: `${get(transaction,'crew_size')} movers`,
      icon: { symbol: `Crew-${get(transaction,'crew_size')}`, library: 'people' },
    },
    get(transaction,'payment_information') && {
      label: 'Payment method',
      value: (
        <>
          <CreditCardLogo symbol={get(transaction,'payment_information.data.card_type')} />
          <span className={'ml-10'}>
            {`${format.capitalize(get(transaction,'payment_information.data.card_type'))} ending in ${get(transaction,'payment_information.data.card_last4')}`}
          </span>
        </>
      ),
      icon: { symbol: 'Credit-card', library: 'shopping' },
    },
  ].filter(v=>v);
};

export const getSummaryFields = (taskDetails) => {
  const selectedMovingCompanyAds = get(taskDetails, 'selected_moving_company_ads');
  return [
    {
      label: 'Method',
      value: getMovingMethodDisplay(get(taskDetails,'selected_moving_method'),taskDetails),
      icon: { symbol: 'Moving-logistics', library: 'task' },
    },
    get(taskDetails,'selected_moving_method') === 'professional-movers' && !isNil(selectedMovingCompanyAds) && {
      label: 'Requested contact',
      value: selectedMovingCompanyAds.length ? selectedMovingCompanyAds.map(company => company.name).join(', ') : 'No thanks',
      icon: { symbol: 'Sending', library: 'communication' },
    },
    get(taskDetails,'selected_moving_method') === 'professional-movers' && get(taskDetails,'interested_in_moved') && get(taskDetails,'mover_booking.long_distance') && {
      label: 'Requested long distance quotes',
      value: get(taskDetails,'mover_booking.contact_email') ? 'Yes' : 'No thanks',
      icon: { symbol: 'Sending', library: 'communication' },
    },
    ...getMoverBookingFields(get(taskDetails,'mover_booking',{})),
    ...getMoverBookingTransactionFields(get(taskDetails,'mover_booking.booked_service_order',{})),
  ].filter(v=>v&&v.value);
};
