import { get } from 'lodash';

import * as screenComponents from './components';

export const screens = {

  'document-upload': {
    slug: 'documents',
    label: 'Documents',
    title: 'Review some important documents for your new apartment',
    subtitle: 'Please download and complete the required information for your upcoming lease.',
    component: screenComponents.DocumentUpload,
    isCompleted: (data, { helpers }) => {
      const activeRequest = helpers.getActiveRequest(data);
      return activeRequest && (get(activeRequest,'status') !== 'draft');
    },
    maxWidth: 860,
  },
  'document-complete': {
    slug: 'complete',
    component: screenComponents.Summary,
    label: 'Confirmation',
    backRestricted: true,
  },
};
