import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { get } from 'lodash';
import classNames from 'classnames';

import { useNotify, format } from '@moved/services';
import { bookMovers } from '@moved/product';
import { CompleteCard, FieldList } from '@moved/ui';

import { getMove } from '../../../../moves/actions';
import { Snippet } from '../../../../snippets';
import { ScreenTitle, CompleteScreenActions } from '../../shared';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';

export const ConfirmLongDistance = ({screen, task, origin, taskBaseRoute}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const Notify = useNotify();
  const { id, moveId } = useParams();
  const taskDetails = task.selectors.useTaskable(id);
  const moverBooking = get(taskDetails,'mover_booking');
  const updateTaskPending = task.selectors.useUpdateMoverBookingTaskPending();
  const resetPending = task.selectors.useResetMoverBookingTaskPending();
  const pending = updateTaskPending || resetPending;

  const isBooked = task.isBooked(taskDetails);

  const _changeSelection = () => {
    // Revert mover_booking data
    dispatch(task.actions.update(id, {
      mover_booking: {
        id: moverBooking.id,
        requested_pickup_date: null,
        stops: [],
        contact_email: null,
      },
    }))
      // then reset the task (only if not direct app)
      .then(r => task.constructor.name !== 'BookMoversDirect' ? dispatch(task.actions.reset(id)) : r)
      // refresh the move because reset affects MBT and MT on the move
      .then(r => { dispatch(getMove(moveId)); return r; })
      .then(r => history.push(`${taskBaseRoute}${task.getStartOverRoute(r)}`))
      .catch(err => Notify.error(format.error(err)));
  };


  const fields = [
    ...bookMovers.getSummaryFields(taskDetails),
    !isBooked && {
      value: (
        <div
          className={classNames('btn-gray btn--small',{'loading':pending})}
          disabled={pending}
          onClick={_changeSelection}
        >
          Change selections
        </div>
      )
    }
  ].filter(v=>v);

  return (
    <div className={taskCSS.holder}>

      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} />
        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>

            <CompleteCard
              status={'pending'}
              title={'Gathering quotes'}
              flavor={(
                <Snippet tag={'tasks.book-movers.summary.long-distance'}>
                  Thanks for submitting your information. You will be contacted about your move soon!
                </Snippet>
              )}
            >
              <FieldList list={fields} />
            </CompleteCard>

          </div>
        </div>

        <CompleteScreenActions origin={origin} />

      </div>

    </div>
  )
};
