import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';

import { useUser, format } from '@moved/services';

import { LayoutSidebar } from '../../common';
import { useMove, useMoveSummaries } from '../../moves/actions/selectors';

import { useTaskList } from '../actions/getTaskList';
import { MoveData } from './MoveData';
import { GettingStarted } from './GettingStarted';
import { TaskList } from './TaskList';

import CSS from './styles/Dashboard.module.scss';

export const Dashboard = () => {
  const { moveId } = useParams();
  const { user } = useUser();
  const moveSummaries = useMoveSummaries();
  const move = useMove(moveId);
  const taskList = useTaskList(move?.personal_task_list_id);

  const moveSummary = moveSummaries?.find(({id}) => id === parseInt(moveId));

  return (
    <LayoutSidebar>
      <Helmet>
        <title>{`Your personal task dashboard : Moved`}</title>
      </Helmet>
      <div className={classNames('stackVertical items-center')}>
        <div className={classNames(CSS.wrapper,'stackVertical gap-48')}>

          <section className={classNames(CSS.section)}>
            <div className={classNames(CSS.contentContainer, 'stackVertical-12')}>
              <h1 className='displayL contentPrimary'>
                {user.firstname ? `${format.capitalize(user.firstname)}'s dashboard` : 'My dashboard'}
              </h1>
              <MoveData moveSummary={moveSummary} className='hidden--mobile' />
            </div>
          </section>

          <section className={classNames(CSS.section, CSS.accent)}>
            <GettingStarted taskList={taskList} className={CSS.contentContainer} />
          </section>

          <section className={classNames(CSS.section)}>
            <div className={CSS.contentContainer}>
              <TaskList
                title='My tasks'
                subtitle='Manage everything you want to get accomplished to have a successful move.'
                taskList={taskList}
              />
              </div>
          </section>

        </div>
      </div>
    </LayoutSidebar>
  );
}
