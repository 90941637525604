import { DeclineEmailCoupon } from './components/DeclineEmailCoupon';
import { TaskFlow } from '../../components/TaskFlow';

export const routes = [
  // wonky custom route to handle a specific action from an email CTA
  {
    path: `/decline-email-coupon`,
    name: 'declineCoupon',
    component: DeclineEmailCoupon,
    exact: true,
  },
  // unrestricted screenSlugs to support content editor powered truck and labor ad screens
  {
    path: `/:screenSlug`,
    name: 'taskFlowScreen',
    component: TaskFlow,
  },
];
