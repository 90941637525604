import { get } from 'lodash';
import { getRecommendedDashboardRoute } from '../../dashboard/helpers';

export const getMoveNavigation = (move) => {
  const moveSteps = get(move,'move_steps',[]);
  // convenience value for looking up if resident customer or not
  const hasMoveSteps = moveSteps.length > 0;
  // convenience value for feature flag presense for marketplace
  const isMarketplaceEnabled = moveSteps.find(step => step?.building?.settings?.marketplace_enabled) != null;
  const navigation = [
    // hide navigation if no resident task dashboard
    hasMoveSteps && {
      label: 'Tasks',
      path: getRecommendedDashboardRoute(move),
      exact: true,
      icon: {
        library: 'layout',
        symbol: 'Layout-horizontal',
      },
    },
    isMarketplaceEnabled && {
      label: 'Moving Offers',
      path: `/moves/${move.id}/marketplace`,
      exact: true,
      icon: {
        library: 'shopping',
        symbol: 'Price#1',
      },
    },
    {
      label: 'Personal Tasks',
      path: `/moves/${move.id}/personal-tasks`,
      exact: true,
      icon: {
        library: 'shopping',
        symbol: 'Price#1',
      },
    }
  ].filter(Boolean);

  // only return a menu to render if there are more than one link
  return navigation.length > 1 ? navigation : null;
};
