import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_PARTNER_RATES',
  request: (id) => request.get(`/mover-bookings/${id}/rates`).then(r => r.data.data.rates),
  selector: (state, id) => get(state, `moverBookingRates.${id}`),
  reducers: {
    success: (state, { response: rates, params: [id] }) => {
      return {
        ...state,
        moverBookingRates: {
          ...state.moverBookingRates,
          [id]: rates,
        },
      };
    }
  }
}

export const {
  action,
  reducer,
  useResource: usePartnerRates,
  usePending: usePartnerRatesPending,
} = generate.redux(creator);

export const initialState = {
  moverBookingRates: {}
};
