import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import classNames from 'classnames';
import { get } from 'lodash';

import { Icon } from '@moved/ui';

import { useNotify, format } from '@moved/services';

import { Snippet } from '../../../../snippets';
import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  ScreenActions ,
} from '../../shared';

import CSS from './styles/ChooseMoved.module.scss';

export const ChooseMoved = ({ screen, changeScreen, task }) => {
  //hooks
  const { id } = useParams();
  const Notify = useNotify();
  const dispatch = useDispatch();
  const [selectMovedPending, setSelectMovedPending] = useState();
  const [selectOtherPending, setSelectOtherPending] = useState();
  const pending = selectMovedPending || selectOtherPending;

  const taskDetails = task.selectors.useTaskable(id);

  const selectMoved = () => {
    if(pending) return;
    setSelectMovedPending(true);
    dispatch(task.actions.update(id, { interested_in_moved: true }))
      .then(r => get(r,'mover_booking') ? r : dispatch(task.actions.createMoverBooking(id)))
      .then(r => get(r,'settings.anonymous_ads_enabled') && get(r,'selected_moving_company_ads') ? dispatch(task.actions.complete(id)) : r)
      .then(r => changeScreen('next', r))
      .catch(err => {
        setSelectMovedPending(false);
        Notify.error(format.error(err))
      });
  };

  const selectOther = () => {
    if(pending) return;
    setSelectOtherPending(true);
    dispatch(task.actions.update(id, { interested_in_moved: false }))
      .then(r => get(r,'selected_moving_company_ads') ? dispatch(task.actions.complete(id)) : r)
      .then(r => changeScreen('next', r))
      .catch(err => {
        setSelectOtherPending(false);
        Notify.error(format.error(err))
      });
  };

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} />
      <ScreenContent screen={screen}>
        <div className={CSS.map}/>
        <div className={CSS.card_row}>
          <div className={classNames(CSS.card, CSS.illustrated_bg)}>
            <h3 className='headingM contentPrimary marginBottom-8'>What is Moved?</h3>
            <div className='labelM contentSecondary stackVertical gap-8'>
              <div>Moved connects you with <em>your</em> perfect moving company.</div>
              <div>No need to shop around. Our partners at Moved have built a network of the most trusted local movers in the area.</div>
              <div>Connecting through Moved will earn you an exclusive, discounted rate.</div>
            </div>
          </div>
        </div>
        <div className={CSS.card_row}>
          <div className={CSS.card}>
            <Icon library='general' symbol='Star' size='32px' className={classNames(CSS.card_icon, CSS.blue)}/>
            <span className={CSS.card_text}>Verified 5 star customer reviews</span>
          </div>
          <div className={CSS.card}>
            <Icon library='shopping' symbol='Sale#2' size='32px' className={classNames(CSS.card_icon, CSS.green)}/>
            <span className={CSS.card_text}>Exclusive discounted rates courtesy of your property</span>
          </div>
          <div className={CSS.card}>
            <Icon library='files' symbol='File-done' size='32px' className={classNames(CSS.card_icon, CSS.red)}/>
            <span className={CSS.card_text}>Pre-approved COI (certificate of insurance)</span>
          </div>
        </div>
        <div className={CSS.card_row}>
          <div className={CSS.card}>
            <Icon library='shopping' symbol='Chart-line#1' size='32px' className={classNames(CSS.card_icon, CSS.orange)}/>
            <span className={CSS.card_text}>10X better performance than industry standard</span>
          </div>
          <div className={CSS.card}>
            <Icon library='general' symbol='Like' size='32px' className={classNames(CSS.card_icon, CSS.brown)}/>
            <span className={CSS.card_text}>Experience with moves at your community</span>
          </div>
          <div className={CSS.card}>
            <Icon library='general' symbol='User' size='32px' className={classNames(CSS.card_icon, CSS.purple)}/>
            <span className={CSS.card_text}>Moved Concierge service included</span>
          </div>
        </div>
      </ScreenContent>

      <ScreenActions>
        <label
          onClick={selectMoved}
          tabIndex="0"
          role="button"
          className={classNames('btn-primary btn--full',{ loading: selectMovedPending})}
          disabled={pending}
        >
          <Snippet tag={'tasks.book-movers.moving-provider.confirm'}>
            Continue
          </Snippet>
        </label>
        { !get(taskDetails,'settings.anonymous_ads_enabled') && (
          <label
            onClick={selectOther}
            tabIndex="10"
            role="button"
            className={classNames('btn-ghost',{ loading: selectOtherPending})}
            disabled={pending}
          >
            <Snippet tag={'tasks.book-movers.moving-provider.cancel'}>
              No thanks
            </Snippet>
          </label>
        )}
      </ScreenActions>
    </ScreenLayout>
  );
}
