import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import classNames from 'classnames';
import { get } from 'lodash';

import { DynamicForm, AtomSpinner } from '@moved/ui';
import { useNotify, format } from '@moved/services';

import { OtherPets } from './';
import { ScreenTitle } from '../../shared';
import taskCSS from '../../../components/styles/TaskFlow.module.scss';

// Multi-file document upload screen component
export const PetType = ({ screen, changeScreen, taskDefinition, taskDetails }) => {
  // HOOKS
  const { screenContext } = useParams();
  const dispatch = useDispatch();
  const Notify = useNotify();

  // REDUX
  const petTypes = taskDefinition.selectors.usePetTypes();

  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);
  const activePet = taskDefinition.getActivePet(taskDetails, screenContext);
  const createPending = taskDefinition.selectors.useCreatePetPending();
  const petPending = taskDefinition.selectors.useUpdatePetPending();
  const loading = taskDefinition.selectors.usePetRegistrationPending();
  const typesPending = taskDefinition.selectors.usePetTypesPending();

  const pending = createPending || petPending;

  useEffect(() => {
    dispatch(taskDefinition.actions.getPetTypes())
      .catch(error => Notify.error(format.error(error)));
  },[taskDefinition]); // eslint-disable-line

  const fields = [
    {
      type: 'text',
      name: 'name',
      label: 'Pet name',
      required: true,
      value: get(activePet,'name'),
    },
    {
      type: 'itemList',
      name: 'pet_type_id',
      label: 'Which type of pet would you like to register?',
      hiddenLabel: true,
      required: 'Pet type is required.',
      value: get(petTypes.find(type => type.name === get(activePet,'pet_type')),'id'),
      isTwoCol: true,
      options: petTypes.map(type => ({
        label: type.name,
        value: type.id,
        icon: { symbol: format.capitalize(type.icon), library: 'animals'},
      })),
    },
  ];

  const _handleSubmit = formData => {
    if(pending) return;
    if(!activePet) {
      dispatch(taskDefinition.actions.createPet(activeRequest.id, formData))
        .then((resp) => {
          const respRequest = taskDefinition.helpers.getActiveRequest(resp);
          // set newest created pet as the active pet
          const pet = respRequest.pets[respRequest.pets.length-1];
          changeScreen('next',resp,`?pet=${pet.id}`)
        })
        .catch(err => {
          Notify.error();
        });
    }

    dispatch(taskDefinition.actions.updatePet(activeRequest.id, activePet.id, formData))
      .then((resp) => {
        changeScreen('next',resp);
      })
      .catch(err => {
        Notify.error();
      });

  };

  return (
    <div className={taskCSS.holder}>
      <div className={classNames(taskCSS.task)}>
        <ScreenTitle screen={screen} />

        <OtherPets taskDetails={taskDetails} />

        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
            <div className={taskCSS.screen_edit_form}>
              { (!loading && !typesPending) ? (
                <DynamicForm
                  id={`${screen.slug}-${screen.context}-form`}
                  fields={fields}
                  formStyle={'underline'}
                  onSubmit={_handleSubmit}
                  disabled={pending}
                />
              ) : (
                <AtomSpinner />
              )}
            </div>
          </div>
        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            <label
              htmlFor={`${screen.slug}-${screen.context}-form-submit`}
              tabIndex="0"
              role="button"
              className={'btn-primary btn--full' + (pending  ? ' loading' : '')}
              disabled={pending}
            >
              Submit
            </label>
          </div>
        </div>

      </div>
    </div>
  );
};
