import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { get } from 'lodash';

import { LoaderOverlay } from '@moved/ui';

import { useActiveTask } from '../../../contexts/TaskContext';

export const DeclineEmailCoupon = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { activeTaskDefinition, activeTaskBaseRoute } = useActiveTask();

  const taskDetails = activeTaskDefinition.selectors.useTaskable(id);

  useEffect(() => {
    if(!taskDetails) return;
    const isTaskEligibleForDecline = (
      get(taskDetails,'selected_moving_method') === 'professional-movers' &&
      get(taskDetails,'interested_in_moved') &&
      !get(taskDetails,'mover_booking.booked_service_order')
    );
    if(isTaskEligibleForDecline) {
      dispatch(activeTaskDefinition.actions.reset(id))
        .then(resp => {
          history.replace(`${activeTaskBaseRoute}${activeTaskDefinition.getStartOverRoute(resp)}`);
        })
        .catch(() => {
          history.replace(`${activeTaskBaseRoute}${activeTaskDefinition.getRecommendedRoute(taskDetails)}`);
        });
    }
    else {
      history.replace(`${activeTaskBaseRoute}${activeTaskDefinition.getRecommendedRoute(taskDetails)}`);
    }
  },[taskDetails]); // eslint-disable-line

  return <LoaderOverlay/>;

};
