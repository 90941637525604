import { merge, get } from 'lodash';

import { BaseTask } from '../../components/BaseTask';
import * as actions from './actions';
import * as selectors from './actions/selectors';
import { screens } from './screens';
import { routes } from './routes';

export class FaqsDirect extends BaseTask {
  constructor() {
    super({
      routes: routes,
      screens: screens,
      actions: actions,
      selectors: {
        useTaskable: selectors.useFaq,
        useTaskablePending: selectors.useFaqPending,
        ...selectors,
      }
    });
  }

  static slug = 'faq';

  _flowBuilder(taskData) {
    return [
      ...get(taskData,'content',[]).map((screen, index) => {
        return merge({}, this.screens['content'], { task_id: taskData.id }, screen);
      }),
    ];
  }

  /* START OVERRIDES */
  canAccessScreen(taskData, screen) {
    return true;
  }
  /* END OVERRIDES */

  /* TASK SPECIFIC */
  /* END TASK SPECIFIC */

}
