import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import classNames from 'classnames';

import { DynamicForm } from '@moved/ui';
import { useNotify } from '@moved/services';

import { ScreenTitle } from '../../shared';

import { CompareMovingMethods } from './CompareMovingMethods';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';

const professionalMoversOption = {
  label: `Hiring movers with a truck`,
  sublabel: `Expert movers handle your belongings with care. Insurance forms may be required by the property.`,
  value: 'professional-movers',
  icon: { symbol: 'Crew-4', library: 'people' },
  large: true,
};

export const MovingPlan = ({ screen, changeScreen, task }) => {
  const { id } = useParams();
  const Notify = useNotify();
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);
  const taskDetails = task.selectors.useTaskable(id);

  const fields = [
    {
      type: 'itemList',
      name: 'selected_moving_method',
      required: 'Required to continue',
      value: get(taskDetails,'selected_moving_method'),
      options: [
        // optionally show professional movers first if uhaul is not configured as the truck rental provider
        get(taskDetails,'truck_provider_ads[0].label') !== 'truck-rental-uhaul' && professionalMoversOption,
        get(taskDetails,'truck_provider_ads',[]).length > 0 && {
          label: `Renting a truck`,
          sublabel: `Find the right equipment at the lowest cost that is optimal for unloading items at your new home.`,
          value: 'truck-rental',
          icon: { symbol: 'Truck', library: 'home' },
          large: true,
        },
        get(taskDetails,'container_provider_ads',[]).length > 0 && {
          label: `Renting a shippable moving container`,
          sublabel: `Explore flexible solutions for loading, storing, and delivering your items on your own schedule.`,
          value: 'container-rental',
          icon: { symbol: 'Box#2', library: 'shopping' },
          large: true,
        },
        get(taskDetails,'labor_provider_ads',[]).length > 0 && {
          label: `Hiring help to load & unload`,
          sublabel: `Get local help to carry heavy items on either side of your move. You'll handle packing and transportation arrangements.`,
          value: 'hiring-labor',
          icon: { symbol: 'Muscle', library: 'people' },
          large: true,
        },
        // optionally show professional movers below trucks, containers, and labor if uhaul IS the truck rental provider
        get(taskDetails,'truck_provider_ads[0].label') === 'truck-rental-uhaul' && professionalMoversOption,
        {
          label: `Doing it all myself`,
          sublabel: `You're personally handling all aspects of packing, transportation, loading, and unloading for your household items.`,
          value: 'diy',
          icon: { symbol: 'Smile', library: 'general' },
          large: true,
        },
      ].filter(v=>v),
    },
  ];

  const _handleSubmit = (data) => {
    if(pending) return;
    setPending(true);
    dispatch(task.actions.update(id, data))
      .then(r => get(r,'selected_moving_method') === 'diy' ? dispatch(task.actions.complete(id)) : r)
      .then(r => changeScreen('next', r))
      .catch(err => {
        setPending(false);
        Notify.error();
      });
  };

  return (
    <div className={taskCSS.holder}>

      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} />

        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
            <div className={taskCSS.screen_edit_form}>
              <CompareMovingMethods />
              <DynamicForm
                id='moving-plan-form'
                fields={fields}
                formStyle='underline'
                onSubmit={_handleSubmit}
                disabled={pending}
              />
            </div>
          </div>
        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            <label
              htmlFor='moving-plan-form-submit'
              tabIndex="0"
              role="button"
              className={classNames('btn-primary btn--full', { loading: pending })}
              disabled={pending}
            >
              Next
            </label>
          </div>
        </div>
      </div>

    </div>
  );
}
