import { merge } from 'lodash';

import * as getLandingPage from './getLandingPage';
import * as getTokenData from './getTokenData';
import * as claimToken from './claimToken';

export const reducers = [
  getLandingPage.reducer,
  getTokenData.reducer,
  claimToken.reducer,
];

export const initialState = merge(
  getLandingPage.initialState,
);
