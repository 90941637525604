import React from 'react';

import { CompleteCard } from '@moved/ui';

import { Snippet } from '../../../../snippets';
import { ScreenTitle } from '../../shared/components/ScreenTitle';
import { CompleteScreenActions } from '../../shared/components/CompleteScreenActions';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';

export const Complete = ({screen, changeScreen, task, origin}) => {

  return (
    <div className={taskCSS.holder}>

      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} />
        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
            <CompleteCard status={'approved'} flavor={<Snippet tag={`tasks.${task.slug}.summary`} />} >
              <p>I understand that setting up utilities for my apartment is my responsibility ahead of moving in.</p>
            </CompleteCard>
          </div>
        </div>

        <CompleteScreenActions origin={origin} />

      </div>

    </div>
  )
};
