import React from 'react';
import { get } from 'lodash';
import classNames from 'classnames';

import { format } from '@moved/services';

import mapIcon from './assets/map-icon.png';
import CSS from './styles/LeaseData.module.scss';

export const LeaseData = ({ activeMoveStep }) => {
  const lease = get(activeMoveStep,'lease');
  return (
    <div className={CSS.container}>
      <div className={CSS.lease_data}>
        <div className={CSS.address_block}>
          <a
            href={`https://www.google.com/maps/place/?q=place_id:${get(lease,'address.google_place_id')}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className={CSS.lease_map} style={{backgroundImage:`url(${mapIcon})`}}></div>
          </a>
          <div className={CSS.lease_address}>
            <label className={CSS.label}>Address</label>
            <div className={CSS.display_value}>{ format.address(get(lease,'address'),{unit:false}) }</div>
          </div>
        </div>
        { get(lease,'address.unit') && (
          <div className={classNames(CSS.lease_attribute,CSS.unit)}>
            <label className={CSS.label}>Unit</label>
            <div className={CSS.display_value}>{ get(lease,'address.unit') }</div>
          </div>
        )}
        { get(activeMoveStep,'date') && (
          <div className={classNames(CSS.lease_attribute,CSS.date)}>
            <label className={CSS.label}>{ get(activeMoveStep,'type') === 'move-in' ? 'Lease start' : 'Move out date'}</label>
            <div className={CSS.display_value}>{ format.date(get(activeMoveStep,'date')) }</div>
          </div>
        )}
        { get(lease,'external_lease_number') && (
          <div className={classNames(CSS.lease_attribute,CSS.number)}>
            <label className={CSS.label}>Lease number</label>
            <div className={CSS.display_value}>{ get(lease,'external_lease_number') }</div>
          </div>
        )}
      </div>
    </div>
  );
};
