import React from 'react';
import { FieldArray as FormikArray } from 'formik';
import { get } from 'lodash';
import { CSSTransition } from 'react-transition-group';

import { DynamicField } from '../DynamicField';
import { Icon } from '../../../../sondheim/components/Icon';
import formCSS from '../StyledForm.module.scss';

// How many total addresses user can input
const maxStops = 2

const AddressRow = ({ address, form, formStyle, remove, index }) => (
  <DynamicField input={address} form={form} formStyle={formStyle} remove={remove} index={index} />
);

const FieldAddressList = ({ input, form, formStyle  }) => {

  const addresses = get(form,`values[${input.name}]`);

  const toIndex = addresses.length > 1 ? addresses.length - 1 : 1;

  const fromAddress = {
    name: `${input.name}[0]`,
    label: 'Origin address',
    type: 'address',
    addressName: 'from_address',
    stopIcon: true,
  };

  const toAddress = {
    name: `${input.name}[${toIndex}]`,
    label: 'Destination address',
    type: 'address',
    addressName: 'to_address',
    stopIcon: true,
  };

  let canAdd = false;
  if(addresses.length < maxStops && addresses[0] !== '' && addresses[toIndex] !== '') canAdd = true;

  return (
    <div className={formCSS.address_list_row}>
        <FormikArray
          name={input.name}
        >
          {({push, pop, insert, remove}) => {
            return (
              <div className={formCSS.address_list_block}>
                <AddressRow address={fromAddress} form={form} formStyle={formStyle} />
                {addresses.map((address, index) => {
                  if(index === 0 || index === addresses.length-1) return null;
                  return(
                    <CSSTransition
                      in={true}
                      appear={true}
                      timeout={400}
                      mountOnEnter={true}
                      unmountOnExit={true}
                      key={index}
                      classNames={{
                        appear:        formCSS.address_enter,
                        appearActive:  formCSS.address_enter_active,
                      }}
                    >
                      <AddressRow
                        address={{
                          name: `${input.name}[${index}]`,
                          label: 'Additional stop',
                          type: 'address',
                          addressName: `stop${index}`,
                          showDeed: false,
                          stopIcon: true,
                        }}
                        form={form}
                        formStyle={formStyle}
                        index={index}
                        remove={remove}
                      />
                    </CSSTransition>
                  );
                })}
                <CSSTransition
                  in={canAdd}
                  timeout={400}
                  mountOnEnter={true}
                  unmountOnExit={true}
                  classNames={{
                    enter:        formCSS.add_stop_enter,
                    enterActive:  formCSS.add_stop_enter_active,
                    exit:         formCSS.add_stop_exit,
                    exitActive:   formCSS.add_stop_exit_active,
                  }}
                >
                  <div className={formCSS.add_stop}>
                    <div className={formCSS.add_content}>
                      <span onClick={() => insert(addresses.length-1,'')} className={formCSS.add_stop_icon}><Icon size='20px' symbol='Plus' library={'navigation'} /></span>
                      <span onClick={() => insert(addresses.length-1,'')} className={formCSS.add_stop_button}>Add stop</span>
                    </div>
                  </div>
                </CSSTransition>
                <AddressRow address={toAddress} form={form} formStyle={formStyle} />
              </div>
            );
          }}
        </FormikArray>

    </div>
  );
}

export default FieldAddressList;
