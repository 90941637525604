import React, { useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';
import classNames from 'classnames';

import { DynamicFormScreen } from '../types/shared/components/DynamicFormScreen';

import CSS from './styles/TaskFlow.module.scss';

// component for rendering each screen component and transitioning between them
export const TaskScreenTransition = ({ screen, index, ...passProps }) => {
  /* current passProps:
  *    changeScreen: Callback for screen navigation
  *    task: Used to access taskDefinition data and functions
  *    origin: Used to handle proper routing
  */

  const [previousIndex, setPreviousIndex] = useState(index);
  useEffect(() => setPreviousIndex(index),[index]);

  const transitions = useTransition(screen, screen => `${screen.slug}${screen.context ? screen.context : ''}`, {
    initial: { opacity: 1, top: '0%' },
    from: { opacity: 0, top: index > previousIndex ? '100%' : '-100%' },
    enter: { opacity: 1, top: '0%' },
    leave: { opacity: 0, top: index > previousIndex ? '-100%' : '100%' },
  });

  return transitions.map(({item:currentScreen, props}) => {
    if(!currentScreen.component) currentScreen.component = DynamicFormScreen;
    return (
      <animated.div
        key={`${currentScreen.key || currentScreen.slug}`}
        style={props}
        className={classNames(CSS.animated_screen, CSS[`task_${currentScreen.slug}`])}
      >
        <currentScreen.component screen={currentScreen} {...passProps} />
      </animated.div>
    );
  })
}
