import React from 'react';
import { AtomSpinner } from '@moved/ui';

import CSS from '../../../components/styles/TaskFlow.module.scss';

export const ScreenLoading = () => {
  return (
    <div className={CSS.holder}>
      <div className={CSS.task}>
        <AtomSpinner />
      </div>
    </div>
  );
}
