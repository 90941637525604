import React from 'react';
import { isNil } from 'lodash';

import { Icon, FieldList } from '@moved/ui';
import { useModal, format } from '@moved/services'


import CSS from './PetDetailsModal.module.scss';

export const PetDetailsModal = ({ pet }) => {
  // hooks
  const { close } = useModal();

  const attributes = [
    {
      label: 'Pet type',
      value: pet.pet_type,
    },
    {
      label: 'Breed',
      value: pet.breed,
    },
    pet.gender && {
      label: 'Gender',
      value: format.capitalize(pet.gender),
    },
    pet.age_years && {
      label: 'Age',
      value: `${pet.age_years} years, ${pet.age_months} months`
    },
    pet.color && {
      label: 'Color',
      value: format.capitalize(pet.color),
    },
    pet.weight_in_lbs && {
      label: 'Weight',
      value: `${pet.weight_in_lbs} lbs`
    },
    !isNil(pet.is_service_animal) && {
      label: 'Service animal',
      value: pet.is_service_animal ? 'Yes' : 'No',
    },
    pet.service_animal_documentation_url && {
      label: 'Service animal documentation',
      value: (
        <a href={pet.service_animal_documentation_url} target="_blank" rel="noopener noreferrer">
          File
        </a>
      ),
    },
    !isNil(pet.is_spayed_or_neutered) && {
      label: (pet.gender === 'female') ? 'Spayed' : 'Neutered',
      value: pet.is_spayed_or_neutered ? 'Yes' : 'No',
    },
    pet.is_spayed_or_neutered && {
      label: `${(pet.gender === 'female') ? 'Spayed' : 'Neutered'} date`,
      value: format.date(pet.spayed_or_neutered_date),
    },
    (pet.vaccine_file_urls && pet.vaccine_file_urls.length > 0) && {
      label: 'Proof of vaccination',
      value: pet.vaccine_file_urls.map((url, idx) => (
        <React.Fragment key={url}>
          {idx > 0 && <span>,&nbsp;</span>}
          <a href={url} target="_blank" rel="noopener noreferrer">
            File {idx+1}
          </a>
        </React.Fragment>
      )),
    },
    pet.license_number && {
      label: 'Pet license number',
      value: pet.license_number,
    },
    pet.license_documentation_url && {
      label: 'Pet license documentation',
      value: (
        <a href={pet.license_documentation_url} target="_blank" rel="noopener noreferrer">
          File
        </a>
      ),
    },
    pet.photo_url && {
      label: 'Photo',
      value: (
        <a href={pet.photo_url} target="_blank" rel="noopener noreferrer">
          View photo of {pet.name}
        </a>
      ),
    },
    pet.owner && {
      label: 'Owner',
      value: format.fullname(pet.owner),
    },
  ].filter(v => v);

  return (
    <div className={CSS.pet_modal}>
      <div className={CSS.title}>
        <h3>Registration details</h3>
      </div>
      <div className={CSS.intro}>
        {pet.photo_url && (
          <div className={CSS.photo_container}>
            <div className={CSS.photo} style={{backgroundImage:`url(${pet.photo_url})`}} />
          </div>
        )}
        <div className={CSS.highlights}>
          <div className={CSS.name}>
            <h2>{pet.name}</h2>
            <Icon
              {...(
                pet.pet_type === 'Other'
                ? { symbol: 'Other#2', library: 'general' }
                : { symbol: format.capitalize(pet.pet_type), library: 'animals'}
              )}
              size={'24px'}
            />
          </div>
            <div className={CSS.subtitle}>
              {[pet.pet_type, pet.breed].join(', ')}
            </div>
            <div className={CSS.fields}>
              <FieldList list={attributes} />
            </div>
        </div>
      </div>
      <div className={CSS.buttons}>
        <button
          className={'btn-gray'}
          onClick={e => close(false)}
        >
          Close details
        </button>
      </div>
    </div>
  );
}
